import React, { useEffect, useState } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
/* import { CKEditor } from "ckeditor4-react"; */
import { Col, Row, Button } from "react-bootstrap";
import { useParams, Redirect, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, CardHeader, CardBody, } from "./../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { addEmailAsync, updateEmailAsync } from "../redux/emailManagementApi";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CustomEditor from "../../CustomEditor/CustomEditor";
import Select from "react-select";

const AddTemplate = () => {
  const templateOptions = [];
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let { websiteLists, web_id } = useSelector(
    (state) => state.environnment,
    shallowEqual
  );
  const [defaultWebList, setdefaultWebList] = useState([]);

  useEffect(() => {
    setdefaultWebList([{
      value: web_id,
      label: web_id
    }]);
  }, [])

  const { selectedEmail, templates } = useSelector((state) => state.emailManagement, shallowEqual);
  if (!selectedEmail && params?.id !== undefined) {
    return <Redirect to="/email-management" />;
  }
  if(selectedEmail && selectedEmail.web_id ){
    web_id = selectedEmail.web_id;
  }
  
  const initialValues = {
    web_id: web_id != 'all' ? web_id : '',
    mailTitle: "",
    mailSubject: "",
    mailBody: "",
  };

  if (templates && templates.length > 0) {
    initialValues.templates = templates;
    templates.forEach((entity) => {
      templateOptions.push({
        value: entity.templateName,
        label: entity.templateName,
      });
    });
  }
  const redirectBack = () => {
    history.push("/email-management");
  };

  const addEmailSchema = Yup.object().shape({
    mailTitle: Yup.string().trim().notOneOf(
      ["null", "NULL", "Null", "Undefined", "undefined"],
      "Please enter Email Title"
    ).required("Please enter Email title"),
    mailSubject: Yup.string().trim().notOneOf(
      ["null", "NULL", "Null", "Undefined", "undefined"],
      "Please enter Email subject"
    ).required("Please enter Email subject"),
    mailBody: Yup.string().trim().notOneOf(
      ["null", "NULL", "Null", "Undefined", "undefined"],
      "Please enter valid Email body"
    ).required("Please enter Email body"),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={selectedEmail && params?.id !== undefined ? selectedEmail : initialValues}
        validationSchema={addEmailSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (selectedEmail && params?.id !== undefined) {
            dispatch(
              updateEmailAsync(values, setSubmitting, resetForm, redirectBack)
            );
          } else {
            dispatch(
              addEmailAsync(values, setSubmitting, resetForm, redirectBack)
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Card>
            <CardHeader
              title={
                <div className="form-group m-0 d-flex align-items-center">
                  <Link className="btn btn-link px-0" to="/email-management">
                    <SVG className="h-50 align-self-center" src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
                    Back
                  </Link>
                  <span className="pl-2">
                    {params?.id ? "Edit Template" : "Add Template"}
                  </span>
                </div>
              }
            ></CardHeader>
            <CardBody>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Form>

                    <div className="form-group">
                      <label className="form-label">Web ID</label>
                      <Select classNamePrefix="react-select" name="web_id" placeholder="Web ID" className={`form-control border-0 p-0 `}
                        options={websiteLists}
                        onChange={(e) => {
                          setFieldValue("web_id", e.value);
                          setdefaultWebList([{
                            value: e.value,
                            label: e.value
                          }]);
                        }}
                        value={defaultWebList}
                      />
                      {touched.web_id && errors.web_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.web_id}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input placeholder="Enter Title"
                        type="text"
                        disabled={selectedEmail && params?.id !== undefined}
                        name="mailTitle"
                        className={errors.mailTitle && touched.mailTitle ? "form-control re_inputRouded is-invalid" : "form-control re_inputRouded"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailTitle}
                      />
                      {touched.mailTitle && errors.mailTitle ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.mailTitle}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Subject</label>
                      <input
                        placeholder="Enter Subject"
                        type="text"
                        name="mailSubject"
                        className={errors.mailSubject && touched.mailSubject ? "form-control re_inputRouded is-invalid" : "form-control re_inputRouded"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailSubject}
                      />
                      {touched.mailSubject && errors.mailSubject ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.mailSubject}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Email Body</label>

                      <CustomEditor
                        data={values.mailBody}
                        className={errors.mailBody && touched.mailBody ? "form-control re_inputRouded is-invalid" : "form-control re_inputRouded"}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // Set field touched value
                          setFieldTouched("mailBody", true);

                          // Set field value
                          setFieldValue("mailBody", data);
                        }}
                      />
                      {touched.mailBody && errors.mailBody ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.mailBody}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link className="btn btn-outline-blue" to="/email-management"                        >
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button variant="blue" className="px-5 defpddng spinnerBtn" size="lg" type="submit">
                          {selectedEmail && params?.id !== undefined ? "Update" : "Submit"}
                          {isSubmitting && (
                            <div className="ml-3 basic-verification-loader text-center">
                              {/* <CircularProgress /> */}
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Formik>
    </>
  );
};
export default AddTemplate;
