import React from "react";

const Checkbox = ({
  id,
  type,
  name,
  handleClick,
  isChecked,
  value,
  defaultValue,
}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export default Checkbox;
