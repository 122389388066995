import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import {
  deleteGenAIAsync,
  deleteSelectedGenAIQuote,
  getAllGenAIAsync,
  generativeAIAction,
} from "../../redux";
import Checkbox from "../../../../components/Checkbox";
import { useIsAccessAllow } from "../../../users/redux";

const GenerativeAITable = () => {
  const history = useHistory();
  const type = "all";
  const { isLoading, GenAIList, skip, limit } = useSelector(
    (state) => state.genAIData,
    shallowEqual
  );
  const [checkedVal, setCheckedVal] = useState(false);
  const [checkIds, setCheckIds] = useState([]);
  const { searchBy, searchText } = useSelector(
    (state) => state.genAIData,
    shallowEqual
  );
  useEffect(() => {
    dispatch(getAllGenAIAsync(searchBy, searchText, type));
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(generativeAIAction.setGenerativeAIBatchNumber(count));
  };

  const onDeleteClick = (data) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteGenAIAsync(data?._id));
      dispatch(getAllGenAIAsync(searchBy, searchText, type));
    }
  };

  const onViewClick = (data) => {
    history.push(`/generativeAI/view-generativeAI/${data?._id}`);
  };

  const allChecked = (e) => {
    setCheckedVal(!checkedVal);
    setCheckIds(GenAIList.records.map((li) => li._id));
    if (checkedVal) {
      setCheckIds([]);
    }
  };

  const oneChecked = (e) => {
    const { id, checked } = e.target;
    setCheckIds([...checkIds, id]);
    if (!checked) {
      setCheckIds(checkIds.filter((item) => item !== id));
    }
  };

  const deleteSelectQuoteById = () => {
    if (window.confirm("Are you sure you want to delete selected quote?")) {
      dispatch(deleteSelectedGenAIQuote(checkIds));
      setCheckedVal(false);
      dispatch(getAllGenAIAsync(searchBy, searchText, type));
    }
  };

  const isViewAllow = useIsAccessAllow("get_a_quote.view");
  const isDeteleAllow = useIsAccessAllow("get_a_quote.delete");
  const isMultipleDeteleAllow = useIsAccessAllow("get_a_quote.multiple_delete");

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        {isMultipleDeteleAllow && (
          <div className="mt-4">
            <div style={{ float: "right" }}>
              <Button
                type="submit"
                disabled={checkIds.length === 0}
                onClick={deleteSelectQuoteById}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  {isMultipleDeteleAllow && (
                    <th>
                      <Checkbox
                        type="checkbox"
                        name="checkAll"
                        id="checkAll"
                        handleClick={allChecked}
                        isChecked={checkedVal}
                      />
                    </th>
                  )}
                  <th>Sr No.</th>
                  {/* <th>Web ID</th> */}
                  {/* <th>Page Name</th> */}
                  <th>Name</th>
                  <th>Email</th>
                  {/* <th>Project Requirement</th> */}
                  {/* <th>Services</th> */}
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                GenAIList.records &&
                GenAIList.records.length ? (
                  GenAIList.records.map((GenAILists, index) => (
                    <tr key={index}>
                      {isMultipleDeteleAllow && (
                        <td>
                          <Checkbox
                            type="checkbox"
                            name={GenAILists._id}
                            id={GenAILists._id}
                            handleClick={oneChecked}
                            isChecked={checkIds.includes(GenAILists._id)}
                          />
                        </td>
                      )}
                      <td>
                        <span className="f-w-500">
                          {skip * limit + (index + 1)}
                        </span>
                      </td>
                      {/* <td>
                        <span>
                          <span className="f-w-500">{GenAILists.web_id}</span>
                        </span>
                      </td> */}
                      {/* <td>
                        <span>
                          <span className="f-w-500">
                            {GenAILists.pageName}
                          </span>
                        </span>
                      </td> */}
                      <td>
                        <span>
                          <span className="f-w-500">{GenAILists.name}</span>
                        </span>
                      </td>
                      <td>
                        <span className="f-w-500">{GenAILists.email}</span>
                      </td>
                      {/* <td>
                        <div
                          style={{
                            display: "-webkit-box",
                            maxWidth: "200px",
                            webkitLineClamp: "3",
                            webkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          <span>{GenAILists.pro_requirements}</span>
                        </div>
                      </td> */}
                      {/* <td>
                        <span className="f-w-500">{GenAILists.services}</span>
                      </td> */}
                      <td>
                        <span className="f-w-500">
                          {new Date(GenAILists.createdOn).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        {isViewAllow && (
                          <button
                            title="View"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                            onClick={() => onViewClick(GenAILists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/custom/eye.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}
                        {isDeteleAllow && (
                          <button
                            title="Delete"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onDeleteClick(GenAILists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={GenAIList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {GenAIList.recordsTotal} Records
              </div>
            </div>
          )}
          {isLoading && (
            <div>
              <div style={{ justifyContent: "center" }} className="text-center">
                <div className="basic-verification-loader text-center">
                  <CircularProgress />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerativeAITable;
