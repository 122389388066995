import axios from "axios";
import { TagActions } from "./tagAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const createTagAsync = (
  detail,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(TagActions.createTagStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tag/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: detail,
      });
      if (data.code === 200 && data.status) {
        dispatch(TagActions.createTagSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(TagActions.createTagError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
      setSubmitting(false);
    } catch (error) {
      dispatch(TagActions.createTagError());
      setSubmitting(false);
      resetForm();
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const getAllTagAsync = (searchBy, searchText) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(TagActions.getAllTag());
      let { skip, limit } = getState().tag;
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tag/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&search=${searchText}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(TagActions.getAllTagSuccess(data.data));
      }
      dispatch(TagActions.getAllTagError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      console.log("Error: ", error);
      dispatch(TagActions.getAllTagError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const getTagDetailAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(TagActions.getTagDetail());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tag/getDetail/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(TagActions.getTagDetailSuccess(data.data));
      }
      dispatch(TagActions.getTagDetailError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(TagActions.getTagDetailError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const updateTagAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      //let { _id } = getState().auth.user;
      let { selectedTag } = getState().tag;
      dispatch(TagActions.updateTagStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tag/update/${selectedTag._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200 && data.status) {
        dispatch(TagActions.updateTagSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(TagActions.updateTagError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(TagActions.updateTagError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteTagAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(TagActions.deleteTagStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tag/remove/${id}`,
        method: "delete",
      });
      if (data.code === 200) {
        dispatch(TagActions.deleteTagSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(TagActions.deleteTagError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      console.log("Error: ", error);
      dispatch(TagActions.deleteTagError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating.Please try again after sometime..",
          3000
        )
      );
    }
  };
};

export const tagListAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(TagActions.getTagListStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tag/tagList`,
        method: "get",
      });
      if (data.code === 200) {
        dispatch(TagActions.getTagListSuccess(data.data));
        // return dispatch(showSuccessSnackbar("success", data.message, 3000));
      } else {
        dispatch(TagActions.getTagListError());
        return dispatch(showSuccessSnackbar("error", data.message, 3000));
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch(TagActions.getTagListError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating.Please try again after sometime..",
          3000
        )
      );
    }
  };
};
