import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody } from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { createTagAsync, updateTagAsync, getTagDetailAsync } from "../redux/tagApi";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const AddTag = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTag } = useSelector((state) => state.tag, shallowEqual);
    const id = params.id;

    useEffect(() => {
        if (id) {
            dispatch(getTagDetailAsync(id));
        }
    }, [])

    const initialValues = {
        slug: "",
        title: "",
    };
    const redirectBack = () => {
        history.push("/tag");
    };

    const addSchema = Yup.object().shape({
        slug: Yup.string().trim().required("Please enter slug"),
        title: Yup.string()
            .trim()
            .notOneOf(
                ["null", "NULL", "Null", "Undefined", "undefined"],
                "Please enter tag Title"
            )
            .required("Please enter tag title"),
    });

    const convertToSlug = (str) => {
        return str.toLowerCase().trim()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '').replace(/-+/g, '-');
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={selectedTag && params?.id !== undefined ? selectedTag : initialValues}
                validationSchema={addSchema}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                    let data = null;
                    data = {
                        slug: values.slug,
                        title: values.title
                    }
                    setSubmitting(true);
                    if (params?.id !== undefined) {
                        dispatch(
                            updateTagAsync(data, setSubmitting, resetForm, redirectBack)
                        );
                    } else {
                        dispatch(
                            createTagAsync(data, setSubmitting, resetForm, redirectBack)
                        );
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                }) => (
                    <Card>
                        <CardHeader
                            title={
                                <div className="form-group m-0 d-flex align-items-center">
                                    <Link className="btn btn-link px-0" to="/tag">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Navigation/Arrow-left.svg"
                                            )}
                                        />
                                        Back
                                    </Link>
                                    <span className="pl-2">
                                        {params?.id ? "Edit Tag" : "Add Tag"}
                                    </span>
                                </div>
                            }
                        ></CardHeader>
                        <CardBody>
                            <Row className="justify-content-center">
                                <Col lg={8}>
                                    <Form>
                                        <div className="form-group">
                                            <label className="form-label">Slug</label>
                                            <input
                                                placeholder="Enter Slug"
                                                type="text"
                                                name="slug"
                                                className={errors.slug && touched.slug ? "form-control re_inputRouded is-invalid" : "form-control re_inputRouded"}
                                                onChange={handleChange}
                                                onBlur={(event) => {
                                                    let slug = event.target.value;
                                                    if (slug) {
                                                        slug = convertToSlug(slug);
                                                    }
                                                    setFieldValue('slug', slug);
                                                }}
                                                value={values.slug ? values.slug : ""}
                                            />
                                            {touched.slug && errors.slug ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {errors.slug}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Title</label>
                                            <input
                                                placeholder="Enter Title"
                                                type="text"
                                                name="title"
                                                className={errors.title && touched.title ? "form-control re_inputRouded is-invalid" : "form-control re_inputRouded"}
                                                onChange={handleChange}
                                                onBlur={(event) => {
                                                    let title = event.target.value;
                                                    setFieldValue('title', title);

                                                    if (!values.slug) {
                                                        setFieldValue('slug', convertToSlug(title));
                                                    }
                                                }}
                                                value={values.title ? values.title : ""}
                                            />
                                            {touched.title && errors.title ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {errors.title}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                                            <div className="mr-5 mb-1 mt-2">
                                                <Link
                                                    className="btn btn-outline-blue"
                                                    to="/tag"
                                                >
                                                    Close
                                                </Link>
                                            </div>
                                            <div className="mb-1 mt-2">
                                                <Button
                                                    variant="blue"
                                                    className="px-5 defpddng spinnerBtn"
                                                    size="lg"
                                                    type="submit"
                                                >
                                                    {selectedTag && params?.id !== undefined
                                                        ? "Update"
                                                        : "Submit"}
                                                    {/* {isSubmitting && (
                                                        <div className="ml-3 basic-verification-loader text-center">
                                                            <CircularProgress />
                                                        </div>
                                                    )} */}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                )}
            </Formik>
        </>
    );
};
export default AddTag;
