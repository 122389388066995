import { dashboardMap } from "./dashboardAction";

const initialState = {
  isLoading: false,
  totalLeadsCount: {},

  // OneMonth
  oneMonthLeads: [],
  refreshOneMonthDashboard: true,

  // Monthly
  monthlyLeadsLists: [],
  refreshMonthDashboard: true,
  skip: 0,
  limit: 5,

  // Country
  countryLeadsList: [],
  refreshCountryDashboard: true,
  skipCountries: 0,
  limitCountries: 5,

  // Google Analytics
  analyticsData: [],
  refreshGoogleAnalyticsDashboard: true,
  filterAnalyticsDate: { toDateAnalytics: "", fromDateAnalytics: "" },

  // Google Analytics
  analyticsGraphData: [],
  refreshGoogleAnalyticsGraphDashboard: true,
  filterAnalyticsGraphDate: [
    {
      toDateGraphAnalytics: "",
      fromDateGraphAnalytics: "",
      sessionValue: "",
    },
  ],

  // Quote PageData
  QuotepageList: [],
  skipQuote: 0,
  limitQuote: 5,
  searchPage: "",
  filterDate: { toDate: "", fromDate: "" },
  refreshQuotePageDataDashboard: true,

  // ContactUs PageData
  contactUsList: [],
  skipContact: 0,
  limitContact: 5,
  searchContactPage: "",
  filterContactDate: { toContactDate: "", fromContactDate: "" },
  refreshContactPageDataDashboard: true,

  // KeyWorkRanker
  keyWordRankerList: [],
  refreshKeywordranker: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // KeyWorkRanker
    case dashboardMap.GET_KEYWORD_RANKER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardMap.GET_KEYWORD_RANKER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshKeywordranker: false,
        keyWordRankerList: action.payload,
      };
    }
    case dashboardMap.GET_KEYWORD_RANKER_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.REFRESH_KEYWORD_RANKER_DATA: {
      return {
        ...state,
        keyWordRankerList: true,
      };
    }

    // OneMonth
    case dashboardMap.GET_ONEMONTH_COUNT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardMap.GET_ONEMONTH_COUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshOneMonthDashboard: false,
        oneMonthLeads: action.payload,
      };
    }
    case dashboardMap.GET_ONEMONTH_COUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.REFRESH_ONEMONTH_COUNT_DATA: {
      return {
        ...state,
        refreshOneMonthDashboard: true,
      };
    }

    // Google Analytics
    case dashboardMap.GET_GOOGLE_ANALYRICS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardMap.GET_GOOGLE_ANALYRICS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshGoogleAnalyticsDashboard: false,
        analyticsData: action.payload,
      };
    }
    case dashboardMap.GET_GOOGLE_ANALYRICS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.REFRESH_GOOGLE_ANALYRICS_DATA: {
      return {
        ...state,
        refreshGoogleAnalyticsDashboard: true,
      };
    }
    case dashboardMap.FILTER_DATE_GOOGLE_ANALYRICS: {
      return {
        ...state,
        filterAnalyticsDate: action.payload,
        refreshGoogleAnalyticsDashboard: true,
      };
    }

    // Google Analytics Graph
    case dashboardMap.GET_GOOGLE_ANALYRICS_GRAPH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardMap.GET_GOOGLE_ANALYRICS_GRAPH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshGoogleAnalyticsGraphDashboard: false,
        analyticsGraphData: action.payload,
      };
    }
    case dashboardMap.GET_GOOGLE_ANALYRICS_GRAPH_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.REFRESH_GOOGLE_ANALYRICS_GRAPH_DATA: {
      return {
        ...state,
        refreshGoogleAnalyticsGraphDashboard: true,
      };
    }
    case dashboardMap.FILTER_DATE_GOOGLE_ANALYRICS_GRAPH: {
      return {
        ...state,
        filterAnalyticsGraphDate: action.payload,
        refreshGoogleAnalyticsGraphDashboard: true,
      };
    }

    // Quote PageData
    case dashboardMap.GET_QUOTEPAGE_COUNT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardMap.GET_QUOTEPAGE_COUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshQuotePageDataDashboard: false,
        QuotepageList: action.payload,
      };
    }
    case dashboardMap.GET_QUOTEPAGE_COUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.SET_DASHBORD_QUOTE_BATCH_NUMBER: {
      return {
        ...state,
        skipQuote: action.payload,
        refreshQuotePageDataDashboard: true,
      };
    }
    case dashboardMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchPage: action.payload,
        refreshQuotePageDataDashboard: true,
      };
    }
    case dashboardMap.FILTER_DATE: {
      return {
        ...state,
        filterDate: action.payload,
        refreshQuotePageDataDashboard: true,
      };
    }
    case dashboardMap.REFRESH_QUOTEPAGE_COUNT_DATA: {
      return {
        ...state,
        skipQuote: 0,
        refreshQuotePageDataDashboard: true,
      };
    }

    //Contact Us
    case dashboardMap.GET_CONTACTPAGE_COUNT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardMap.GET_CONTACTPAGE_COUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshContactPageDataDashboard: false,
        contactUsList: action.payload,
      };
    }
    case dashboardMap.GET_CONTACTPAGE_COUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.SET_DASHBORD_CONTACT_BATCH_NUMBER: {
      return {
        ...state,
        skipContact: action.payload,
        refreshContactPageDataDashboard: true,
      };
    }
    case dashboardMap.SEARCH_TEXT_CONTACTPAGE_CHANGE: {
      return {
        ...state,
        searchContactPage: action.payload,
        refreshContactPageDataDashboard: true,
      };
    }
    case dashboardMap.FILTER_DATE_CONTACTPAGE: {
      return {
        ...state,
        filterContactDate: action.payload,
        refreshContactPageDataDashboard: true,
      };
    }
    case dashboardMap.REFRESH_CONTACTPAGE_COUNT_DATA: {
      return {
        ...state,
        skipContact: 0,
        refreshContactPageDataDashboard: true,
      };
    }

    //totol leads

    case dashboardMap.GET_TOTAL_LEADS_DASHBORD_COUNT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case dashboardMap.GET_TOTAL_LEADS_DASHBORD_COUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        totalLeadsCount: action.payload,
      };
    }
    case dashboardMap.GET_TOTAL_LEADS_DASHBORD_COUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case dashboardMap.REFRESH_DASHBORD_LIST: {
      return {
        ...state,
        skip: 0,
        refreshMonthDashboard: true,
      };
    }
    case dashboardMap.REFRESH_COUNTRY_DASHBORD_LIST: {
      return {
        ...state,
        skip: 0,
        refreshCountryDashboard: true,
      };
    }
    case dashboardMap.SET_DASHBORD_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshMonthDashboard: true,
      };
    }

    case dashboardMap.SET_DASHBORD_COUNTRY_BATCH_NUMBER: {
      return {
        ...state,
        skipCountries: action.payload,
        refreshCountryDashboard: true,
      };
    }

    case dashboardMap.GET_MONTHLY_LEADS_DASHBORD_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case dashboardMap.GET_MONTHLY_LEADS_DASHBORD_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshMonthDashboard: false,
        monthlyLeadsLists: action.payload,
      };
    }
    case dashboardMap.GET_MONTHLY_LEADS_DASHBORD_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case dashboardMap.GET_COUNTRY_LEADS_DASHBORD_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case dashboardMap.GET_COUNTRY_LEADS_DASHBORD_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshCountryDashboard: false,
        countryLeadsList: action.payload,
      };
    }
    case dashboardMap.GET_COUNTRY_LEADS_DASHBORD_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
