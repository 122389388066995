import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { dashboardActions } from "./dashboardAction";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getTotalLeadsCount = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(dashboardActions.getTotalLeadsDashboardCountStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/dashboard/totalLeadsCount`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          dashboardActions.getTotalLeadsDashboardCountSuccess(data.data)
        );
      }
      dispatch(dashboardActions.getTotalLeadsDashboardCountError());
      return dispatch(showSuccessSnackbar("error", data.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getTotalLeadsDashboardCountError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getMonthlyLeadsList = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      const { skip, limit } = getState().dashboard;
      dispatch(dashboardActions.getMonthlyLeadsDashboardListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/dashboard/monthlyLeads?skip=${skip}&limit=${limit}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(
          dashboardActions.getMonthlyLeadsDashboardListSuccess(data?.data)
        );
      }
      dispatch(dashboardActions.getMonthlyLeadsDashboardListError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getMonthlyLeadsDashboardListError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getCountryLeadsList = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      const { skipCountries, limitCountries } = getState().dashboard;

      dispatch(dashboardActions.getCountryLeadsDashboardListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/dashboard/topCountriesLead?skip=${skipCountries}&limit=${limitCountries}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(
          dashboardActions.getCountryLeadsDashboardListSuccess(data?.data)
        );
      }
      dispatch(dashboardActions.getCountryLeadsDashboardListError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getCountryLeadsDashboardListError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getGoogleAnalytics = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      const { filterAnalyticsDate } = getState().dashboard;

      dispatch(dashboardActions.getGoogleAnalyticsDataStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/dashboard/googleData?to=${filterAnalyticsDate.toDateAnalytics}&from=${filterAnalyticsDate.fromDateAnalytics}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(
          dashboardActions.getGoogleAnalyticsDataSuccess(data?.data)
        );
      }
      dispatch(dashboardActions.getGoogleAnalyticsDataError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getGoogleAnalyticsDataError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getGoogleAnalyticsForGraph = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      const { filterAnalyticsGraphDate } = getState().dashboard;

      dispatch(dashboardActions.getGoogleAnalyticsDataGraphStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/dashboard/googleDataGraph?to=${filterAnalyticsGraphDate[0].toDateGraphAnalytics}&from=${filterAnalyticsGraphDate[0].fromDateGraphAnalytics}&session=${filterAnalyticsGraphDate[0].sessionValue}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(
          dashboardActions.getGoogleAnalyticsDataGraphSuccess(data?.data)
        );
      }
      dispatch(dashboardActions.getGoogleAnalyticsDataGraphError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getGoogleAnalyticsDataGraphError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getQuotePageCount = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      const {
        skipQuote,
        limitQuote,
        searchPage,
        filterDate,
      } = getState().dashboard;
      dispatch(dashboardActions.getQuotePageStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/quote/getPageCount?skip=${skipQuote}&limit=${limitQuote}&search=${searchPage}&to=${filterDate.toDate}&from=${filterDate.fromDate}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(dashboardActions.getQuotePageSuccess(data?.data));
      }
      dispatch(dashboardActions.getQuotePageError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getQuotePageError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getContactUsPageCount = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      const {
        skipContact,
        limitContact,
        searchContactPage,
        filterContactDate,
      } = getState().dashboard;
      dispatch(dashboardActions.getContactPageStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/contactUs/getPageCount?skip=${skipContact}&limit=${limitContact}&search=${searchContactPage}&to=${filterContactDate.toContactDate}&from=${filterContactDate.fromContactDate}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(dashboardActions.getContactPageSuccess(data?.data));
      }
      dispatch(dashboardActions.getContactPageError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getContactPageError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getOneMonthCount = (timestamp) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      dispatch(dashboardActions.getOneMonthCountStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/dashboard/getOneMonthCount?seletedDate=${timestamp}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data?.code === 200) {
        return dispatch(dashboardActions.getOneMonthCountSuccess(data?.data));
      }
      dispatch(dashboardActions.getOneMonthCountError());
      dispatch(showSuccessSnackbar("error", data?.message, 30000));
    } catch (error) {
      dispatch(dashboardActions.getOneMonthCountError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getKeyWordRank = (key, seletedValue) => {
  const apiKey = process.env.REACT_APP_APIKEY;
  const customSearchEngineId = process.env.REACT_APP_SEARCH_ENGINE_ID;
  const website = process.env.REACT_APP_WEBSITE;
  const countries = seletedValue;
  const keyword = key;
  const result = [];
  let Err;
  // console.log(
  //   "apiKey",
  //   apiKey,
  //   "customSearchEngineId",
  //   customSearchEngineId,
  //   "seletedValue",
  //   seletedValue,
  //   "key",
  //   key
  // );
  // return false;
  return async (dispatch) => {
    try {
      let requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      for (let country of countries) {
        dispatch(dashboardActions.getKeywordRankStart());
        const res = await fetch(
          `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${customSearchEngineId}&q=${encodeURIComponent(
            keyword
          )}&gl=${country}`,
          requestOptions
        );
        const data = await res.json();

        if (data?.items) {
          const items = data.items;

          const websiteIndex = items.findIndex((item) =>
            item.link.includes(website)
          );
          if (websiteIndex > -1) {
            let origin = items[websiteIndex]?.link;

            const rank = websiteIndex + 1;
            result.push({ rank, country, origin });
          } else {
            result.push({ rank: "Not in 10", country, origin: "-" });
          }
        } else {
          console.error("Error");
        }
      }
      if (result.length) {
        return dispatch(dashboardActions.getKeywordRankSuccess(result));
      } else {
        dispatch(dashboardActions.getKeywordRankError());
        dispatch(showSuccessSnackbar("error", Err, 30000));
      }
    } catch (error) {
      console.log("error", error);
      dispatch(dashboardActions.getKeywordRankError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
