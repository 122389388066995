export const quoteMap = {
  GET_ALL_QUOTE_START: "GET_ALL_QUOTE_START",
  GET_ALL_QUOTE_SUCCESS: "GET_ALL_QUOTE_SUCCESS",
  GET_ALL_QUOTE_ERROR: "GET_ALL_QUOTE_ERROR",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  SET_QUOTE_BATCH_NUMBER: "SET_QUOTE_BATCH_NUMBER",
  GET_QUOTE_START: "GET_QUOTE_START",
  GET_QUOTE_SUCCESS: "GET_QUOTE_SUCCESS",
  GET_QUOTE_ERROR: "GET_QUOTE_ERROR",
  SELECT_QUOTE_DETAILS: "SELECT_QUOTE_DETAILS",
  GET_QUOTE_PAGE_NAME_LIST_SUCCESS: "GET_QUOTE_PAGE_NAME_LIST_SUCCESS",
};

export const quoteAction = {
  getAllContactStart: (data) => ({
    type: quoteMap.GET_ALL_QUOTE_START,
    payload: data,
  }),
  getAllContactSuccess: (data) => ({
    type: quoteMap.GET_ALL_QUOTE_SUCCESS,
    payload: data,
  }),
  getAllContactError: (errors) => ({
    type: quoteMap.GET_ALL_QUOTE_ERROR,
    payload: { errors },
  }),
  refreshContactList: () => ({ type: quoteMap.REFRESH_QUOTE_LIST }),
  searchTextChange: (data) => ({
    type: quoteMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setContactBatchNumber: (data) => ({
    type: quoteMap.SET_QUOTE_BATCH_NUMBER,
    payload: data,
  }),
  getContactStart: (data) => ({
    type: quoteMap.GET_QUOTE_START,
    payload: data,
  }),
  getContactSuccess: (data) => ({
    type: quoteMap.GET_QUOTE_SUCCESS,
    payload: data,
  }),
  getContactError: (errors) => ({
    type: quoteMap.GET_QUOTE_ERROR,
    payload: { errors },
  }),
  getContactError: (errors) => ({
    type: quoteMap.GET_QUOTE_ERROR,
    payload: { errors },
  }),
  getQuotePageNameListSuccess: (data) => ({
    type: quoteMap.GET_QUOTE_PAGE_NAME_LIST_SUCCESS,
    payload:data,
  })
};
