import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getContactUsAsync } from "../redux";
import { useParams } from 'react-router-dom';
import { Col, Row, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Card, CardHeader, CardBody, } from "../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";

const ViewContactUs = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { ContactList } = useSelector((state) => state.contactUs);
    const { id } = useParams();

    useEffect(() => {
        dispatch(getContactUsAsync(id));
        // eslint-disable-next-line
    }, []);

    const redirectBack = () => {
        history.push("/careers");
    };

    return (
        <>
            <Formik enableReinitialize initialValues={ContactList} >
                {({
                    values
                }) => (
                    <Row className="justify-content-center">
                        <Col lg={11}>
                            <Card>
                                <CardHeader title="View Careers"></CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="form-group">
                                            <label className="form-label"> Job Position Name</label>
                                            <input className="form-control re_inputRouded" placeholder="Job Position Name" type="text" disabled={true} name="jobPositionName" value={values.jobPositionName} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> Your Name</label>
                                            <input className="form-control re_inputRouded" placeholder="Your Name" type="text" disabled={true} name="yourName" value={values.yourName} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> Email</label>
                                            <Link href="#" onClick={(e) => {
                                                window.location = `mailto:${values.email}`;
                                                e.preventDefault();
                                            }}>
                                                <input className="form-control re_inputRouded" style={{ cursor: "pointer", color: "blue", backgroundColor: "#F3F6F9", opacity: 1 }} placeholder="Email" type="text" disabled={true} name="emails" value={values.email} />
                                            </Link>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> Contact No.</label>
                                            <Link href="#" onClick={(e) => {
                                                window.location = `tel:${values.countryCode}${values.contactNumber}`;
                                                e.preventDefault();
                                            }}>
                                                <input className="form-control re_inputRouded" style={{ cursor: "pointer", color: "blue" }} placeholder="Contact Number" type="text" disabled={true} name="contactNumber" value={`${values.contactNumber}`} />
                                            </Link>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> Total Experience</label>
                                            <input className="form-control re_inputRouded" placeholder="Total Experience" type="text" disabled={true} name="totalExperience" value={values.totalExperience} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> Linkedin Profile</label>
                                            <input className="form-control re_inputRouded" placeholder="Linkedin Profile" type="text" disabled={true} name="linkedinProfile" value={values.linkedinProfile} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> Your Message</label>
                                            <textarea className="form-control re_inputRouded" rows={5} placeholder="Your Message" type="text" disabled={true} name="yourMessage" value={values.yourMessage} />
                                        </div>
                                        
                                        <div className="form-group">
                                            <label className="form-label"> Page Name</label>
                                            <input className="form-control re_inputRouded" placeholder="PageName" type="text" disabled={true} name="pagename" value={values.pageName} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"> CV File</label>
                                            <Link href="#" onClick={(e) => {window.open(`${values.uploadFileResume}`,'_blank');
                                                e.preventDefault();
                                            }}>
                                                <input className="form-control re_inputRouded" style={{ cursor: "pointer", color: "blue" }} placeholder="uploadFileResume" type="text" disabled={true} name="uploadFileResume" value={values.uploadFileResume} />
                                            </Link>
                                        </div>
                                        <div className="text-center">
                                            <Button variant="blue" className="px-5 defpddng " size="lg" onClick={redirectBack}>
                                                Close
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Formik>
        </>
    )
}

export default ViewContactUs;
