import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Chart from "../../../components/Chart/Chart";
import Select from "react-select";
import {
  dashboardActions,
  getGoogleAnalyticsForGraph,
  getOneMonthCount,
} from "../redux";
import { memo, useMemo, useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { FiFilter } from "react-icons/fi";
import { LuFilterX } from "react-icons/lu";

const DashboardChart = ({
  series,
  xAxis,
  height,
  customOptions,
  graph,
  text,
  data,
  selectOptions,
}) => {
  const [show, setShow] = useState(true);
  const [showSingle, setShowSingle] = useState(false);
  const [graphSesion, setGraphSession] = useState();

  const [monthlySeries, setMonthlySeries] = useState();
  const [toErrAnalytics, setToErrAnalytics] = useState(false);
  const [fromErrAnalytics, setFromErrAnalytics] = useState(false);
  const [toDateAnalytics, setToDateAnalytics] = useState();
  const [fromDateAnalytics, setFromDateAnalytics] = useState();
  const [removeFilter, setRemoveFilter] = useState(true);
  const [filter, setFilter] = useState(false);

  const dispatch = useDispatch();

  const {
    oneMonthLeads,
    refreshMonthDashboard,
    analyticsGraphData,
    refreshGoogleAnalyticsGraphDashboard,
  } = useSelector((state) => state.dashboard, shallowEqual);

  
  useEffect(() => {
    if (series[0].data.length) {
      setMonthlySeries(series);
    }
  }, [refreshMonthDashboard]);

  useEffect(
    () => {
      if (data === "google") {
        if (analyticsGraphData?.length) {
          setFromErrAnalytics(false);
          setFromErrAnalytics(true);
        } else {
          setFromErrAnalytics(false);
        }
      }
    },
    useMemo(() => [data])
  );

  const filterByDate = (session) =>
    dispatch(
      dashboardActions.filterDateAnalyticsGraph([
        {
          sessionValue: graphSesion ? graphSesion : session ? session : "",
          toDateGraphAnalytics: toDateAnalytics
            ? new Date(toDateAnalytics)
            : "",
          fromDateGraphAnalytics: fromDateAnalytics
            ? new Date(fromDateAnalytics)
            : "",
        },
      ])
    );

  const oneMonthSeries = oneMonthLeads.values;

  const oneMonthXaxis = oneMonthLeads.labels;

  const options = selectOptions.map((selectOption) => {
    return { value: selectOption, label: selectOption };
  });

  const optionsSessions = [{ value: "all", label: "all" }, ...options];

  const analyticsGraph = useMemo(() => {
    const analyticsGraphSeries = [];
    const analyticsGraphXais = [];

    if (analyticsGraphData?.length > 0) {
      analyticsGraphData?.forEach((data) => {
        analyticsGraphSeries.push(data.metricValues[0].value);
        analyticsGraphXais.push(data.dimensionValues[0].value);
      });
      return {
        analyticsGraphSeries,
        analyticsGraphXais,
      };
    }
  }, [analyticsGraphData]);

  const handleSelectChangeTable = (e) => {
    if (e.value === "all Months") {
      setShow(true);
    } else {
      let dateString = e.value;
      let formattedDateString = dateString.replace("-", "");
      let desiredDate = new Date(formattedDateString);
      let timestamp = desiredDate.getTime();
      if (timestamp) {
        dispatch(getOneMonthCount(timestamp));
        setShow(false);
      }
    }
  };

  const handleSelectChangeGraph = (e) => {
    if (e.value === "all") {
      setToDateAnalytics();
      setFromDateAnalytics();
      setToErrAnalytics(false);
      setFromErrAnalytics(false);
      setShowSingle(false);
    } else {
      let session = e.value;
      setGraphSession(session);
      setShowSingle(true);

      filterByDate(session);
    }
  };

  const handleToDateChangeAnalytics = (date) => {
    setToDateAnalytics(date);
    if (!date) {
      setToDateAnalytics(null);
      filterByDate();
    } else {
      setToDateAnalytics(date);
    }
    setToErrAnalytics(false);
    setFromErrAnalytics(false);
  };

  const handleFromDateChangeAnalytics = (date) => {
    if (!date) {
      setFromDateAnalytics(null);
      filterByDate();
    } else {
      setFromDateAnalytics(date);
    }
    setToErrAnalytics(false);

    setFromErrAnalytics(false);

    if (fromDateAnalytics && date <= toDateAnalytics) {
      setFromErrAnalytics(true);
    }
  };
  const handleFilterDateAnalytics = () => {
    if (toDateAnalytics || fromDateAnalytics) {
      filterByDate();
      setShowSingle(true);
    } else {
      setToErrAnalytics(true);
      setFromErrAnalytics(true);
    }
  };

  const handleFilter = () => {
    setRemoveFilter(false);
    setFilter(true);
  };

  const handleClearFilter = () => {
    setRemoveFilter(true);
    if (filter) setFilter(false);
    else setFilter(true);
    dispatch(
      dashboardActions.filterDateAnalyticsGraph([
        {
          sessionValue: graphSesion ? graphSesion : "",
          toDateGraphAnalytics: toDateAnalytics
            ? new Date(toDateAnalytics)
            : "",
          fromDateGraphAnalytics: fromDateAnalytics
            ? new Date(fromDateAnalytics)
            : "",
        },
      ])
    );
  };

  return (
    <>
      {data === "leadsMonth" ? (
        <>
          <Select
            className="select-wrap select-dashboard-chart "
            classNamePrefix="react-select"
            placeholder="Select Month"
            // className={`form-control border-0 p-0 `}
            options={options}
            onChange={handleSelectChangeTable}
          />
          <Chart
            series={
              show
                ? monthlySeries
                : [
                    {
                      name: "leads",
                      data: oneMonthSeries.length ? oneMonthSeries : [],
                    },
                  ]
            }
            xAxis={show ? xAxis.filter((x) => x !== "all") : oneMonthXaxis}
            height={height}
            customOptions={customOptions}
            graph={graph}
            // type={}
            text={text}
          />
        </>
      ) : (
        <>
          <div className="page_type">
            <Select
              className="select-dashboard-google-chart"
              classNamePrefix="react-select"
              placeholder="Select Channel Group"
              // className={`form-control border-0 p-0 `}
              options={optionsSessions}
              onChange={handleSelectChangeGraph}
            />
            {removeFilter ? (
              <FiFilter
                title="Advance Filter"
                className=""
                size="2em"
                type="button"
                onClick={handleFilter}
              />
            ) : (
              <LuFilterX
                title="Clear Filter"
                className="ml-2"
                size="2em"
                type="button"
                onClick={handleClearFilter}
              />
            )}
          </div>
          <>
            {filter && (
              <div className="date-picker-container pt-5 ">
                <DatePicker
                  placeholderText="Select to date"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={toDateAnalytics}
                  onChange={handleToDateChangeAnalytics}
                  name="createdDate"
                  className={
                    toErrAnalytics
                      ? "form-control re_inputRouded is-invalid date-picker ml-10"
                      : "form-control re_inputRouded date-picker ml-2"
                  }
                />
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select from date"
                  autoComplete="off"
                  selected={fromDateAnalytics}
                  onChange={handleFromDateChangeAnalytics}
                  name="createdDate"
                  className={
                    fromErrAnalytics
                      ? "form-control re_inputRouded is-invalid date-picker ml-10"
                      : "form-control re_inputRouded date-picker"
                  }
                />
                <button
                  type="submit"
                  onClick={handleFilterDateAnalytics}
                  className="search-button"
                >
                  Search
                </button>
              </div>
            )}
          </>

          <Chart
            series={
              showSingle
                ? [
                    {
                      name: "leads",
                      data: analyticsGraph?.analyticsGraphSeries.length
                        ? analyticsGraph?.analyticsGraphSeries
                        : [],
                    },
                  ]
                : series
            }
            xAxis={showSingle ? analyticsGraph?.analyticsGraphXais : xAxis}
            height={height}
            customOptions={customOptions}
            graph={graph}
            type="bar"
            text={text}
          />
        </>
      )}
    </>
  );
};

export default memo(DashboardChart);
