export const userMap = {
  GET_ADD_USER_START: "GET_ADD_USER_START",
  GET_ADD_USER_SUCCESS: "GET_ADD_USER_SUCCESS",
  GET_ADD_USER_ERROR: "GET_ADD_USER_ERROR",
  SET_SELECTED_ADD_USER: "SET_SELECTED_ADD_USER",
  SET_ADD_USER_BATCH_NUMBER: "SET_ADD_USER_BATCH_NUMBER",
  UPDATE_ADD_USER_START: "UPDATE_ADD_USER_START",
  UPDATE_ADD_USER_SUCCESS: "UPDATE_ADD_USER_SUCCESS",
  UPDATE_ADD_USER_ERROR: "UPDATE_ADD_USER_ERROR",
  DELETE_ADD_USER_START: "DELETE_ADD_USER_START",
  DELETE_ADD_USER_SUCCESS: "DELETE_ADD_USER_SUCCESS",
  DELETE_ADD_USER_ERROR: "DELETE_ADD_USER_ERROR",
  ADD_ADD_USER_START: "ADD_ADD_USER_START",
  ADD_ADD_USER_SUCCESS: "ADD_ADD_USER_SUCCESS",
  ADD_ADD_USER_ERROR: "ADD_ADD_USER_ERROR",
  CREATE_ADD_USER_CLOSE: "CREATE_ADD_USER_CLOSE",
  CREATE_ADD_USER_SHOW: "CREATE_ADD_USER_SHOW",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  GET_ROLE_PERMISSION_START: "GET_ROLE_PERMISSION_START",
  GET_ROLE_PERMISSION_SUCCESS: "GET_ROLE_PERMISSION_SUCCESS",
  GET_ROLE_PERMISSION_ERROR: "GET_ROLE_PERMISSION_ERROR",
  GET_USER_DETAIL_START: "GET_USER_DETAIL_START",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_ERROR: "GET_USER_DETAIL_ERROR",
};

export const userActions = {
  getSubUserStart: (data) => ({
    type: userMap.GET_ADD_USER_START,
    payload: data,
  }),
  getSubUserSuccess: (data) => ({
    type: userMap.GET_ADD_USER_SUCCESS,
    payload: data,
  }),
  getSubUserError: (data) => ({
    type: userMap.GET_ADD_USER_ERROR,
    payload: data,
  }),

  updateSubUserStart: (data) => ({
    type: userMap.UPDATE_ADD_USER_START,
    payload: data,
  }),
  updateSubUserSuccess: (data) => ({
    type: userMap.UPDATE_ADD_USER_SUCCESS,
    payload: data,
  }),
  updateSubUserError: (errors) => ({
    type: userMap.UPDATE_ADD_USER_ERROR,
    payload: { errors },
  }),
  setSelectedSubUser: (data) => ({
    type: userMap.SET_SELECTED_ADD_USER,
    payload: data,
  }),
  deleteSubUserStart: (data) => ({
    type: userMap.DELETE_ADD_USER_START,
    payload: data,
  }),
  deleteSubUserSuccess: (data) => ({
    type: userMap.DELETE_ADD_USER_SUCCESS,
    payload: data,
  }),
  deleteSubUserError: (data) => ({
    type: userMap.DELETE_ADD_USER_ERROR,
    payload: data,
  }),
  addSubUserStart: (data) => ({
    type: userMap.ADD_ADD_USER_START,
    payload: data,
  }),
  addSubUserSuccess: (data) => ({
    type: userMap.ADD_ADD_USER_SUCCESS,
    payload: data,
  }),
  addSubUserError: (errors) => ({
    type: userMap.ADD_ADD_USER_ERROR,
    payload: { errors },
  }),
  setSubUserBatchNumber: (data) => ({
    type: userMap.SET_ADD_USER_BATCH_NUMBER,
    payload: data,
  }),
  createSubUserModalClose: () => ({
    type: userMap.CREATE_ADD_USER_CLOSE,
  }),
  createSubUserModalShow: () => ({
    type: userMap.CREATE_ADD_USER_SHOW,
  }),
  searchTextChange: (data) => ({
    type: userMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  getRolePermissionStart: (data) => ({
    type: userMap.GET_ROLE_PERMISSION_START,
    payload: data,
  }),
  getRolePermissionSuccess: (data) => ({
    type: userMap.GET_ROLE_PERMISSION_SUCCESS,
    payload: data,
  }),
  getRolePermissionError: (data) => ({
    type: userMap.GET_ROLE_PERMISSION_ERROR,
    payload: data,
  }),

  getSubUserDetailStart: (data) => ({
    type: userMap.GET_USER_DETAIL_START,
    payload: data,
  }),
  getSubUserDetailSuccess: (data) => ({
    type: userMap.GET_USER_DETAIL_SUCCESS,
    payload: data,
  }),
  getSubUserDetailError: (errors) => ({
    type: userMap.GET_USER_DETAIL_ERROR,
    payload: { errors },
  }),
};
