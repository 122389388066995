import axios from "axios";
import { quoteAction } from "./quoteAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllQuoteAsync = (searchBy, searchText, type) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { skip, limit } = getState().quoteData;
      dispatch(quoteAction.getAllContactStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/quote/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&type=${type}&dir=&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(quoteAction.getAllContactSuccess(data.data));
      }
      dispatch(quoteAction.getAllContactError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      console.log(error);
      dispatch(quoteAction.getAllContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getQuoteAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(quoteAction.getContactStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/quote/getquote/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(quoteAction.getContactSuccess(data.data));
      }
      dispatch(quoteAction.getContactError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      console.log(error);
      dispatch(quoteAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteQuoteAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const dataarra = [id];

      const adminBaseURL = getAdminURL(getState());
      dispatch(quoteAction.getContactStart());
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/quote/removeMultiQuote/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          data: dataarra,
        },
      });
      console.log(id);
      if (data.code === 200) {
        return dispatch(quoteAction.getContactSuccess(data.data));
      }
      dispatch(quoteAction.getContactError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      console.log(error);
      dispatch(quoteAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteSelectedQuote = (id) => {
  return async (dispatch, getState) => {
    try {
      const idsArray = id;
      const adminBaseURL = getAdminURL(getState());
      dispatch(quoteAction.getContactStart());
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/quote/deleteSelectedQuote/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: idsArray,
        },
      });
      console.log(id);
      if (data.code === 200) {
        return dispatch(quoteAction.getContactSuccess(data.data));
      }
      dispatch(quoteAction.getContactError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      console.log(error);
      dispatch(quoteAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getQuotePageNameListAsync = () => {
  return async (dispatch, getState) => {
    try {
      const { pageNameList } = getState().quoteData;
      if (pageNameList.length > 0) {
        return;
      }
      const adminBaseURL = getAdminURL(getState());
      dispatch(quoteAction.getContactStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/quote/getPageNameList`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(quoteAction.getQuotePageNameListSuccess(data.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(quoteAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
