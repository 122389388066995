export const generativeAI = {
  GET_ALL_GENERATIVEAI_START: "GET_ALL_GENERATIVEAI_START",
  GET_ALL_GENERATIVEAI_SUCCESS: "GET_ALL_GENERATIVEAI_SUCCESS",
  GET_ALL_GENERATIVEAI_ERROR: "GET_ALL_GENERATIVEAI_ERROR",

  GET_GENERATIVEAI_START: "GET_GENERATIVEAI_START",
  GET_GENERATIVEAI_SUCCESS: "GET_GENERATIVEAI_SUCCESS",
  GET_GENERATIVEAI_ERROR: "GET_GENERATIVEAI_ERROR",

  DELETE_GENERATIVEAI_START: "DELETE_GENERATIVEAI_START",
  DELETE_GENERATIVEAI_SUCCESS: "DELETE_GENERATIVEAI_SUCCESS",
  DELETE_GENERATIVEAI_ERROR: "DELETE_GENERATIVEAI_ERROR",

  DELETE_MULTIPLE_GENERATIVEAI_START: "DELETE_MULTIPLE_GENERATIVEAI_START",
  DELETE_MULTIPLE_GENERATIVEAI_SUCCESS: "DELETE_MULTIPLE_GENERATIVEAI_SUCCESS",
  DELETE_MULTIPLE_GENERATIVEAI_ERROR: "DELETE_MULTIPLE_GENERATIVEAI_ERROR",

  // SELECT_GENERATIVEAI_DETAILS: "SELECT_GENERATIVEAI_DETAILS",
  // GET_GENERATIVEAI_PAGE_NAME_LIST_SUCCESS: "GET_GENERATIVEAI_PAGE_NAME_LIST_SUCCESS",
  
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_GENERATIVEAI_LIST: "REFRESH_GENERATIVEAI_LIST",
  SET_GENERATIVEAI_BATCH_NUMBER: "SET_GENERATIVEAI_BATCH_NUMBER",
};

export const generativeAIAction = {
  getAllGenerativeAIStart: (data) => ({
    type: generativeAI.GET_ALL_GENERATIVEAI_START,
    payload: data,
  }),
  getAllGenerativeAISuccess: (data) => ({
    type: generativeAI.GET_ALL_GENERATIVEAI_SUCCESS,
    payload: data,
  }),
  getAllGenerativeAIError: (errors) => ({
    type: generativeAI.GET_ALL_GENERATIVEAI_ERROR,
    payload: { errors },
  }),


  getGenerativeAIStart: (data) => ({
    type: generativeAI.GET_GENERATIVEAI_START,
    payload: data,
  }),
  getGenerativeAISuccess: (data) => ({
    type: generativeAI.GET_GENERATIVEAI_SUCCESS,
    payload: data,
  }),
  getGenerativeAIError: (errors) => ({
    type: generativeAI.GET_GENERATIVEAI_ERROR,
    payload: { errors },
  }),


  deleteGenerativeAIStart: (data) => ({
    type: generativeAI.DELETE_GENERATIVEAI_START,
    payload: data,
  }),
  deleteGenerativeAISuccess: (data) => ({
    type: generativeAI.DELETE_GENERATIVEAI_SUCCESS,
    payload: data,
  }),
  deleteGenerativeAIError: (errors) => ({
    type: generativeAI.DELETE_GENERATIVEAI_ERROR,
    payload: { errors },
  }),


  deleteMultipleGenerativeAIStart: (data) => ({
    type: generativeAI.DELETE_MULTIPLE_GENERATIVEAI_START,
    payload: data,
  }),
  deleteMultipleGenerativeAISuccess: (data) => ({
    type: generativeAI.DELETE_MULTIPLE_GENERATIVEAI_SUCCESS,
    payload: data,
  }),
  deleteMultipleGenerativeAIError: (errors) => ({
    type: generativeAI.DELETE_MULTIPLE_GENERATIVEAI_ERROR,
    payload: { errors },
  }),


  searchTextChange: (data) => ({
    type: generativeAI.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  refreshGenerativeAIList: () => ({ type: generativeAI.REFRESH_GENERATIVEAI_LIST }),
  setGenerativeAIBatchNumber: (data) => ({
    type: generativeAI.SET_GENERATIVEAI_BATCH_NUMBER,
    payload: data,
  }),

};
