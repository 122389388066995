import { AuthorManagementMap } from "./authorManagementAction";

const initialState = {
  isLoading: false,
  authorList: {},
  refreshAuthorList: true,
  selectedAuthor: null,
  searchBy: "",
  searchText: "",
  skip: 0,
  limit: 10,
  authors:[]
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthorManagementMap.GET_ALL_AUTHOR_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthorManagementMap.GET_ALL_AUTHOR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        authorList: action.payload,
        refreshAuthorList: false,
      };
    }
    case AuthorManagementMap.GET_ALL_AUTHOR_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshAuthorList: false,
      };
    }
    case AuthorManagementMap.ADD_AUTHOR_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthorManagementMap.ADD_AUTHOR_SUCCESS: {
      return {
        ...state,
        refreshAuthorList: true,
      };
    }
    case AuthorManagementMap.ADD_AUTHOR_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshAuthorList: false,
      };
    }
    case AuthorManagementMap.UPDATE_AUTHOR_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthorManagementMap.UPDATE_AUTHOR_SUCCESS: {
      return {
        ...state,
        refreshAuthorList: true,
      };
    }
    case AuthorManagementMap.UPDATE_AUTHOR_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshAuthorList: false,
      };
    }
    case AuthorManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case AuthorManagementMap.SET_AUTHOR_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshAuthorList: true,
      };
    }
    case AuthorManagementMap.REFRESH_AUTHOR_LIST: {
      return {
        ...state,
        skip: 0,
        refreshAuthorList: true,
      };
    }
    case AuthorManagementMap.DELETE_AUTHOR_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthorManagementMap.DELETE_AUTHOR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshAuthorList: true,
      };
    }
    case AuthorManagementMap.DELETE_AUTHOR_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshAuthorList: false,
      };
    }
    case AuthorManagementMap.GET_AUTHOR_DETAIL_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthorManagementMap.GET_AUTHOR_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selectedAuthor: action.payload,
        refreshBlog: false,
      };
    }
    case AuthorManagementMap.GET_AUTHOR_DETAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlog: false,
      };
    }
    case AuthorManagementMap.GET_AUTHOR_LIST_START: {
      return {
        ...state,
      };
    }
    case AuthorManagementMap.GET_AUTHOR_LIST_SUCCESS: {
      return {
        ...state,
        authors: action.payload,
      };
    }
    case AuthorManagementMap.GET_AUTHOR_LIST_ERROR: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};
