import { userMap } from "./userAction";

const initialState = {
  isLoading: false,
  refreshSubUserList: true,
  searchBy: "",
  subUserList: {},
  searchText: "",
  searchStatus: "",
  skip: 0,
  subUserSkip: 0,
  subUserLimit: 100,
  column: "",
  dir: "",
  limit: 100,
  selectedSubUser: {},
  createSubUserModal: false,
  rolePermissionList: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case userMap.GET_ADD_USER_START: {
      return {
        ...state,
        isSubUserLoading: true,
      };
    }
    case userMap.GET_ADD_USER_SUCCESS: {
      return {
        ...state,
        refreshSubUserList: false,
        isSubUserLoading: false,
        subUserList: action.payload,
      };
    }
    case userMap.GET_ADD_USER_ERROR: {
      return {
        ...state,
        refreshSubUserList: false,
        isSubUserLoading: false,
        subUserList: {},
      };
    }
    case userMap.SET_ADD_USER_BATCH_NUMBER: {
      return {
        ...state,
        subUserSkip: action.payload,
        refreshSubUserList: true,
      };
    }
    case userMap.SET_SELECTED_ADD_USER: {
      return {
        ...state,
        selectedSubUser: action.payload,
      };
    }

    case userMap.UPDATE_ADD_USER_START: {
      return {
        ...state,
        isSubUserLoading: true,
      };
    }
    case userMap.UPDATE_ADD_USER_SUCCESS: {
      return {
        ...state,
        isSubUserLoading: false,
        refreshSubUserList: true,
      };
    }
    case userMap.UPDATE_ADD_USER_ERROR: {
      return {
        ...state,
        isSubUserLoading: false,
        refreshSubUserList: false,
      };
    }
    case userMap.DELETE_ADD_USER_START: {
      return {
        ...state,
        isSubUserLoading: true,
      };
    }
    case userMap.DELETE_ADD_USER_SUCCESS: {
      return {
        ...state,
        isSubUserLoading: false,
        refreshSubUserList: true,
      };
    }
    case userMap.DELETE_ADD_USER_ERROR: {
      return {
        ...state,
        isSubUserLoading: false,
        refreshSubUserList: false,
      };
    }
    case userMap.ADD_ADD_USER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case userMap.ADD_ADD_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshSubUserList: true,
      };
    }
    case userMap.ADD_ADD_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case userMap.CREATE_ADD_USER_MANAGEMENT_CLOSE: {
      return {
        ...state,
        createSubUserModal: false,
      };
    }
    case userMap.CREATE_ADD_USER_MANAGEMENT_SHOW: {
      return {
        ...state,
        createSubUserModal: true,
      };
    }
    case userMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
        refreshSubUserList: true,
      };
    }
    case userMap.GET_ROLE_PERMISSION_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case userMap.GET_ROLE_PERMISSION_SUCCESS: {
      return {
        ...state,
        refreshSubUserList: false,
        isLoading: false,
        rolePermissionList: action.payload,
      };
    }
    case userMap.GET_ROLE_PERMISSION_ERROR: {
      return {
        ...state,
        refreshSubUserList: false,
        isLoading: false,
        rolePermissionList: {},
      };
    }
    case userMap.GET_USER_DETAIL_START: {
      return {
        ...state,
        isSubUserLoading: true,
      };
    }
    case userMap.GET_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        isSubUserLoading: false,
        selectedSubUser: action.payload,
      };
    }
    case userMap.GET_USER_DETAIL_ERROR: {
      return {
        ...state,
        isSubUserLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
