import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { getGenAIAsync } from "../redux";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";

const ViewGenerativeAI = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { GenAIList } = useSelector((state) => state.genAIData);
  const { id } = useParams();
  let { cdnURL } = useSelector((state) => state.environnment.environmentLists);
  useEffect(() => {
    dispatch(getGenAIAsync(id));
    // eslint-disable-next-line
  }, []);

  const redirectBack = () => {
    history.push("/generativeAI");
  };

  return (
    <>
      <Formik enableReinitialize initialValues={GenAIList}>
        {({ values }) => (
          <Row className="justify-content-center">
            {console.log(values, "valuesvalues")}
            <Col lg={11}>
              <Card>
                <CardHeader title="View Generative AI"></CardHeader>
                <CardBody>
                  <Form>
                    <div className="form-group">
                      <label className="form-label"> Name</label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="Name"
                        type="text"
                        disabled={true}
                        name="name"
                        value={values.name}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Email</label>
                      <Link
                        href="#"
                        onClick={(e) => {
                          window.location = `mailto:${values.email}`;
                          e.preventDefault();
                        }}
                      >
                        <input
                          className="form-control re_inputRouded"
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            backgroundColor: "#F3F6F9",
                            opacity: 1,
                          }}
                          placeholder="Email"
                          type="text"
                          disabled={true}
                          name="emails"
                          value={values.email}
                        />
                      </Link>
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Contact No.</label>
                      <Link
                        href="#"
                        onClick={(e) => {
                          window.location = `tel:${values.countryCode}${values.contactNumber}`;
                          e.preventDefault();
                        }}
                      >
                        <input
                          className="form-control re_inputRouded"
                          style={{ cursor: "pointer", color: "blue" }}
                          placeholder="Contact Number"
                          type="text"
                          disabled={true}
                          name="contactNumber"
                          value={`${values.contactNumber}`}
                        />
                      </Link>
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Country From IP</label>
                      <input
                        className="form-control re_inputRouded"
                        style={{ cursor: "pointer", color: "blue" }}
                        placeholder="Country From IP"
                        type="text"
                        disabled={true}
                        name="countryName"
                        value={values.countryName}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Country From Phone Ext
                      </label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="Country From Phone Ext"
                        type="text"
                        disabled={true}
                        name="countryNameFromCountryCode"
                        value={values.countryNameFromCountryCode}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Industry</label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="Industry"
                        type="text"
                        disabled={true}
                        name="industry"
                        value={values.industry}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Project Requirements
                      </label>
                      <textarea
                        className="form-control re_inputRouded"
                        rows={5}
                        placeholder="Project Requirement"
                        type="text"
                        disabled={true}
                        name="pro_requirement"
                        value={values.pro_requirements}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Services</label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="PageName"
                        type="text"
                        disabled={true}
                        name="services"
                        value={values.services}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label className="form-label"> Page Name</label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="PageName"
                        type="text"
                        disabled={true}
                        name="pagename"
                        value={values.pageName}
                      />
                    </div> */}
                    <div className="text-center">
                      <Button
                        variant="blue"
                        className="px-5 defpddng "
                        size="lg"
                        onClick={redirectBack}
                      >
                        Close
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default ViewGenerativeAI;
