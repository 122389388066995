import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { EnvironmentActions } from "../../../../app/modules/GetEnvironment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";
import { useIsAccessAllow } from "../../../../app/modules/users/redux";
// import { useIsAccessAllow } from "../../../../../../../users/redux";

export function Topbar() {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  /* HireAI GetQuote Allow Start */
  const [filterWebsiteLists, setFilterWebsiteLists] = useState([]);

  const { websiteLists, web_id } = useSelector(
    (state) => state.environnment,
    shallowEqual
  );

  const { user } = useSelector((state) => state.auth);

  const isHireAIGetQuoteAllow = useIsAccessAllow(
    "get_a_quote.hire_ai_get_quote"
  );

  const filterData = websiteLists.filter((item) => {
    if (isHireAIGetQuoteAllow && user.roleId !== 1) {
      return item.value === "hireaideveloper";
    }
  });

  useEffect(() => {
    if (filterData.length > 0) {
      setFilterWebsiteLists(filterData);
    } else {
      setFilterWebsiteLists(websiteLists);
    }
  }, [isHireAIGetQuoteAllow]);
  /* HireAI GetQuote Allow End */

  const defaultWebList = [
    {
      value: web_id,
      label: web_id == "all" ? "All" : web_id,
    },
  ];

  const changeWebID = (e) => {
    dispatch(EnvironmentActions.updateEnvironmentsWebId(e.value));
    window.location.reload();
  };

  return (
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

      {/*layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />*/}
      <div className="form-row" style={{ alignItems: "center" }}>
        <span> WEB ID</span>
        <div
          className="form-group m-2"
          style={{ width: "200px", marginRight: "50px" }}
        >
          <Select
            classNamePrefix="react-select"
            placeholder="Select page type"
            className={`form-control border-0 p-2 `}
            style={{ backgroundColor: "#e3dcff" }}
            options={filterWebsiteLists}
            onChange={changeWebID}
            defaultValue={defaultWebList}
            theme={(theme) => ({
              ...theme,
              spacing: {
                ...theme.spacing,
                controlHeight: 36,
              },
            })}
          />
        </div>
      </div>

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
