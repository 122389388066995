import axios from "axios";
import { BlogActions } from "./blogAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const createBlogAsync = (
  detail,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(BlogActions.createBlogStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blog/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: detail,
      });
      if (data.code === 200 && data.status) {
        dispatch(BlogActions.createBlogSuccess(data.data));
        setSubmitting(false);
        // resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(BlogActions.createBlogError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
      setSubmitting(false);
    } catch (error) {
      dispatch(BlogActions.createBlogError());
      setSubmitting(false);
      // resetForm();
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const getAllBlogAsync = (searchBy, searchText, status) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(BlogActions.getAllBlog());
      let { skip, limit } = getState().blog;
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blog/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&search=${searchText}&status=${status}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(BlogActions.getAllBlogSuccess(data.data));
      }
      dispatch(BlogActions.getAllBlogError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      console.log("Error: ", error);
      dispatch(BlogActions.getAllBlogError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const getBlogDetailAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(BlogActions.getBlogDetail());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blog/getDetail/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(BlogActions.getBlogDetailSuccess(data.data));
      }
      dispatch(BlogActions.getBlogDetailError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(BlogActions.getBlogDetailError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const updateBlogAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      //let { _id } = getState().auth.user;
      let { selectedBlog } = getState().blog;
      dispatch(BlogActions.updateBlogStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blog/update/${selectedBlog._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });

      if (data.code === 200 && data.status) {
        dispatch(BlogActions.updateBlogSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(BlogActions.updateBlogError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(BlogActions.updateBlogError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteBlogAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(BlogActions.deleteBlogStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blog/remove/${id}`,
        method: "delete",
      });
      if (data.code === 200) {
        dispatch(BlogActions.deleteBlogSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(BlogActions.deleteBlogError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      console.log("Error: ", error);
      dispatch(BlogActions.deleteBlogError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating.Please try again after sometime..",
          3000
        )
      );
    }
  };
};
