import React, { useEffect, useRef, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  getAllContactUsAsync,
  contactUsAction,
  getContactPageNameListAsync,
} from "../redux";
import ContactUsTable from "./contact_us_detail/contactUsTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Select from "react-select";
import { useParams, useHistory } from "react-router-dom";
import { useIsAccessAllow } from "../../users/redux";

export function ContactUs() {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchContactRef = useRef();
  const { pageType: currentPageType } = useParams();

  let type = currentPageType ? currentPageType : "all";

  const {
    refreshContactList,
    searchBy,
    searchText,
    pageNameList,
  } = useSelector((state) => state.contactUs, shallowEqual);
  const [pageType, setPageType] = useState(currentPageType);
  const defaultPageType = [
    {
      value: type,
      label: type,
    },
  ];
  useEffect(() => {
    dispatch(getContactPageNameListAsync());
  }, []);

  useEffect(() => {
    if (refreshContactList) {
      dispatch(getAllContactUsAsync(searchBy, searchText, type));
    }
    // eslint-disable-next-line
  }, [refreshContactList]);

  const onSearchTextChange = (e) => {
    dispatch(contactUsAction.searchTextChange(e.target.value));
  };

  const changePageType = (e) => {
    setPageType(e.value);
    if (pageType == "all") {
      history.push("/contact-us");
    } else {
      history.push("/contact-us/" + e.value);
    }
    dispatch(contactUsAction.searchTextChange(""));
  };
  const isSearchAllow = useIsAccessAllow("contact_us.list");

  return (
    <>
      <Card>
        <CardHeader title="Contact Us">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div
                    className="form-group mr-3 mb-1 p-0"
                    style={{ width: "300px", marginRight: "50px" }}
                  >
                    <Select
                      classNamePrefix="react-select"
                      placeholder="Select page type"
                      className={`form-control border-0 p-0 `}
                      options={pageNameList}
                      onChange={changePageType}
                      defaultValue={defaultPageType}
                      theme={(theme) => ({
                        ...theme,
                        spacing: {
                          ...theme.spacing,
                          controlHeight: 50,
                        },
                      })}
                    />
                  </div>
                  {isSearchAllow && (
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        autoComplete="off"
                        onChange={onSearchTextChange}
                        ref={searchContactRef}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <ContactUsTable />
      </Card>
    </>
  );
}
