import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import {
  ImageResize,
  ImageStyle,
  ImageToolbar,
} from "@ckeditor/ckeditor5-image";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import Font from "@ckeditor/ckeditor5-font/src/font";

const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Alignment,
    Paragraph,
    SourceEditing,
    Heading,
    Link,
    List,
    BlockQuote,
    Table,
    TableToolbar,
    GeneralHtmlSupport,
    Image,
    ImageUpload,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    LinkImage,
    Font,
  ],

  extraPlugins: [uploadPlugin],

  // , CloudServices, EasyImage, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload
  //toolbar: ["sourceEditing", "heading", "bold", "italic"]

  //'resizeImage:25', 'resizeImage:50', 'resizeImage:75',
  image: {
    styles: ["full", "alignLeft", "alignRight"],
    toolbar: [
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "resizeImage:25",
      "resizeImage:50",
      "resizeImage:75",
      "resizeImage:original",
      "resizeImage",
      "|",
      "imageTextAlternative",
      "linkImage",
    ],
    resizeUnit: "%",
  },
  fontFamily: {
    options: [
      "default",
      "Inter,sans-serif",
      "Arial, Helvetica, sans-serif",
      "Courier New, Courier, monospace",
      "Times New Roman, Times, serif",
      "Trebuchet MS, Helvetica, sans-serif",
    ],
  },
  toolbar: {
    items: [
      "sourceEditing",
      "|",
      "heading",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "bold",
      "italic",
      "alignment",
      "|",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "imageUpload",
      "insertTable",
      "|",
      "blockQuote",
      "undo",
      "redo",
    ],
  },

  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },

  // 'uploadImage',
};

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          body.append("files", file);
          fetch(`${process.env.REACT_APP_HOST}/api/admin/user/uploadImage`, {
            method: "post",
            body: body,
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.code === 200) {
                resolve({
                  default: res.data.image,
                });
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const CustomEditor = (props) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfiguration}
      data={props.data}
      className={props.className}
      onChange={props.onChange}
    />
  );
};
export default CustomEditor;
