import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { useIsAccessAllow } from "../../../users/redux";
import {
  contactUsAction,
  deleteCareersAsync,
  getAllContactUsAsync,
} from "../../redux";

const ContactUsTable = () => {
  const history = useHistory();
  const type = "CONTACT";
  const { isLoading, ContactList, skip, limit } = useSelector(
    (state) => state.contactUs,
    shallowEqual
  );

  const { searchBy, searchText } = useSelector(
    (state) => state.contactUs,
    shallowEqual
  );
  useEffect(() => {
    dispatch(getAllContactUsAsync(searchBy, searchText, type));
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(contactUsAction.setContactBatchNumber(count));
  };

  const onDeleteClick = (data) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteCareersAsync(data?._id));
      dispatch(getAllContactUsAsync(searchBy, searchText, type));
    }
  };

  const onViewClick = (data) => {
    history.push(`/careers/view-careers/${data?._id}`);
  };
  const isViewAllow = useIsAccessAllow("careers.view");
  const isDeteleAllow = useIsAccessAllow("careers.delete");

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Job Position Name</th>
                  <th>Your Name</th>
                  <th>Email</th>
                  <th>Contact Number</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                ContactList.records &&
                ContactList.records.length ? (
                  ContactList.records.map((ContactLists, index) => (
                    <tr key={index}>
                      <td>
                        <span className="f-w-500">
                          {skip * limit + (index + 1)}
                        </span>
                      </td>
                      <td>
                        <span>
                          <span className="f-w-500">
                            {ContactLists.jobPositionName}
                          </span>
                        </span>
                      </td>
                      <td>
                        <span className="f-w-500">{ContactLists.yourName}</span>
                      </td>
                      <td>
                        <span className="f-w-500">{ContactLists.email}</span>
                      </td>
                      <td>
                        <span className="f-w-500">
                          {ContactLists.contactNumber}
                        </span>
                      </td>
                      <td>
                        <span className="f-w-500">
                          {new Date(ContactLists.createdOn).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        {isViewAllow && (
                          <button
                            title="View"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                            onClick={() => onViewClick(ContactLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/custom/eye.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}
                        {isDeteleAllow && (
                          <button
                            title="Delete"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onDeleteClick(ContactLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={ContactList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {ContactList.recordsTotal} Records
              </div>
            </div>
          )}
          {isLoading && (
            <div>
              <div style={{ justifyContent: "center" }} className="text-center">
                <div className="basic-verification-loader text-center">
                  <CircularProgress />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUsTable;
