import React, { useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { MailSubscriberActions, getAllMailSubscribersAsync } from "../redux";
import MailSubscriberTable from "./mailSubscriberDetail/mailSubscriberTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { useIsAccessAllow } from "../../users/redux";

export function MailSubscriber() {
  const dispatch = useDispatch();
  const searchEmailRef = useRef();
  const type = "";
  const { refreshEmailList, searchBy, searchText } = useSelector(
    (state) => state.mailSubscriber,
    shallowEqual
  );

  useEffect(() => {
    if (refreshEmailList) {
      dispatch(getAllMailSubscribersAsync(searchBy, searchText, type));
    }
  }, [refreshEmailList]);

  const onSearchTextChange = (e) => {
    dispatch(MailSubscriberActions.searchTextChange(e.target.value));
  };
  const isSearchAllow = useIsAccessAllow("mail_subscribers.list");

  return (
    <>
      <Card>
        <CardHeader title="Mail Subscribers">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                {isSearchAllow && (
                  <div className="form-row">
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        onChange={onSearchTextChange}
                        ref={searchEmailRef}
                      />
                    </div>
                  </div>
                )}
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <MailSubscriberTable />
      </Card>
    </>
  );
}
