import { BlogMap } from "./blogAction";

const initialState = {
  isLoading: false,
  blogList: [],
  refreshBlog: true,
  selectedBlog: {},
  reDirect: false,
  searchBy: "",
  searchText: "",
  status: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BlogMap.CREATE_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogMap.CREATE_BLOG_SUCCESS: {
      return {
        ...state,
        refreshBlog: true,
        reDirect: true,
      };
    }
    case BlogMap.CREATE_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BlogMap.GET_ALL_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogMap.GET_ALL_BLOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshBlog: false,
        blogList: action.payload,
      };
    }
    case BlogMap.GET_ALL_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlog: false,
      };
    }
    case BlogMap.UPDATE_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogMap.UPDATE_BLOG_SUCCESS: {
      return {
        ...state,
        refreshBlog: true,
        selectedBlog: {
          ...state.selectedBlog,
          ...action.payload,
        },
        reDirect: true,
      };
    }
    case BlogMap.UPDATE_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BlogMap.DELETE_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogMap.DELETE_BLOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshBlog: true,
      };
    }
    case BlogMap.DELETE_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BlogMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case BlogMap.SEARCH_CHANGE_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case BlogMap.REFRESH_BLOG_LIST: {
      return {
        ...state,
        skip: 0,
        refreshBlog: true,
      };
    }
    case BlogMap.GET_BLOG_DETAIL_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogMap.GET_BLOG_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selectedBlog: action.payload,
        refreshBlog: false,
      };
    }
    case BlogMap.GET_BLOG_DETAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlog: false,
      };
    }
    case BlogMap.SET_BLOG_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshBlog: true,
      };
    }
    default:
      return { ...state };
  }
};
