import { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import BasicPagination from "../../pagination/BasicPagination";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../redux";
import { FiFilter } from "react-icons/fi";
import { LuFilterX } from "react-icons/lu";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getQuotePageNameListAsync } from "../../quote/redux";
import { getContactPageNameListAsync } from "../../contact_us/redux";

const PageCount = ({ QuotepageList, contactUsList }) => {
  // Quote
  const [showQuote, setShowQuote] = useState(false);
  const [toDateQuote, setToDateQuote] = useState(null);
  const [fromDateQuote, setFromDateQuote] = useState(null);
  const [fromErrQuote, setFromErrQuote] = useState(false);
  const [toErrQuote, setToErrQuote] = useState(false);
  const [quoteFilter, setQuoteFilter] = useState(false);
  const [removeQuoteFilter, setRemoveQuoteFilter] = useState(true);
  const [quoteDataLength, setQuoteDataLength] = useState();

  // Contact
  const [showContact, setShowContact] = useState(false);
  const [toDateContact, setToDateContact] = useState();
  const [fromDateContact, setFromDateContact] = useState();
  const [toErrContact, setToErrContact] = useState(false);
  const [fromErrContact, setFromErrContact] = useState(false);
  const [contactFilter, setContactFilter] = useState(false);
  const [removeContactFilter, setRemoveContactFilter] = useState(true);
  const [contactDataLength, setContactDataLength] = useState();

  const dispatch = useDispatch();

  const {
    skipQuote,
    limitQuote,
    skipContact,
    limitContact,
    refreshContactPageDataDashboard,
    refreshQuotePageDataDashboard,
  } = useSelector((state) => state.dashboard, shallowEqual);

  useEffect(() => {
    setQuoteDataLength(limitQuote * skipQuote + QuotepageList?.data?.length);
    setContactDataLength(
      limitContact * skipContact + contactUsList?.data?.length
    );
  }, [QuotepageList]);

  useEffect(() => {
    setContactDataLength(
      limitContact * skipContact + contactUsList?.data?.length
    );
  }, [contactUsList]);

  // Quote

  const { pageNameList } = useSelector(
    (state) => state.quoteData,
    shallowEqual
  );

  const onPageChangeQuote = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skipQuote;

    dispatch(dashboardActions.setDashBoardQuoteBatchNumber(count));
  };

  const onSearchTextChangeQuote = (e) => {
    if (e.value !== "all") {
      dispatch(dashboardActions.searchTextChange(e.value));
    } else {
      dispatch(dashboardActions.searchTextChange(""));
    }
  };

  const filterQuoteByDate = () => {
    dispatch(
      dashboardActions.filterDate({
        toDate: toDateQuote ? toDateQuote.getTime() : "",
        fromDate: fromDateQuote ? fromDateQuote.getTime() : "",
      })
    );
  };

  const handleFromDateChangeQuote = (date) => {
    if (!date) {
      setFromDateQuote(null);
      filterQuoteByDate();
    } else {
      setFromDateQuote(date);
    }
    setToErrQuote(false);
    setFromErrQuote(false);

    if (fromDateQuote && date <= toDateQuote) {
      setFromErrQuote(true);
    }
  };

  const handleToDateChangeQuote = async (date) => {
    if (!date) {
      setToDateQuote(null);
      filterQuoteByDate();
    } else {
      setToDateQuote(date);
    }
    setToErrQuote(false);
    setFromErrQuote(false);
  };

  const handleFilterDateQuote = () => {
    if (toDateQuote || fromDateQuote) {
      filterQuoteByDate();
    } else {
      setToErrQuote(true);
      setFromErrQuote(true);
    }
  };

  const handleQuoteFilter = () => {
    setRemoveQuoteFilter(false);
    setQuoteFilter(true);
  };
  const handleClearQuoteFilter = () => {
    setRemoveQuoteFilter(true);
    if (quoteFilter) setQuoteFilter(false);
    else setQuoteFilter(true);
    dispatch(
      dashboardActions.filterDate({
        toDate: "",
        fromDate: "",
      })
    );
  };

  useEffect(
    () => {
      if (QuotepageList?.total) dispatch(getQuotePageNameListAsync());

      if (QuotepageList?.total > 5) {
        setFromErrQuote(false);
        setShowQuote(true);
      } else {
        setShowQuote(false);
      }
    },
    useMemo(() => [refreshQuotePageDataDashboard, toDateQuote, fromDateQuote])
  );

  // Contact

  const { contactNameList } = useSelector(
    (state) => state.contactUs,
    shallowEqual
  );

  const onPageChangeContact = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skipContact;

    dispatch(dashboardActions.setDashBoardContactBatchNumber(count));
  };

  const onSearchTextChangeContact = (e) => {
    if (e.value !== "all") {
      dispatch(dashboardActions.searchTextChangeContact(e.value));
    } else {
      dispatch(dashboardActions.searchTextChangeContact(""));
    }
  };

  const filterContactByDate = () =>
    dispatch(
      dashboardActions.filterDateContact({
        toContactDate: toDateContact ? toDateContact.getTime() : "",
        fromContactDate: fromDateContact ? fromDateContact.getTime() : "",
      })
    );

  const handleFromDateChangeContact = (date) => {
    if (!date) {
      setFromDateContact(date);
      filterContactByDate();
    }

    setToErrContact(false);

    setFromErrContact(false);

    if (fromDateContact && date <= toDateContact) {
      setFromErrContact(true);
    }

    setFromDateContact(date);
  };

  const handleToDateChangeContact = (date) => {
    if (!date) {
      setToDateContact(date);
      filterContactByDate();
    }
    setToErrContact(false);
    setFromErrContact(false);
    setToDateContact(date);
  };

  const handleFilterDateContact = () => {
    if (toDateContact || fromDateContact) {
      filterContactByDate();
    } else {
      setToErrContact(true);
      setFromErrContact(true);
    }
  };

  const handleContactFilter = () => {
    setRemoveContactFilter(false);
    setContactFilter(true);
  };

  const handleClearContactFilter = () => {
    setRemoveContactFilter(true);
    if (contactFilter) setContactFilter(false);
    else setContactFilter(true);
    dispatch(
      dashboardActions.filterDateContact({
        toContactDate: "",
        fromContactDate: "",
      })
    );
  };

  useEffect(
    () => {
      if (contactUsList?.total) dispatch(getContactPageNameListAsync());

      if (contactUsList?.total > 5) {
        setFromErrContact(false);
        setShowContact(true);
      } else {
        setShowContact(false);
      }
    },
    useMemo(() => [refreshContactPageDataDashboard])
  );
  return (
    <div
      className={`${!contactUsList ? "dashboardDiv" : ""}  dashboardTableDiv`}
    >
      {QuotepageList ? (
        <>
          <h2 className="p-3">Quote Page</h2>
          <div className="form-group ml-3 mb-2">
            <div className="page_type">
              <Select
                className="select-wrap"
                classNamePrefix="react-select"
                placeholder="Select page type"
                // className={`form-control border-0 p-0 `}
                options={pageNameList}
                onChange={onSearchTextChangeQuote}
              />
              {removeQuoteFilter ? (
                <FiFilter
                  title="Advance Filter"
                  className="ml-2"
                  size="2em"
                  type="button"
                  onClick={handleQuoteFilter}
                />
              ) : (
                <LuFilterX
                  title="Clear Filter"
                  className="ml-2"
                  size="2em"
                  type="button"
                  onClick={handleClearQuoteFilter}
                />
              )}
            </div>
            {quoteFilter && (
              <div className="date-picker-container pt-5">
                <DatePicker
                  placeholderText="Select to date"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={toDateQuote}
                  onChange={handleToDateChangeQuote}
                  name="toDate"
                  className={
                    toErrQuote
                      ? "form-control re_inputRouded is-invalid date-picker ml-10"
                      : "form-control re_inputRouded date-picker ml-0"
                  }
                />
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select from date"
                  autoComplete="off"
                  selected={fromDateQuote}
                  onChange={handleFromDateChangeQuote}
                  name="fromDate"
                  className={
                    fromErrQuote
                      ? "form-control re_inputRouded is-invalid date-picker ml-10"
                      : "form-control re_inputRouded date-picker"
                  }
                />
                <button
                  type="submit"
                  onClick={handleFilterDateQuote}
                  className="search-button"
                >
                  Search
                </button>
              </div>
            )}
          </div>
          <Table hover responsive className="">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Page Name</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {QuotepageList?.data?.length > 0 ? (
                QuotepageList?.data?.map((QuotePage, index) => (
                  <tr key={index}>
                    <td>{skipQuote * limitQuote + (index + 1)}</td>
                    <td>{QuotePage._id}</td>
                    <td>
                      <span className="f-w-500">{QuotePage.count}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {showQuote && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={QuotepageList?.total} //
                  limit={limitQuote}
                  batch={skipQuote + 1}
                  onBatchChange={onPageChangeQuote}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skipQuote * limitQuote + 1} - {quoteDataLength} of{" "}
                {QuotepageList?.total} Records
              </div>
            </div>
          )}
        </>
      ) : contactUsList ? (
        <>
          <h2 className="p-3">Contact-Us Page</h2>
          <div className="form-group ml-3 mb-2">
            <div className="page_type">
              <Select
                className="select-wrap"
                classNamePrefix="react-select"
                placeholder="Select page type"
                options={contactNameList}
                onChange={onSearchTextChangeContact}
              />
              {removeContactFilter ? (
                <FiFilter
                  title="Advance Filter"
                  className="ml-2"
                  size="2em"
                  type="button"
                  onClick={handleContactFilter}
                />
              ) : (
                <LuFilterX
                  title="Clear Filter"
                  className="ml-2"
                  size="2em"
                  type="button"
                  onClick={handleClearContactFilter}
                />
              )}
            </div>
            {contactFilter && (
              <div className="date-picker-container pt-5 ">
                <DatePicker
                  placeholderText="Select to date"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={toDateContact}
                  onChange={handleToDateChangeContact}
                  name="createdDate"
                  className={
                    toErrContact
                      ? "form-control re_inputRouded is-invalid date-picker ml-10"
                      : "form-control re_inputRouded date-picker ml-0"
                  }
                />
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select from date"
                  autoComplete="off"
                  selected={fromDateContact}
                  onChange={handleFromDateChangeContact}
                  name="createdDate"
                  className={
                    fromErrContact
                      ? "form-control re_inputRouded is-invalid date-picker ml-10"
                      : "form-control re_inputRouded date-picker"
                  }
                />
                <button
                  type="submit"
                  onClick={handleFilterDateContact}
                  className="search-button"
                >
                  Search
                </button>
              </div>
            )}
          </div>
          <Table hover responsive className="">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Page Name</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {contactUsList?.data?.length > 0 ? (
                contactUsList?.data?.map((ContactPage, index) => (
                  <tr key={index}>
                    <td>{skipContact * limitContact + (index + 1)}</td>
                    <td>{ContactPage._id}</td>
                    <td>
                      <span className="f-w-500">{ContactPage.count}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {showContact && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={contactUsList?.total} //
                  limit={limitContact}
                  batch={skipContact + 1}
                  onBatchChange={onPageChangeContact}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skipContact * limitContact + 1} -{" "}
                {contactDataLength} of {contactUsList?.total} Records
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PageCount;
