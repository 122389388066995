import { CategoryMap } from "./categoryAction";

const initialState = {
  isLoading: false,
  categoryList: [],
  categories: [],
  refreshCategory: true,
  selectedCategory: {},
  reDirect: false,
  searchBy: "",
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CategoryMap.CREATE_CATEGORY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CategoryMap.CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        refreshCategory: true,
        reDirect: true,
      };
    }
    case CategoryMap.CREATE_CATEGORY_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CategoryMap.GET_ALL_CATEGORY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CategoryMap.GET_ALL_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshCategory: false,
        categoryList: action.payload,
      };
    }
    case CategoryMap.GET_ALL_CATEGORY_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCategory: false,
      };
    }
    case CategoryMap.UPDATE_CATEGORY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CategoryMap.UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        refreshCategory: true,
        selectedCategory: {
          ...state.selectedCategory,
          ...action.payload,
        },
        reDirect: true,
      };
    }
    case CategoryMap.UPDATE_CATEGORY_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CategoryMap.DELETE_CATEGORY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CategoryMap.DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshCategory: true,
      };
    }
    case CategoryMap.DELETE_CATEGORY_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CategoryMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case CategoryMap.REFRESH_CATEGORY_LIST: {
      return {
        ...state,
        skip: 0,
        refreshCategory: true,
      };
    }
    case CategoryMap.GET_CATEGORY_DETAIL_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CategoryMap.GET_CATEGORY_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selectedCategory: action.payload,
        refreshCategory: false,
      };
    }
    case CategoryMap.GET_CATEGORY_LIST_START: {
      return {
        ...state,
      };
    }
    case CategoryMap.GET_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case CategoryMap.GET_CATEGORY_LIST_ERROR: {
      return {
        ...state,
      };
    }

    case CategoryMap.GET_CATEGORY_DETAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCategory: false,
      };
    }
    case CategoryMap.SET_CATEGORY_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshCategory: true,
      };
    }
    default:
      return { ...state };
  }
};
