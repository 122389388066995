import { quoteMap } from "./quoteAction";

const initialState = {
  isLoading: false,
  ContactList: {},
  refreshContactList: true,
  searchBy: "",
  countries: [],
  searchText: "",
  pageNameList: [],
  skip: 0,
  limit: 10,
  selectedContact: {}
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case quoteMap.GET_ALL_QUOTE_START: {
      return {
        ...state,
        isLoading: true,

      };
    }
    case quoteMap.GET_ALL_QUOTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ContactList: action.payload,
        refreshContactList: false,
      };
    }
    case quoteMap.GET_ALL_QUOTE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }

    case quoteMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
        refreshContactList: true
      };
    }
    case quoteMap.SET_QUOTE_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshContactList: true,
      };
    }
    case quoteMap.GET_QUOTE_START: {
      return {
        ...state,
        isLoading: true,

      };
    }
    case quoteMap.GET_QUOTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ContactList: action.payload,
        refreshContactList: false,
      };
    }
    case quoteMap.GET_QUOTE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }
    case quoteMap.GET_QUOTE_PAGE_NAME_LIST_SUCCESS: {
      return {
        ...state,
        pageNameList: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
