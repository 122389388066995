import { WhatsAppAccountMap } from "./whatsAppAccountAction";

const initialState = {
  isLoading: false,
  accountList: {},
  refreshList: true,
  selectedAccount: null,
  searchBy: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WhatsAppAccountMap.GET_ALL_WA_ACCOUNTS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WhatsAppAccountMap.GET_ALL_WA_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        accountList: action.payload,
        refreshList: false,
      };
    }
    case WhatsAppAccountMap.GET_ALL_WA_ACCOUNTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshList: false,
      };
    }
    case WhatsAppAccountMap.SELECT_WA_ACCOUNTS_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedAccount: action.payload,
      };
    }
    case WhatsAppAccountMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
        refreshList: true,
      };
    }
    case WhatsAppAccountMap.SET_WA_ACCOUNTS_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshList: true,
      };
    }
    case WhatsAppAccountMap.REFRESH_WA_ACCOUNTS_LIST: {
      return {
        ...state,
        skip: 0,
        refreshList: true,
      };
    }
    default:
      return { ...state };
  }
};
