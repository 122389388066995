import React, { useEffect, useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import PageList from "./pageList.json";
import { getPageSpeedScore } from "./redux/pageSpeedScoreApi";

export function PageSpeedScore() {
  const dispatch = useDispatch();

  const [urlPage, setUrl] = useState();
  const [error, setError] = useState(false);
  const [scoreData, setScore] = useState(false);
  const { pageSpeedScore } = useSelector(
    (score) => score.pageSpeedScore,
    shallowEqual
  );
  const clickButton = async () => {
    if (urlPage == undefined) {
      setError(true);
    } else {
      setError(false);
      await dispatch(getPageSpeedScore(urlPage));
      setScore(true);
    }
  };
  return (
    <>
      <Card>
        <CardHeader
          title={
            <div className="form-group m-0 d-flex align-items-center">
              <span className="pl-2">Get Page Speed Score</span>
            </div>
          }
        ></CardHeader>
        <CardBody>
          <form className="" onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-6">
                <input
                  type="text"
                  name="url"
                  list="urlList"
                  autoComplete="off"
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Select page"
                />
                <datalist id="urlList">
                  {PageList?.map((page, index) => (
                    <option key={index} value={page.url}>
                      {page.label}
                    </option>
                  ))}
                </datalist>
                {error ? (
                  <span className="color_red">Please select page </span>
                ) : null}
              </div>
              <div className="col-md-4">
                <button
                  type="submit"
                  className="px-5 defpddng spinnerBtn btn btn-blue btn-lg"
                  onClick={clickButton}
                >
                  Get Score
                </button>
              </div>
            </div>
          </form>
          {scoreData ? (
            <div className="d-flex justify-content-center">
              <span
                className={`${
                  scoreData == true && pageSpeedScore.score < 50
                    ? "bg-danger"
                    : scoreData == true &&
                      pageSpeedScore.score >= 50 &&
                      pageSpeedScore.score < 80
                    ? "bg-warning"
                    : "bg-success"
                } p-12  m-12 rounded border display-2 text-inverse-dark`}
              >
                {pageSpeedScore.score}
              </span>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
}
