import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table } from "react-bootstrap";
import { TagActions } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { getAllTagAsync, deleteTagAsync } from "../../redux";
import { useIsAccessAllow } from "../../../users/redux";

const TagTable = () => {
  const history = useHistory();
  const { isLoading, tagList, skip, limit, searchBy, searchText } = useSelector(
    (state) => state.tag,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllTagAsync(searchBy, searchText));
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(TagActions.setTagBatchNumber(count));
  };

  const onEditClick = (data) => {
    history.push(`/tag/edit-tag/${data?._id}`);
  };
  const onDeleteClick = (data) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteTagAsync(data?._id));
      dispatch(getAllTagAsync(searchBy, searchText));
    }
  };
  const isEditTagAllow = useIsAccessAllow("tag.edit_tag");
  const isDeleteAllow = useIsAccessAllow("tag.delete_tag");

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Slug</th>
                  <th>Title</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tagList.records && tagList.records.length ? (
                  tagList.records.map((tagLists, index) => (
                    <tr key={index}>
                      <td>
                        <span>{skip * limit + (index + 1)}</span>
                      </td>
                      <td>
                        <span>{tagLists.slug}</span>
                      </td>
                      <td>
                        <span>
                          <span>{tagLists.title}</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {new Date(tagLists.createdOn).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        {isEditTagAllow && (
                          <button
                            title="Edit"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                            onClick={() => onEditClick(tagLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}

                        {isDeleteAllow && (
                          <button
                            title="Delete"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onDeleteClick(tagLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={tagList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {tagList.recordsTotal} Records
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagTable;
