import { CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { categoryListAsync } from "../../Category/redux/categoryApi";
import CustomEditor from "../../CustomEditor/CustomEditor";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { tagListAsync } from "../../tag/redux/tagApi";
import {
  createBlogAsync,
  getBlogDetailAsync,
  updateBlogAsync,
} from "../redux/blogApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { blogStatus } from "../constants/constants";
import { authorListAsync } from "../../AuthorManagement/redux/authorManagementApi";

const statusOptionList = [
  { status: "Draft", value: blogStatus.DRAFT },
  { status: "Published", value: blogStatus.PUBLISHED },
  { status: "Pending Review", value: blogStatus.PENDING_REVIEW },
  { status: "Future", value: blogStatus.FUTURE },
  { status: "Auto Draft", value: blogStatus.AUTO_DRAFT },
];

const AddBlog = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedBlog } = useSelector((state) => state.blog, shallowEqual);
  const { tags } = useSelector((state) => state.tag, shallowEqual);
  const { categories } = useSelector((state) => state.category, shallowEqual);
  const { authors } = useSelector((state) => state.author, shallowEqual);
  const id = params.id;
  const [file, setFile] = useState({
    file: null,
    url: "",
  });
  const [bannerImage, setBannerImage] = useState({
    file: null,
    url: "",
  });
  const [defaultTag, setDefaultTag] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [defaultAuthor, setDefaultAuthor] = useState();
  const [createdDate, setCreatedDate] = useState(new Date());
  const [statusOption, setStatusOption] = useState("draft");

  useEffect(() => {
    dispatch(tagListAsync());
    dispatch(categoryListAsync());
    dispatch(authorListAsync());
    if (id) {
      dispatch(getBlogDetailAsync(id));
    }
  }, []);

  useEffect(() => {
    if (
      selectedBlog &&
      selectedBlog.image &&
      selectedBlog.image !== "undefined" &&
      params?.id !== undefined
    ) {
      setFile({ file: null, url: selectedBlog?.image });
      setBannerImage({ file: null, url: selectedBlog?.bannerImage });
    }
    if (tags.length && selectedBlog?.tag && params?.id !== undefined) {
      let defTagArr = [];
      let tag = [];
      tags.filter((ele) => {
        if (selectedBlog.tag.includes(ele.value)) {
          defTagArr.push({ value: ele.value, label: ele.label });
          tag.push(ele.value);
        }
      });
      setDefaultTag(defTagArr);
    }
    if (
      categories.length &&
      selectedBlog?.category &&
      params?.id !== undefined
    ) {
      let defCategoryArr = [];
      let category = [];
      categories.filter((ele) => {
        if (selectedBlog.category.includes(ele.value)) {
          defCategoryArr.push({ value: ele.value, label: ele.label });
          category.push(ele.value);
        }
      });
      setDefaultCategory(defCategoryArr);
    }
    if (authors && selectedBlog?.author && params?.id !== undefined) {
      let defAuthorArr = [];
      let author = [];
      authors.filter((ele) => {
        if (selectedBlog.author.includes(ele.value)) {
          defAuthorArr.push({ value: ele.value, label: ele.label });
          author.push(ele.value);
        }
      });
      setDefaultAuthor(defAuthorArr);
    }
    if (selectedBlog && selectedBlog.createdOn) {
      setCreatedDate(new Date(selectedBlog.createdOn));
    }
    if (selectedBlog && selectedBlog.status) {
      setStatusOption(selectedBlog.status);
    }
  }, [tags, categories, authors, selectedBlog]);

  const initialValues = {
    slug: "",
    title: "",
    description: "",
    tag: [],
    category: "",
    author: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    createdDate: "",
    status: "",
    blogCTATitel: "",
    blogCTADescription: "",
    blogCTAButton: "",
  };
  const redirectBack = () => {
    history.push("/blog");
  };

  const uploadImage = (e) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const uploadBannerImage = (e) => {
    setBannerImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const addSchema = Yup.object().shape({
    slug: Yup.string()
      .trim()
      .required("Please enter blog slug"),
    title: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter blog Title"
      )
      .required("Please enter blog title"),

    description: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter blog description"
      )
      .required("Please enter blog description"),
    // blogCTATitel: Yup.string()
    //   .trim()
    //   .notOneOf(
    //     ["null", "NULL", "Null", "Undefined", "undefined"],
    //     "Please enter blog BLOG: CTA Title"
    //   )
    //   .required("Please enter blog BLOG: CTA Title"),
    // blogCTADescription: Yup.string()
    //   .trim()
    //   .notOneOf(
    //     ["null", "NULL", "Null", "Undefined", "undefined"],
    //     "Please enter blog BLOG: CTA Description"
    //   )
    //   .required("Please enter BLOG: CTA Description"),
    // blogCTAButton: Yup.string()
    //   .trim()
    //   .notOneOf(
    //     ["null", "NULL", "Null", "Undefined", "undefined"],
    //     "Please enter blog BLOG: CTA Button Name"
    //   )
    //   .required("Please enter BLOG: CTA Button Name"),

    tag: Yup.array(),
    status: Yup.string(),
    category: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please select category"
      ),
    author: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter blog author name"
      )
      .required("Please enter blog author name"),
  });

  const changeTag = (e) => {
    let selectedTag = e;
    let tagArr = [];
    if (selectedTag) {
      selectedTag.map((ele) => {
        tagArr.push(ele.value);
      });
    }
    setDefaultTag(e);
    return tagArr;
  };

  const changeCategory = (e) => {
    let selectedCategory = e;
    let categoryArr = [];
    if (selectedCategory) {
      selectedCategory.map((ele) => {
        categoryArr.push(ele.value);
      });
    }
    setDefaultCategory(e);
    return categoryArr;
  };
  const changeAuthor = (e) => {
    let selectedAuthor = e;
    let authorValue = null;
    if (selectedAuthor && selectedAuthor.value) {
      authorValue = selectedAuthor.value;
    }
    setDefaultAuthor(e);
    return authorValue;
  };
  const convertToSlug = (str) => {
    return str
      .toLowerCase()
      .trim()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/-+/g, "-");
  };
  const correctDescription = (description) => {
    if (description != undefined) {
      return description.replaceAll('""', '"');
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          selectedBlog && params?.id !== undefined
            ? selectedBlog
            : initialValues
        }
        validationSchema={addSchema}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          let data = null;
          data = new FormData();
          if (file && file.file) {
            data.append("image", file.file);
          } else if (values && values.image) {
            data.append("image", values.image);
          } else {
            return dispatch(
              showSuccessSnackbar("error", "Please select image", 3000)
            );
          }

          if (bannerImage && bannerImage.file) {
            data.append("bannerImage", bannerImage.file);
          } else if (values && values.bannerImage) {
            data.append("bannerImage", values.bannerImage);
          } else {
            return dispatch(
              showSuccessSnackbar("error", "Please select bannerimage", 3000)
            );
          }

          data.append("slug", values.slug);
          data.append("title", values.title);
          data.append("description", values.description);
          data.append("tag", values.tag);
          data.append("category", values.category);
          data.append("author", values.author);
          data.append("blogCTATitel", values.blogCTATitel);
          data.append("blogCTADescription", values.blogCTADescription);
          data.append("blogCTAButton", values.blogCTAButton);
          data.append("metaTitle", values.metaTitle);
          data.append("metaDescription", values.metaDescription);
          data.append("metaKeywords", values.metaKeywords);
          data.append("createdOn", createdDate.getTime());
          data.append("status", statusOption ? statusOption : "draft");
          setSubmitting(true);
          if (params?.id !== undefined) {
            dispatch(
              updateBlogAsync(data, setSubmitting, resetForm, redirectBack)
            );
            setDefaultTag([]);
            setDefaultCategory([]);
          } else {
            dispatch(
              createBlogAsync(data, setSubmitting, resetForm, redirectBack)
            );
            setDefaultTag([]);
            setDefaultCategory([]);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <Row>
              <Col md={8}>
                <Card>
                  <CardHeader
                    title={
                      <div className="form-group m-0 d-flex align-items-center">
                        <Link className="btn btn-link px-0" to="/blog">
                          <SVG
                            className="h-50 align-self-center"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Arrow-left.svg"
                            )}
                          />
                          Back
                        </Link>
                        <span className="pl-2">
                          {params?.id ? "Edit Blog" : "Add Blog"}
                        </span>
                      </div>
                    }
                  ></CardHeader>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col lg={12}>
                        <div className="form-group">
                          <label className="form-label">Slug</label>
                          <input
                            placeholder="Enter Slug"
                            type="text"
                            name="slug"
                            className={
                              errors.slug && touched.slug
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={handleChange}
                            onBlur={(event) => {
                              let slug = event.target.value;
                              if (slug) {
                                slug = convertToSlug(slug);
                              }
                              setFieldValue("slug", slug);
                            }}
                            value={values.slug ? values.slug : ""}
                          />
                          {touched.slug && errors.slug ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.slug}</div>
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <label className="form-label">Title</label>
                          <input
                            placeholder="Enter Title"
                            type="text"
                            name="title"
                            className={
                              errors.title && touched.title
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={handleChange}
                            onBlur={(event) => {
                              let title = event.target.value;
                              setFieldValue("title", title);

                              if (!values.slug) {
                                setFieldValue("slug", convertToSlug(title));
                              }
                            }}
                            value={values.title ? values.title : ""}
                          />
                          {touched.title && errors.title ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.title}
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <CustomEditor
                            data={values.description}
                            className={
                              errors.description && touched.description
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldTouched("description", true);
                              setFieldValue("description", data);
                            }}
                          />
                          {touched.description && errors.description ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.description}
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <div className="col-lg-14">
                          <div className="form-group">
                            <label className="form-label">Tag</label>
                            <Select
                              classNamePrefix="react-select"
                              name="tag"
                              isMulti
                              placeholder="Select Tag"
                              className={`form-control border-0 p-0 `}
                              options={tags}
                              onChange={(e) => {
                                let data = changeTag(e);
                                setFieldTouched(
                                  "tag",
                                  data?.length ? true : false
                                );
                                setFieldValue("tag", data);
                              }}
                              value={defaultTag}
                            />
                            {touched.tag && errors.tag ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.tag}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-14">
                          <div className="form-group">
                            <label className="form-label">Category</label>
                            <Select
                              classNamePrefix="react-select"
                              name="category"
                              isMulti
                              placeholder="Select Category"
                              className={`form-control border-0 p-0 `}
                              options={categories}
                              // onChange={(e) => { setFieldValue("category", e.value) }}
                              // value={categories.filter(({ value }) => value === values.category)}
                              onChange={(e) => {
                                let data = changeCategory(e);
                                setFieldTouched(
                                  "category",
                                  data?.length ? true : false
                                );
                                setFieldValue("category", data);
                              }}
                              value={defaultCategory}
                            />
                            {touched.category && errors.category ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.category}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label">BLOG: CTA Title</label>
                          <input
                            placeholder="BLOG: CTA Title"
                            type="text"
                            name="blogCTATitel"
                            className={
                              errors.blogCTATitel && touched.blogCTATitel
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.blogCTATitel ? values.blogCTATitel : ""
                            }
                          />
                          {touched.blogCTATitel && errors.blogCTATitel ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.blogCTATitel}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            BLOG: CTA Description
                          </label>
                          <textarea
                            placeholder="BLOG: CTA Description"
                            type="text"
                            name="blogCTADescription"
                            className={
                              errors.blogCTADescription &&
                              touched.blogCTADescription
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows="7"
                            value={
                              values.blogCTADescription
                                ? values.blogCTADescription
                                : ""
                            }
                          />
                          {touched.blogCTADescription &&
                          errors.blogCTADescription ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.blogCTADescription}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label className="form-label">BLOG: CTA Button</label>
                          <input
                            placeholder="BLOG: CTA Button"
                            type="text"
                            name="blogCTAButton"
                            className={
                              errors.blogCTAButton && touched.blogCTAButton
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.blogCTAButton ? values.blogCTAButton : ""
                            }
                          />
                          {touched.blogCTAButton && errors.blogCTAButton ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.blogCTAButton}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <CardHeader
                    title={
                      <div className="form-group m-0 d-flex align-items-center">
                        <span className="pl-2">Blog Attribute</span>
                      </div>
                    }
                  ></CardHeader>
                  <CardBody>
                    <div className="col-md-12 text-center mb-4">
                      <label className="form-label">Featured Image</label>
                      <div
                        className="pro_img"
                        style={{
                          backgroundImage: `url(${file.url})`,
                          height: 120,
                          width: "100%",
                          borderRadius: 10,
                          backgroundSize: "contain",
                          border: "1px solid #cccccc",
                        }}
                      >
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={uploadImage}
                        />
                        <div
                          className="edt_img_btn"
                          style={{ fontSize: 14, paddingTop: 60 }}
                        >
                          <span className="material-icons">edit</span>EDIT
                        </div>
                      </div>
                      <br></br>
                    </div>

                    <div className="col-md-12 text-center mb-4">
                      <label className="form-label">Banner Image</label>
                      <div
                        className="pro_img"
                        style={{
                          backgroundImage: `url(${bannerImage.url})`,
                          height: 120,
                          width: "100%",
                          borderRadius: 10,
                          backgroundSize: "contain",
                          border: "1px solid #cccccc",
                        }}
                      >
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={uploadBannerImage}
                        />
                        <div
                          className="edt_img_btn"
                          style={{ fontSize: 14, paddingTop: 60 }}
                        >
                          <span className="material-icons">edit</span>EDIT
                        </div>
                      </div>
                      <br></br>
                    </div>

                    <div className="form-group">
                      <label className="form-label">SEO : Meta Title</label>
                      <input
                        placeholder="Enter meta tile"
                        type="text"
                        name="metaTitle"
                        className={
                          errors.metaTitle && touched.metaTitle
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.metaTitle ? values.metaTitle : ""}
                      />
                      {touched.metaTitle && errors.metaTitle ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.metaTitle}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        SEO : Meta Description
                      </label>
                      <textarea
                        placeholder="Enter meta Description"
                        type="text"
                        name="metaDescription"
                        className={
                          errors.metaDescription && touched.metaDescription
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows="7"
                        value={
                          values.metaDescription ? values.metaDescription : ""
                        }
                      />
                      {touched.metaDescription && errors.metaDescription ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.metaDescription}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">SEO : Meta Keywords</label>
                      <input
                        placeholder="Enter meta keywords"
                        type="text"
                        name="metaKeywords"
                        className={
                          errors.metaKeywords && touched.metaKeywords
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.metaKeywords ? values.metaKeywords : ""}
                      />
                      {touched.metaKeywords && errors.metaKeywords ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.metaKeywords}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="form-group">
                      <label className="form-label">Author</label>
                      <input
                        placeholder="Enter author name"
                        type="text"
                        name="author"
                        className={
                          errors.author && touched.author
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.author ? values.author : ""}
                      />
                      {touched.author && errors.author ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.author}</div>
                        </div>
                      ) : null}
                    </div> */}
                    <div className="form-group">
                      <label className="form-label">Author Name</label>
                      <Select
                        classNamePrefix="react-select"
                        name="author"
                        placeholder="Select Author"
                        className={`form-control border-0 p-0`}
                        options={authors}
                        onChange={(e) => {
                          let data = changeAuthor(e);
                          setFieldTouched(
                            "author",
                            data?.length ? true : false
                          );
                          setFieldValue("author", data);
                        }}
                        value={defaultAuthor}
                      />
                      {touched.author && errors.author ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.author}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Created Date</label>
                      <div>
                        <DatePicker
                          showTimeSelect
                          dateFormat="dd/MM/yyyy h:mm aa"
                          selected={createdDate}
                          onChange={(date) => setCreatedDate(date)}
                          name="createdDate"
                          className={
                            errors.createdDate && touched.createdDate
                              ? "form-control re_inputRouded is-invalid"
                              : "form-control re_inputRouded"
                          }
                        />

                        {touched.createdDate && errors.createdDate ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.createdDate}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-14">
                      <div className="form-group">
                        <label className="form-label">Status</label>
                        <select
                          className={
                            errors.author && touched.author
                              ? "form-control re_inputRouded is-invalid"
                              : "form-control re_inputRouded"
                          }
                          name="status"
                          placeholder="Select Status"
                          onChange={(e) => {
                            setStatusOption(e.target.value);
                          }}
                          value={statusOption}
                        >
                          {statusOptionList.map((listStatus) => (
                            <option
                              value={listStatus.value}
                              key={"status_" + listStatus.value}
                            >
                              {listStatus.status}
                            </option>
                          ))}
                        </select>
                        {touched.status && errors.status ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.status}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link className="btn btn-outline-blue" to="/blog">
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button
                          variant="blue"
                          className="px-5 defpddng spinnerBtn"
                          size="lg"
                          type="submit"
                        >
                          {selectedBlog && params?.id !== undefined
                            ? "Update"
                            : "Submit"}
                          {isSubmitting && (
                            <div className="ml-3 basic-verification-loader text-center">
                              <CircularProgress />
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddBlog;
