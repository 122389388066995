import axios from "axios";
import { userActions } from "./userAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { AuthActions } from "../../Auth/redux/authAction";
import { useSelector } from "react-redux";

export const useIsAccessAllow = (permission) => {
  const { user } = useSelector((state) => state.auth);
  if (user.roleId == 1) {
    return true;
  }
  const { permissions } = user;
  return permissions.includes(permission);
};

const getUserURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getUserAsync = (searchText) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      let { subUserSkip, subUserLimit } = getState().user;
      dispatch(userActions.getSubUserStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/getAll?skip=${subUserSkip}&limit=${subUserLimit}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(userActions.getSubUserSuccess(data.data));
      }
      return dispatch(userActions.getSubUserError());
    } catch (error) {
      dispatch(userActions.getSubUserError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateUser = (values, SubUserId, redirectBack) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      dispatch(userActions.updateSubUserStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/updateProfile/${SubUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        redirectBack();
        dispatch(userActions.updateSubUserSuccess(data.data));
        dispatch(showSuccessSnackbar("success", data?.message, 3000));
        return data;
      }
      return dispatch(userActions.updateSubUserError());
    } catch (error) {
      dispatch(userActions.updateSubUserError());
      dispatch(showSuccessSnackbar("error", "Error while updating data", 3000));
    }
  };
};

export const deleteUserAsync = (SubUserId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      dispatch(userActions.deleteSubUserStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/deleteUser/${SubUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(userActions.deleteSubUserSuccess(data.data));
        dispatch(showSuccessSnackbar("success", data?.message, 3000));
        return data;
      }
      return dispatch(userActions.deleteSubUserError());
    } catch (error) {
      dispatch(userActions.deleteSubUserError());
      dispatch(
        showSuccessSnackbar("error", "Error while deleting SubUser", 3000)
      );
    }
  };
};

export const userAsync = (SubUser, redirectBack) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      dispatch(userActions.addSubUserStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/addSubUser`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: SubUser,
      });
      if (data?.code === 200) {
        redirectBack();
        dispatch(userActions.addSubUserSuccess(data?.data));
        dispatch(showSuccessSnackbar("success", data?.message, 3000));
        return data;
      } else {
        dispatch(userActions.addSubUserError());
        dispatch(showSuccessSnackbar("error", data?.message, 3000));
        return data;
      }
    } catch (error) {
      dispatch(userActions.addSubUserError());
      return dispatch(
        showSuccessSnackbar("error", "Please try again later.", 3000)
      );
    }
  };
};

export const getRolePermissionAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      dispatch(userActions.getRolePermissionStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/role/getAllPermission`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(userActions.getRolePermissionSuccess(data.data));
      } else {
        return dispatch(userActions.getRolePermissionError());
      }
    } catch (error) {
      dispatch(userActions.getRolePermissionError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getuserDetailAsync = (SubUserId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/getProfile/${SubUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(userActions.getSubUserDetailSuccess(data.data));
        dispatch(showSuccessSnackbar("success", data?.message, 3000));
        return data;
      }
      return dispatch(userActions.getSubUserDetailError());
    } catch (error) {
      dispatch(userActions.getSubUserDetailError());
      dispatch(showSuccessSnackbar("error", "Error while get data", 3000));
    }
  };
};

export const refreshLoginToken = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getUserURL(getState());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/admin/user/refreshLoginToken`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(AuthActions.loginSuccess(data.data));
        return data;
      }
      return dispatch(userActions.getSubUserDetailError());
    } catch (error) {
      dispatch(userActions.getSubUserDetailError());
      dispatch(showSuccessSnackbar("error", "Error while get data", 3000));
    }
  };
};
