import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { ProfileSettings } from "./modules/profile_settings";
import { ContactUs } from "./modules/contact_us/pages/contactUs";
import { QuoteData } from "./modules/quote/pages/quote";
import { GenerativeAIData } from "./modules/generativeAI/pages/generativaAI";
import { CareersData } from "./modules/careers/pages/careers";
import ViewContactUs from "./modules/contact_us/pages/viewContactUs";
import ViewQuote from "./modules/quote/pages/viewQuote";
import ViewGenerativeAI from "./modules/generativeAI/pages/viewgenerativaAI";
import ViewCareers from "./modules/careers/pages/viewCareers";
import { Tag } from "./modules/tag/pages/tag";
import addTag from "./modules/tag/pages/addTag";
import DubaiAddTag from "./modules/Dubai_tag/pages/dubaiaddTag";
import { Blog } from "./modules/blog/pages/blog";
import { DubaiBlog } from "./modules/dubai_blog/pages/dubaiblog";
import dubaiaddBlog from "./modules/dubai_blog/pages/dubaiaddBlog";
import { DubaiTag } from "./modules/Dubai_tag/pages/dubaitag";

import addBlog from "./modules/blog/pages/addBlog";
import { Category } from "./modules/Category/pages/category";
import { DubaiCategory } from "./modules/Dubai_Category/pages/dubaicategory";
import addCategory from "./modules/Category/pages/addCategory";
import DubaiAddCategory from "./modules/Dubai_Category/pages/addCategory";

import { EmailManagement } from "./modules/emailManagement/pages/emailManagement";
import AddTemplate from "./modules/emailManagement/pages/AddTemplate";
import { MailSubscriber } from "./modules/mailSubscriber";
import { WhatsAppAccount } from "./modules/whatsApp";
import UpdateSubUser from "./modules/users/pages/createUser";
import { Users } from "./modules/users";
import { useIsAccessAllow } from "./modules/users/redux";
import { PageSpeedScore } from "./modules/pageSpeedScore/PageSpeed";
import { AuthorManagement } from "./modules/AuthorManagement/pages/AuthorManagement";
import AddAuthor from "./modules/AuthorManagement/pages/AddAuthor";
import { DubaiAuthorManagement } from "./modules/Dubai_AuthorManagement/pages/DubaiAuthorManagement";
import DubaiAddAuthor from "./modules/Dubai_AuthorManagement/pages/DubaiAddAuthor";
import { HireAIBlog } from "./modules/Hire_ai_blog";
import AddHireAIBlog from "./modules/Hire_ai_blog/pages/hireaiaddBlog";
import { HireAITag } from "./modules/Hire_ai_tag";
import HireAITagAdd from "./modules/Hire_ai_tag/pages/hireaiaddTag";
import { HireAICategory } from "./modules/hire_ai_Category";
import HireAIAddCategory from "./modules/hire_ai_Category/pages/addCategory";
import { HireAIAuthor, hireAIAuthor } from "./modules/hire_ai_author";
import HireAIAddAuthor from "./modules/hire_ai_author/pages/HireAIAddAuthor";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/profile-settings" component={ProfileSettings} />

        <ContentRoute
          exact
          path="/user"
          component={Users}
          title="Users"
          isAccessAllow={useIsAccessAllow("user.list")}
        />
        <ContentRoute
          exact
          path="/user/add-user"
          component={UpdateSubUser}
          title="Add User"
          isAccessAllow={useIsAccessAllow("user.add_user")}
        />
        <ContentRoute
          exact
          path="/user/edit-user/:id"
          component={UpdateSubUser}
          title="Update User"
          isAccessAllow={useIsAccessAllow("user.edit_user")}
        />

        {/* <ContentRoute
          exact
          path="/role"
          component={UpdateSubUser}
          title="Role"
        /> */}

        <ContentRoute
          exact
          path="/contact-us"
          component={ContactUs}
          isAccessAllow={useIsAccessAllow("contact_us.list")}
        />
        <ContentRoute
          exact
          path="/contact-us/view-contact-us/:id"
          component={ViewContactUs}
          isAccessAllow={useIsAccessAllow("contact_us.view")}
        />
        <ContentRoute
          exact
          path="/contact-us/:pageType"
          component={ContactUs}
        />

        <ContentRoute
          exact
          path="/quote"
          component={QuoteData}
          isAccessAllow={useIsAccessAllow("get_a_quote.list")}
        />
        <ContentRoute exact path="/quote/:pageType" component={QuoteData} />
        <ContentRoute
          exact
          path="/quote/view-quote/:id"
          component={ViewQuote}
          isAccessAllow={useIsAccessAllow("get_a_quote.view")}
        />

        <ContentRoute
          exact
          path="/generativeAI"
          component={GenerativeAIData}
          isAccessAllow={useIsAccessAllow("get_genAI_quote.list")}
        />
        <ContentRoute
          exact
          path="/generativeAI/:pageType"
          component={GenerativeAIData}
        />
        <ContentRoute
          exact
          path="/generativeAI/view-generativeAI/:id"
          component={ViewGenerativeAI}
          isAccessAllow={useIsAccessAllow("get_genAI_quote.view")}
        />
        <ContentRoute
          exact
          path="/careers"
          component={CareersData}
          isAccessAllow={useIsAccessAllow("careers.list")}
        />
        <ContentRoute
          exact
          path="/careers/view-careers/:id"
          component={ViewCareers}
          isAccessAllow={useIsAccessAllow("careers.view")}
        />
         {/* Webclues Author Start */}
        <ContentRoute
          exact
          path="/tag"
          component={Tag}
          isAccessAllow={useIsAccessAllow("tag.list")}
        />
        <ContentRoute
          exact
          path="/tag/add-tag"
          component={addTag}
          isAccessAllow={useIsAccessAllow("tag.add_tag")}
        />
        <ContentRoute
          exact
          path="/tag/edit-tag/:id"
          component={addTag}
          isAccessAllow={useIsAccessAllow("tag.edit_tag")}
        />

        <ContentRoute
          exact
          path="/blog"
          component={Blog}
          isAccessAllow={useIsAccessAllow("blog.list")}
        />
        <ContentRoute
          exact
          path="/blog/add-blog"
          component={addBlog}
          isAccessAllow={useIsAccessAllow("blog.add_blog")}
        />
        <ContentRoute
          exact
          path="/blog/edit-blog/:id"
          component={addBlog}
          isAccessAllow={useIsAccessAllow("blog.edit_blog")}
        />
       
        <ContentRoute
          exact
          path="/author"
          component={AuthorManagement}
          isAccessAllow={useIsAccessAllow("author.list")}
        />
        <ContentRoute
          exact
          path="/author/add-author"
          component={AddAuthor}
          isAccessAllow={useIsAccessAllow("author.add_author")}
        />
        <ContentRoute
          exact
          path="/author/edit-author/:id"
          component={AddAuthor}
          isAccessAllow={useIsAccessAllow("author.edit_author")}
        />
         {/* Webclues Author END */}
        {/* DUBAI Author Start */}
        <ContentRoute
          exact
          path="/dubai_tag"
          component={DubaiTag}
          isAccessAllow={useIsAccessAllow("dubai_tag.list")}
        />
        <ContentRoute
          exact
          path="/dubai_tag/add-tag"
          component={DubaiAddTag}
          isAccessAllow={useIsAccessAllow("dubai_tag.add_tag")}
        />
        <ContentRoute
          exact
          path="/dubai_tag/edit-tag/:id"
          component={DubaiAddTag}
          isAccessAllow={useIsAccessAllow("dubai_tag.edit_tag")}
        />
        <ContentRoute
          exact
          path="/dubai_blog"
          component={DubaiBlog}
          isAccessAllow={useIsAccessAllow("dubai_blog.list")}
        />

        <ContentRoute
          exact
          path="/dubai_blog/add-blog"
          component={dubaiaddBlog}
          isAccessAllow={useIsAccessAllow("dubai_blog.add_blog")}
        />
        <ContentRoute
          exact
          path="/dubai_blog/edit-blog/:id"
          component={dubaiaddBlog}
          isAccessAllow={useIsAccessAllow("dubai_blog.edit_blog")}
        />
        <ContentRoute
          exact
          path="/dubai_author"
          component={DubaiAuthorManagement}
          isAccessAllow={useIsAccessAllow("dubai_author.list")}
        />
        <ContentRoute
          exact
          path="/dubai_author/add-author"
          component={DubaiAddAuthor}
          isAccessAllow={useIsAccessAllow("dubai_author.add_author")}
        />
        <ContentRoute
          exact
          path="/dubai_author/edit-author/:id"
          component={DubaiAddAuthor}
          isAccessAllow={useIsAccessAllow("dubai_author.edit_author")}
        />
        <ContentRoute
          exact
          path="/dubai_category"
          component={DubaiCategory}
          isAccessAllow={useIsAccessAllow("dubai_category.list")}
        />
        <ContentRoute
          exact
          path="/dubai_category/add-category"
          component={DubaiAddCategory}
          isAccessAllow={useIsAccessAllow("dubai_category.add_category")}
        />
        <ContentRoute
          exact
          path="/dubai_category/edit-category/:id"
          component={DubaiAddCategory}
          isAccessAllow={useIsAccessAllow("dubai_category.edit_category")}
        />
        {/* DUBAI Author End */}
        <ContentRoute
          exact
          path="/email-management"
          component={EmailManagement}
          isAccessAllow={useIsAccessAllow("email_management.list")}
        />
        <ContentRoute
          exact
          path="/email-management/add-template"
          component={AddTemplate}
          isAccessAllow={useIsAccessAllow("email_management.add_template")}
        />
        <ContentRoute
          exact
          path="/email-management/edit-template/:id"
          component={AddTemplate}
          isAccessAllow={useIsAccessAllow("email_management.edit_template")}
        />

        <ContentRoute
          exact
          path="/category"
          component={Category}
          isAccessAllow={useIsAccessAllow("category.list")}
        />

        <ContentRoute
          exact
          path="/category/add-category"
          component={addCategory}
          isAccessAllow={useIsAccessAllow("category.add_category")}
        />

        <ContentRoute
          exact
          path="/category/edit-category/:id"
          component={addCategory}
          isAccessAllow={useIsAccessAllow("category.edit_category")}
        />

        <ContentRoute
          exact
          path="/mail-subscribers"
          component={MailSubscriber}
          isAccessAllow={useIsAccessAllow("mail_subscribers.list")}
        />
        <ContentRoute
          exact
          path="/whatsapp/accounts"
          component={WhatsAppAccount}
        />

        <ContentRoute
          exact
          path="/page-speed"
          component={PageSpeedScore}
          isAccessAllow={useIsAccessAllow("pagespeed_score.list")}
        />
                                    {/* Hire AI Blog Route Start */}
           <ContentRoute
          exact
          path="/hire_ai_blog"
          component={HireAIBlog}
          isAccessAllow={useIsAccessAllow("hire_ai_blog.list")}
        />
          <ContentRoute
          exact
          path="/hire_ai_blog/add-blog"
          component={AddHireAIBlog}
          isAccessAllow={useIsAccessAllow("hire_ai_blog.add_blog")}
        />
        <ContentRoute
          exact
          path="/hire_ai_blog/edit-blog/:id"
          component={AddHireAIBlog}
          isAccessAllow={useIsAccessAllow("hire_ai_blog.edit_blog")}
        />
        {/* Hire AI Blog Route End */}
        
             {/* Hire AI tag Route Start */}
           <ContentRoute
          exact
          path="/hire_ai_tag"
          component={HireAITag}
          isAccessAllow={useIsAccessAllow("hire_ai_tag.list")}
        />
          <ContentRoute
          exact
          path="/hire_ai_tag/add-tag"
          component={HireAITagAdd}
          isAccessAllow={useIsAccessAllow("hire_ai_tag.add_tag")}
        />
        <ContentRoute
          exact
          path="/hire_ai_tag/edit-tag/:id"
          component={HireAITagAdd}
          isAccessAllow={useIsAccessAllow("hire_ai_tag.edit_tag")}
        />
        {/* Hire AI tag Route End */}
        

        {/* Hire AI Category Route Start */}

        <ContentRoute
          exact
          path="/hire_ai_category"
          component={HireAICategory}
          isAccessAllow={useIsAccessAllow("hire_ai_category.list")}
        />
        <ContentRoute
          exact
          path="/hire_ai_category/add-category"
          component={HireAIAddCategory}
          isAccessAllow={useIsAccessAllow("hire_ai_category.add_category")}
        />
        <ContentRoute
          exact
          path="/hire_ai_category/edit-category/:id"
          component={HireAIAddCategory}
          isAccessAllow={useIsAccessAllow("hire_ai_category.edit_category")}
        />
        {/* Hire AI Category Route End */}


        {/* Hire AI Author Route Start */}

        <ContentRoute
          exact
          path="/hire_ai_author"
          component={HireAIAuthor}
          isAccessAllow={useIsAccessAllow("hire_ai_author.list")}
        />
        <ContentRoute
          exact
          path="/hire_ai_author/add-author"
          component={HireAIAddAuthor}
          isAccessAllow={useIsAccessAllow("hire_ai_author.add_author")}
        />
        <ContentRoute
          exact
          path="/hire_ai_author/edit-author/:id"
          component={HireAIAddAuthor}
          isAccessAllow={useIsAccessAllow("hire_ai_author.edit_author")}
        />
        {/* Hire AI Author Route End */}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
