export const CategoryMap = {
  CREATE_CATEGORY_START: "CREATE_CATEGORY_START",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_ERROR: "CREATE_CATEGORY_ERROR",
  GET_ALL_CATEGORY_START: "GET_ALL_CATEGORY_START",
  GET_ALL_CATEGORY_SUCCESS: "GET_ALL_CATEGORY_SUCCESS",
  GET_ALL_CATEGORY_ERROR: "GET_ALL_CATEGORY_ERROR",
  UPDATE_CATEGORY_START: "UPDATE_CATEGORY_START",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_ERROR: "UPDATE_CATEGORY_ERROR",
  DELETE_CATEGORY_START: "DELETE_CATEGORY_START",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR",
  GET_CATEGORY_DETAIL_START: "GET_CATEGORY_DETAIL_START",
  GET_CATEGORY_DETAIL_SUCCESS: "GET_CATEGORY_DETAIL_SUCCESS",
  GET_CATEGORY_DETAIL_ERROR: "GET_CATEGORY_DETAIL_ERROR",
  GET_CATEGORY_LIST_START: "GET_CATEGORY_LIST_START",
  GET_CATEGORY_LIST_SUCCESS: "GET_CATEGORY_LIST_SUCCESS",
  GET_CATEGORY_LIST_ERROR: "GET_CATEGORY_LIST_ERROR",

  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_CATEGORY_LIST: "REFRESH_CATEGORY_LIST",
  SET_CATEGORY_BATCH_NUMBER: "SET_CATEGORY_BATCH_NUMBER",
};

export const CategoryActions = {
  getAllCategory: (data) => ({
    type: CategoryMap.GET_ALL_CATEGORY_START,
    payload: data,
  }),
  getAllCategorySuccess: (data) => ({
    type: CategoryMap.GET_ALL_CATEGORY_SUCCESS,
    payload: data,
  }),
  getAllCategoryError: () => ({
    type: CategoryMap.GET_ALL_CATEGORY_ERROR,
  }),

  createCategoryStart: () => ({ type: CategoryMap.CREATE_CATEGORY_START }),
  createCategorySuccess: (data) => ({
    type: CategoryMap.CREATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  createCategoryError: (errors) => ({
    type: CategoryMap.CREATE_CATEGORY_ERROR,
    payload: { errors },
  }),

  updateCategoryStart: () => ({ type: CategoryMap.UPDATE_CATEGORY_START }),
  updateCategorySuccess: (data) => ({
    type: CategoryMap.UPDATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  updateCategoryError: (errors) => ({
    type: CategoryMap.UPDATE_CATEGORY_ERROR,
    payload: { errors },
  }),

  deleteCategoryStart: () => ({ type: CategoryMap.DELETE_CATEGORY_START }),
  deleteCategorySuccess: (data) => ({
    type: CategoryMap.DELETE_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteCategoryError: (errors) => ({
    type: CategoryMap.DELETE_CATEGORY_ERROR,
    payload: { errors },
  }),

  getCategoryDetail: (data) => ({
    type: CategoryMap.GET_CATEGORY_DETAIL_START,
    payload: data,
  }),
  getCategoryDetailSuccess: (data) => ({
    type: CategoryMap.GET_CATEGORY_DETAIL_SUCCESS,
    payload: data,
  }),
  getCategoryDetailError: () => ({
    type: CategoryMap.GET_CATEGORY_DETAIL_ERROR,
  }),

  getCategoryListStart: (data) => ({
    type: CategoryMap.GET_CATEGORY_LIST_START,
    payload: data,
  }),
  getCategoryListSuccess: (data) => ({
    type: CategoryMap.GET_CATEGORY_LIST_SUCCESS,
    payload: data,
  }),
  getCategoryListError: () => ({ type: CategoryMap.GET_CATEGORY_LIST_ERROR }),

  searchTextChange: (data) => ({
    type: CategoryMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  refreshCategoryList: () => ({
    type: CategoryMap.REFRESH_CATEGORY_LIST,
  }),
  setCategoryBatchNumber: (data) => ({
    type: CategoryMap.SET_CATEGORY_BATCH_NUMBER,
    payload: data,
  }),
};
