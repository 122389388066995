import axios from "axios";
import { pageSpeedScoreActions } from './pageSpeedScoreAction'
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
    return state.environnment.environmentLists.adminBaseURL;
}

export const getPageSpeedScore = (url) => {
    return async (dispatch, getState) => {
        try{
            const adminBaseURL = getAdminURL(getState());
            dispatch(pageSpeedScoreActions.getPageSpeedScoreActionsStart())
            const { data } = await axios({
                method : "GET",
                url : `${process.env.REACT_APP_HOST}${adminBaseURL}/googleAnalytics/getPageSpeedScore?pageUrl=${url}`,
                headers: {
                    "Content-Type": "application/json",
                },
            })
            if(data?.code === 200){
                return dispatch(
                    pageSpeedScoreActions.getPageSpeedScoreActionsSuccess(data?.data)
                )
            }
            dispatch(pageSpeedScoreActions.getPageSpeedScoreActionsError())
            return dispatch(showSuccessSnackbar("error",data?.message,30000))
        } catch (error){
            console.log("IN ERR",error)
            dispatch(pageSpeedScoreActions.getPageSpeedScoreActionsError())
            dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000))
        }
    }
}