import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
export function StatisticsWidget1({ svgIcon, iconColor, title, description, redirectUrl }) {
    return (
        <div className="d-flex justify-content-around bg-white px-6 py-8 rounded-sm mb-7">
            <span className={`svg-icon svg-icon-6x svg-icon-${iconColor} d-block my-2`}>
                <SVG src={svgIcon}></SVG>
            </span>
            <div>
                {redirectUrl !== "" ? (
                    <>
                        <Link to={redirectUrl} className="text-primary font-weight-bold font-size-h5 mt-2">
                            {title}
                        </Link>
                    </>
                ) :
                    <>
                        <span className="text-primary font-weight-bold font-size-h5 mt-2">{title}</span>
                    </>
                }
                <div className="text-primary f-w-700 fn_s_32 mt-2">
                    {description}
                </div>
                {/* {loader && <span className="ml-3 spinner spinner-dark"></span>} */}
            </div>
        </div >
    );
};