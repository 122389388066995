export const WhatsAppAccountMap = {
  GET_ALL_WA_ACCOUNTS_START: "GET_ALL_WA_ACCOUNTS_START",
  GET_ALL_WA_ACCOUNTS_SUCCESS: "GET_ALL_WA_ACCOUNTS_SUCCESS",
  GET_ALL_WA_ACCOUNTS_ERROR: "GET_ALL_WA_ACCOUNTS_ERROR",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_WA_ACCOUNTS_LIST: "REFRESH_WA_ACCOUNTS_LIST",
  SELECT_WA_ACCOUNTS_DETAILS: "SELECT_WA_ACCOUNTS_DETAILS",
  SET_WA_ACCOUNTS_BATCH_NUMBER: "SET_WA_ACCOUNTS_BATCH_NUMBER",
};

export const WhatsAppAccountActions = {
  getAllWaAccountsStart: (data) => ({
    type: WhatsAppAccountMap.GET_ALL_WA_ACCOUNTS_START,
    payload: data,
  }),
  getAllWaAccountsSuccess: (data) => ({
    type: WhatsAppAccountMap.GET_ALL_WA_ACCOUNTS_SUCCESS,
    payload: data,
  }),
  getAllWaAccountsError: (errors) => ({
    type: WhatsAppAccountMap.GET_ALL_WA_ACCOUNTS_ERROR,
    payload: { errors },
  }),
  refreshWaAccountsList: () => ({
    type: WhatsAppAccountMap.REFRESH_WA_ACCOUNTS_LIST,
  }),
  searchTextChange: (data) => ({
    type: WhatsAppAccountMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedWaAccounts: (data) => ({
    type: WhatsAppAccountMap.SELECT_WA_ACCOUNTS_DETAILS,
    payload: data,
  }),
  setAccountBatchNumber: (data) => ({
    type: WhatsAppAccountMap.SET_WA_ACCOUNTS_BATCH_NUMBER,
    payload: data,
  }),
};
