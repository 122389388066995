import { generativeAI } from "./generativaAIAction";

const initialState = {
  isLoading: false,
  GenAIList: [],
  refreshGenAIList: true,
  countries: [],
  searchBy: "",
  searchText: "",
  pageNameList: [],
  skip: 0,
  limit: 10,
  selectedContact: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case generativeAI.GET_ALL_GENERATIVEAI_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case generativeAI.GET_ALL_GENERATIVEAI_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        GenAIList: action.payload,
        refreshGenAIList: false,
      };
    }
    case generativeAI.GET_ALL_GENERATIVEAI_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshGenAIList: false,
      };
    }

    case generativeAI.GET_GENERATIVEAI_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case generativeAI.GET_GENERATIVEAI_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        GenAIList: action.payload,
        refreshGenAIList: false,
      };
    }
    case generativeAI.GET_GENERATIVEAI_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshGenAIList: false,
      };
    }

    case generativeAI.DELETE_GENERATIVEAI_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case generativeAI.DELETE_GENERATIVEAI_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshGenAIList: true,
      };
    }
    case generativeAI.DELETE_GENERATIVEAI_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    //Multiple
    case generativeAI.DELETE_MULTIPLE_GENERATIVEAI_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case generativeAI.DELETE_MULTIPLE_GENERATIVEAI_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshGenAIList: true,
      };
    }
    case generativeAI.DELETE_MULTIPLE_GENERATIVEAI_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    
    
    case generativeAI.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
        refreshGenAIList: true,
      };
    }
    case generativeAI.REFRESH_BLOG_LIST: {
      return {
        ...state,
        skip: 0,
        refreshGenAIList: true,
      };
    }
    case generativeAI.SET_GENERATIVEAI_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshGenAIList: true,
      };
    }
    default:
      return { ...state };
  }
};
