import axios from "axios";
import { CategoryActions } from "./categoryAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const createCategoryAsync = (
  detail,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(CategoryActions.createCategoryStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaicategory/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: detail,
      });
      if (data.code === 200 && data.status) {
        dispatch(CategoryActions.createCategorySuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(CategoryActions.createCategoryError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
      setSubmitting(false);
    } catch (error) {
      dispatch(CategoryActions.createCategoryError());
      setSubmitting(false);
      resetForm();
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const getAllCategoryAsync = (searchBy, searchText) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(CategoryActions.getAllCategory());
      let { skip, limit } = getState().category;
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaicategory/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&search=${searchText}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(CategoryActions.getAllCategorySuccess(data.data));
      }
      dispatch(CategoryActions.getAllCategoryError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      console.log("Error: ", error);
      dispatch(CategoryActions.getAllCategoryError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const getCategoryDetailAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(CategoryActions.getCategoryDetail());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaicategory/getDetail/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(CategoryActions.getCategoryDetailSuccess(data.data));
      }
      dispatch(CategoryActions.getCategoryDetailError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CategoryActions.getCategoryDetailError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const updateCategoryAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      //let { _id } = getState().auth.user;
      let { selectedCategory } = getState().category;
      dispatch(CategoryActions.updateCategoryStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaicategory/update/${selectedCategory._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200 && data.status) {
        dispatch(CategoryActions.updateCategorySuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(CategoryActions.updateCategoryError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CategoryActions.updateCategoryError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteCategoryAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(CategoryActions.deleteCategoryStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaicategory/remove/${id}`,
        method: "delete",
      });
      if (data.code === 200) {
        dispatch(CategoryActions.deleteCategorySuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(CategoryActions.deleteCategoryError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      console.log("Error: ", error);
      dispatch(CategoryActions.deleteCategoryError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating.Please try again after sometime..",
          3000
        )
      );
    }
  };
};

export const categoryListAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(CategoryActions.getCategoryListStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaicategory/categoryList`,
        method: "get",
      });
      if (data.code === 200) {
        dispatch(CategoryActions.getCategoryListSuccess(data.data));
        // return dispatch(showSuccessSnackbar("success", data.message, 3000));
      } else {
        dispatch(CategoryActions.getCategoryListError());
        return dispatch(showSuccessSnackbar("error", data.message, 3000));
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch(CategoryActions.getCategoryListError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating.Please try again after sometime..",
          3000
        )
      );
    }
  };
};
