import React, { useEffect, useState } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
/* import { CKEditor } from "ckeditor4-react"; */
import { Col, Row, Button } from "react-bootstrap";
import { useParams, Redirect, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import {
  addAuthorAsync,
  getAuthorDetailAsync,
  updateAuthorAsync,
} from "../redux/authorManagementApi";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CustomEditor from "../../CustomEditor/CustomEditor";
import Select from "react-select";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { CircularProgress } from "@material-ui/core";

const HireAIAddAuthor = () => {
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedAuthor } = useSelector((state) => state.author, shallowEqual);
  const [bannerImage, setBannerImage] = useState({
    file: null,
    url: "",
  });
  let { websiteLists, web_id } = useSelector(
    (state) => state.environnment,
    shallowEqual
  );
  const [defaultWebList, setdefaultWebList] = useState([]);
  const [createdDate, setCreatedDate] = useState(new Date());

  useEffect(() => {
    if (id) {
      dispatch(getAuthorDetailAsync(id));
    }
  }, []);

  useEffect(() => {
    if (
      selectedAuthor &&
      selectedAuthor.bannerImage &&
      selectedAuthor.bannerImage !== "undefined" &&
      params.id !== undefined
    ) {
      web_id = selectedAuthor.web_id;
      setBannerImage({ file: null, url: selectedAuthor?.bannerImage });
    }
    setdefaultWebList([
      {
        value: web_id,
        label: web_id,
      },
    ]);
  }, []);

  if (!selectedAuthor && params?.id !== undefined) {
    return <Redirect to="/hire_ai_author" />;
  }

  const initialValues = {
    web_id: web_id != "all" ? web_id : "",
    authorName: "",
    authorDescription: "",
    authorDescriptionSub: "",
    slug: "",
    linkedIn: "",
    authorMailId: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
  };

  const redirectBack = () => {
    history.push("/hire_ai_author");
  };
  const addAuthorSchema = Yup.object().shape({
    authorName: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Author Name"
      )
      .required("Please enter Author Name"),
    slug: Yup.string()
      .trim()
      .required("Please enter slug"),
    authorDescription: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Author Description"
      )
      .required("Please enter Author Description"),
    authorDescriptionSub: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Author Description"
      )
      .required("Please enter Author Description Sub"),
    linkedIn: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Author Description"
      )
      .required("Please enter Author LinkedIn"),
    authorMailId: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Author Description"
      )
      .required("Please enter Author Email Address"),
  });
  const uploadBannerImage = (e) => {
    setBannerImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const convertToSlug = (str) => {
    return str
      .toLowerCase()
      .trim()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/-+/g, "-");
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          selectedAuthor && params?.id !== undefined
            ? selectedAuthor
            : initialValues
        }
        validationSchema={addAuthorSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let data = null;
          data = new FormData();
          if (bannerImage && bannerImage.file) {
            data.append("bannerImage", bannerImage.file);
          } else if (values && values.bannerImage) {
            data.append("bannerImage", values.bannerImage);
          } else {
            return dispatch(
              showSuccessSnackbar("error", "Please select bannerimage", 3000)
            );
          }
          data.append("web_id", values.web_id || "all ");
          data.append("authorName", values.authorName);
          data.append("authorDescription", values.authorDescription);
          data.append("authorDescriptionSub", values.authorDescriptionSub);
          data.append("slug", values.slug);
          data.append("linkedIn", values.linkedIn);
          data.append("authorMailId", values.authorMailId);
          data.append("createdOn", createdDate.getTime());
          data.append("metaTitle", values.metaTitle);
          data.append("metaDescription", values.metaDescription);
          data.append("metaKeywords", values.metaKeywords);
          setSubmitting(true);
          if (selectedAuthor && params?.id !== undefined) {
            dispatch(
              updateAuthorAsync(data, setSubmitting, resetForm, redirectBack)
            );
          } else {
            dispatch(
              addAuthorAsync(data, setSubmitting, resetForm, redirectBack)
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Card>
            <CardHeader
              title={
                <div className="form-group m-0 d-flex align-items-center">
                  <Link className="btn btn-link px-0" to="/hire_ai_author">
                    <SVG
                      className="h-50 align-self-center"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-left.svg"
                      )}
                    />
                    Back
                  </Link>
                  <span className="pl-2">
                    {params?.id ? "Edit Author" : "Add Author"}
                  </span>
                </div>
              }
            ></CardHeader>
            <CardBody>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Form>
                    <div className="form-group">
                      <label className="form-label">Web ID</label>
                      <Select
                        classNamePrefix="react-select"
                        name="web_id"
                        placeholder="Web ID"
                        className={`form-control border-0 p-0 `}
                        options={websiteLists}
                        onChange={(e) => {
                          setFieldValue("web_id", e.value);
                          setdefaultWebList([
                            {
                              value: e.value,
                              label: e.value,
                            },
                          ]);
                        }}
                        value={defaultWebList}
                      />
                      {touched.web_id && errors.web_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.web_id}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Author Name</label>
                      <input
                        placeholder="Enter Author Name"
                        type="text"
                        // disabled={selectedAuthor && params?.id !== undefined}
                        name="authorName"
                        className={
                          errors.authorName && touched.authorName
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.authorName}
                      />
                      {touched.authorName && errors.authorName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.authorName}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Slug</label>
                      <input
                        placeholder="Enter Slug"
                        type="text"
                        name="slug"
                        className={
                          errors.slug && touched.slug
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={(event) => {
                          let slug = event.target.value;
                          if (slug) {
                            slug = convertToSlug(slug);
                          }
                          setFieldValue("slug", slug);
                        }}
                        value={values.slug ? values.slug : ""}
                      />
                      {touched.slug && errors.slug ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.slug}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Author Description</label>
                      <textarea
                        placeholder="Author Description"
                        type="text"
                        name="authorDescription"
                        className={
                          errors.authorDescription && touched.authorDescription
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.authorDescription}
                      />
                      {touched.authorDescription && errors.authorDescription ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.authorDescription}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        Author Description Sub
                      </label>
                      <textarea
                        placeholder="Author Description Sub"
                        type="text"
                        name="authorDescriptionSub"
                        className={
                          errors.authorDescriptionSub &&
                          touched.authorDescriptionSub
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.authorDescriptionSub}
                      />
                      {touched.authorDescriptionSub &&
                      errors.authorDescriptionSub ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.authorDescriptionSub}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Author LinkedIn</label>
                      <input
                        placeholder="Enter Author LinkedIn"
                        type="text"
                        // disabled={selectedAuthor && params?.id !== undefined}
                        name="linkedIn"
                        className={
                          errors.linkedIn && touched.linkedIn
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.linkedIn}
                      />
                      {touched.linkedIn && errors.linkedIn ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.linkedIn}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Author Mail Address</label>
                      <input
                        placeholder="Enter Author Mail Address"
                        type="text"
                        // disabled={selectedAuthor && params?.id !== undefined}
                        name="authorMailId"
                        className={
                          errors.authorMailId && touched.authorMailId
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.authorMailId}
                      />
                      {touched.authorMailId && errors.authorMailId ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.authorMailId}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">SEO : Meta Title</label>
                      <input
                        placeholder="Enter meta tile"
                        type="text"
                        name="metaTitle"
                        className={
                          errors.metaTitle && touched.metaTitle
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.metaTitle ? values.metaTitle : ""}
                      />
                      {touched.metaTitle && errors.metaTitle ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.metaTitle}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        SEO : Meta Description
                      </label>
                      <textarea
                        placeholder="Enter meta Description"
                        type="text"
                        name="metaDescription"
                        className={
                          errors.metaDescription && touched.metaDescription
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows="7"
                        value={
                          values.metaDescription ? values.metaDescription : ""
                        }
                      />
                      {touched.metaDescription && errors.metaDescription ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.metaDescription}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">SEO : Meta Keywords</label>
                      <input
                        placeholder="Enter meta keywords"
                        type="text"
                        name="metaKeywords"
                        className={
                          errors.metaKeywords && touched.metaKeywords
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.metaKeywords ? values.metaKeywords : ""}
                      />
                      {touched.metaKeywords && errors.metaKeywords ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.metaKeywords}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-12 text-center mb-4">
                      <label className="form-label">Author Image</label>
                      <div
                        className="pro_img"
                        style={{
                          backgroundImage: `url(${bannerImage.url})`,
                          height: 120,
                          width: "100%",
                          borderRadius: 10,
                          backgroundSize: "contain",
                          border: "1px solid #cccccc",
                        }}
                      >
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={uploadBannerImage}
                        />
                        <div
                          className="edt_img_btn"
                          style={{ fontSize: 14, paddingTop: 60 }}
                        >
                          <span className="material-icons">edit</span>EDIT
                        </div>
                      </div>
                      <br></br>
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link
                          className="btn btn-outline-blue"
                          to="/hire_ai_author"
                        >
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button
                          variant="blue"
                          className="px-5 defpddng spinnerBtn"
                          size="lg"
                          type="submit"
                        >
                          {selectedAuthor && params?.id !== undefined
                            ? "Update"
                            : "Submit"}
                          {isSubmitting && (
                            <div className="ml-3 basic-verification-loader text-center">
                              <CircularProgress />
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Formik>
    </>
  );
};
export default HireAIAddAuthor;
