import React, { useEffect } from "react";
import ApexCharts from "apexcharts";

export function DonutChart({ series, labels, graph, text }) {
  useEffect(() => {
    let options = {
      chart: {
        width: 380,
        type: "donut",
      },
      title: {
        text: text,
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "25px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
      dataLabels: {
        enabled: true,
      },
      series: series,
      labels: labels,
    };

    let element =
      graph == 0
        ? document.getElementById("chart")
        : document.getElementById("chartCountry");
    if (!element) {
      return;
    }

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [series, labels]);

  return (
    <>{graph == 0 ? <div id="chart"></div> : <div id="chartCountry"></div>}</>
  );
}
