import axios from "axios";
import { contactUsAction } from "./contactUsAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllContactUsAsync = (searchBy, searchText, type) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { skip, limit } = getState().contactUs;
      dispatch(contactUsAction.getAllContactStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/contactUs/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&type=${type}&dir=&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          contactUsAction.getAllContactSuccess(data.data)
        );
      }
      dispatch(contactUsAction.getAllContactError());
      return dispatch(
        showSuccessSnackbar("success", data.message, 3000)
      );
    } catch (error) {
      console.log(error)
      dispatch(contactUsAction.getAllContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getContactUsAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(contactUsAction.getContactStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/contactUs/getContectUs/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          contactUsAction.getContactSuccess(data.data)
        );
      }
      dispatch(contactUsAction.getContactError());
      return dispatch(
        showSuccessSnackbar("success", data.message, 3000)
      );
    } catch (error) {
      console.log(error)
      dispatch(contactUsAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};


export const deleteContactUsAsync = (id) => {
  return async (dispatch, getState) => {
    try {
	const dataarra	= [id];

      const adminBaseURL = getAdminURL(getState());
      dispatch(contactUsAction.getContactStart());
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/contactUs/removeMultiContactsUs/`,
        headers: {
          "Content-Type": "application/json",
        },
		data: {
			data:dataarra,
		},
      });
	  console.log(id);
      if (data.code === 200) {
        return dispatch(
          contactUsAction.getContactSuccess(data.data)
        );
      }
      dispatch(contactUsAction.getContactError());
      return dispatch(
        showSuccessSnackbar("success", data.message, 3000)
      );
    } catch (error) {
      console.log(error)
      dispatch(contactUsAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};


export const deletedSelectedContactsUs = (id) => {
  return async (dispatch, getState) => {
    try {
	const idsArray	= id;

      const adminBaseURL = getAdminURL(getState());
      dispatch(contactUsAction.getContactStart());
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/contactUs/deletedSelectedContactsUs/`,
        headers: {
          "Content-Type": "application/json",
        },
		data: {
			ids:idsArray,
		},
      });
	  console.log(id);
      if (data.code === 200) {
        return dispatch(
          contactUsAction.getContactSuccess(data.data)
        );
      }
      dispatch(contactUsAction.getContactError());
      return dispatch(
        showSuccessSnackbar("success", data.message, 3000)
      );
    } catch (error) {
      console.log(error)
      dispatch(contactUsAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getContactPageNameListAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(contactUsAction.getContactStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/contactUs/getPageNameList`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          contactUsAction.getContactPageNameListSuccess(data.data)
        );
      }
    } catch (error) {
      console.log(error)
      dispatch(contactUsAction.getContactError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};