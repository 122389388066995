import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const AccessDenied = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`,
        }}
      >
        <h1 className="font-size-h3 font-weight-light">Access Denied</h1>
      </div>
    </div>
  );
};
