import axios from "axios";
import { MailSubscriberActions } from "./mailSubscriberAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllMailSubscribersAsync = (searchBy, searchText, type) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { skip, limit } = getState().mailSubscriber;
      dispatch(MailSubscriberActions.getAllEmailStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/mail/getAllSubscribers?skip=${skip}&limit=${limit}&column=${searchBy}&dir=&search=${searchText}`,
        headers: { "Content-Type": "application/json" },
      });
      if (data.code === 200) {
        return dispatch(MailSubscriberActions.getAllEmailSuccess(data.data));
      }
      dispatch(MailSubscriberActions.getAllEmailError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      dispatch(MailSubscriberActions.getAllEmailError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};