import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  updateUserProfileAsync,
} from "../redux/profileApi";
import { isValidPhoneNumber } from "react-phone-number-input";
import { regexValidation } from "../../../../_metronic/_helpers";

const ProfileBasicDetails = (props) => {
  // const [file, setFile] = useState({ file: null, url: "" });
  const [file] = useState({ file: null, url: "" });

  const dispatch = useDispatch();
  useEffect(() => {
    if (props && props.selectedTab !== "basic-details") {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [props && props.selectedTab]);

  const { user } = useSelector((state) => state.auth, shallowEqual);

  const initialValues = {
    email: user.email || "",
    contactNumber: user.contactNumber || "",
    name: user.name || "",
  };

  const ProfileSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .trim()
        .required("Full name is required"),
      email: Yup.string()
        .trim()
        .email("Enter valid email")
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("Email is required"),
      contactNumber: Yup.string()
        .trim()
        .max(15, "Maximum 15 numbers")
        .matches(regexValidation.CONTACT_NUMBER_REGEX, "Contact Number is not valid")
        .required("Contact Number is required"),
    });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ProfileSchema,
    onSubmit: (values, { setErrors, resetForm }) => {
      let data = null;
      data = new FormData();
      if (file && file.file) {
        data.append("profilePicture", file.file);
      }
      data.append("name", values.name);
      data.append("email", values.email);
      data.append("contactNumber", values?.contactNumber)
      if ((isValidPhoneNumber(values && values.contactNumber))) {
        dispatch(updateUserProfileAsync(data));
        resetForm();
      } else {
        setErrors({ contactNumber: "Invalid contact number" });
      }
    },
  });

  // const uploadImage = (e) => {
  //   setFile({
  //     url: URL.createObjectURL(e.target.files[0]),
  //     file: e.target.files[0],
  //   });
  // };


  return (
    <>
      <div className="tblmrgn mt-0 lwpddng cstm_pddng">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-6-as">
            <form
              className="form def_form frmwtpddng"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-md-12 text-center mb-4">
                  <div className="pro_img" style={{ backgroundImage: `url(${file.url || user.profilePicture})` }} >
                    {/* <input type="file" accept=".png, .jpg, .jpeg" onChange={uploadImage} disabled /> */}
                    {/* <div className="edt_img_btn">
                      <span className="material-icons">edit</span>EDIT
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Full Name</label>
                    <input
                      placeholder="Full Name"
                      type="text"
                      className={`form-control wth_chng`}
                      name="name"
                      {...formik.getFieldProps("name")}
                      disabled
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Phone</label>

                    <input
                      placeholder="Enter contact number(i.e. +99123456789)"
                      type="text"
                      className={`form-control wth_chng`}
                      name="contactNumber"
                      {...formik.getFieldProps("contactNumber")}
                      disabled
                    />
                    {formik.touched.contactNumber && formik.errors.contactNumber ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.contactNumber}
                        </div>
                      </div>
                    ) : null}

                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <div className="re_grpPhone position-relative">
                      <input
                        placeholder="username@company.com"
                        type="email"
                        className={`form-control wth_chng`}
                        name="email"
                        {...formik.getFieldProps("email")}
                        disabled
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                <button
                  type="submit"
                  disabled={Object.keys(formik.errors).length > 0}
                  className="btn btn-blue defpddng"
                >
                  <span>Update</span>
                  {isLoading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div> */}
            </form>

          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileBasicDetails;
