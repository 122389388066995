import { MailSubscriberMap } from "./mailSubscriberAction";

const initialState = {
  isLoading: false,
  emailList: {},
  refreshEmailList: true,
  selectedEmail: null,
  searchBy: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MailSubscriberMap.GET_ALL_EMAIL_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MailSubscriberMap.GET_ALL_EMAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        emailList: action.payload,
        refreshEmailList: false,
      };
    }
    case MailSubscriberMap.GET_ALL_EMAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshEmailList: false,
      };
    }
    case MailSubscriberMap.SELECT_EMAIL_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedEmail: action.payload,
      };
    }
    case MailSubscriberMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
        refreshEmailList: true
      };
    }
    case MailSubscriberMap.SET_EMAIL_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshEmailList: true,
      };
    }
    case MailSubscriberMap.REFRESH_EMAIL_LIST: {
      return {
        ...state,
        skip: 0,
        refreshEmailList: true,
      };
    }
    default:
      return { ...state };
  }
};
