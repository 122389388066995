import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { WhatsAppAccountActions, getAllAccountsAsync } from "../redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../../_metronic/_partials/controls";
import WhatsAppAccountTable from "./whatsAppAccount/whatsAppAccountTable";

export function WhatsAppAccount() {
  const dispatch = useDispatch();
  const searchRef = useRef();
  const type = "";
  const { refreshList, searchBy, searchText } = useSelector(
    (state) => state.whatsAppAccount,
    shallowEqual
  );

  useEffect(() => {
    if (refreshList) {
      dispatch(getAllAccountsAsync(searchBy, searchText, type));
    }
  }, [refreshList]);

  const onSearchTextChange = (e) => {
    dispatch(WhatsAppAccountActions.searchTextChange(e.target.value));
  };

  return (
    <>
      <Card>
        <CardHeader title="WhatsApp Accounts">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <WhatsAppAccountTable />
      </Card>
    </>
  );
}
