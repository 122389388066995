export const TagMap = {
    CREATE_TAG_START: 'CREATE_TAG_START',
    CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
    CREATE_TAG_ERROR: 'CREATE_TAG_ERROR',
    GET_ALL_TAG_START: 'GET_ALL_TAG_START',
    GET_ALL_TAG_SUCCESS: 'GET_ALL_TAG_SUCCESS',
    GET_ALL_TAG_ERROR: 'GET_ALL_TAG_ERROR',
    UPDATE_TAG_START: 'UPDATE_TAG_START',
    UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
    UPDATE_TAG_ERROR: 'UPDATE_TAG_ERROR',
    DELETE_TAG_START: 'DELETE_TAG_START',
    DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
    DELETE_TAG_ERROR: 'DELETE_TAG_ERROR',
    GET_TAG_DETAIL_START: 'GET_TAG_DETAIL_START',
    GET_TAG_DETAIL_SUCCESS: 'GET_TAG_DETAIL_SUCCESS',
    GET_TAG_DETAIL_ERROR: 'GET_TAG_DETAIL_ERROR',
    GET_TAG_LIST_START: 'GET_TAG_LIST_START',
    GET_TAG_LIST_SUCCESS: 'GET_TAG_LIST_SUCCESS',
    GET_TAG_LIST_ERROR: 'GET_TAG_LIST_ERROR',
    SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
    REFRESH_TAG_LIST: "REFRESH_TAG_LIST",
    SET_TAG_BATCH_NUMBER: "SET_TAG_BATCH_NUMBER",
}

export const TagActions = {
    getAllTag: (data) => ({ type: TagMap.GET_ALL_TAG_START, payload: data }),
    getAllTagSuccess: (data) => ({ type: TagMap.GET_ALL_TAG_SUCCESS, payload: data }),
    getAllTagError: () => ({ type: TagMap.GET_ALL_TAG_ERROR }),

    createTagStart: () => ({ type: TagMap.CREATE_TAG_START }),
    createTagSuccess: (data) => ({ type: TagMap.CREATE_TAG_SUCCESS, payload: data }),
    createTagError: (errors) => ({ type: TagMap.CREATE_TAG_ERROR, payload: { errors } }),

    updateTagStart: () => ({ type: TagMap.UPDATE_TAG_START }),
    updateTagSuccess: (data) => ({ type: TagMap.UPDATE_TAG_SUCCESS, payload: data }),
    updateTagError: (errors) => ({ type: TagMap.UPDATE_TAG_ERROR, payload: { errors } }),

    deleteTagStart: () => ({ type: TagMap.DELETE_TAG_START }),
    deleteTagSuccess: (data) => ({ type: TagMap.DELETE_TAG_SUCCESS, payload: data }),
    deleteTagError: (errors) => ({ type: TagMap.DELETE_TAG_ERROR, payload: { errors } }),

    getTagDetail: (data) => ({ type: TagMap.GET_TAG_DETAIL_START, payload: data }),
    getTagDetailSuccess: (data) => ({ type: TagMap.GET_TAG_DETAIL_SUCCESS, payload: data }),
    getTagDetailError: () => ({ type: TagMap.GET_TAG_DETAIL_ERROR }),

    getTagListStart: (data) => ({ type: TagMap.GET_TAG_LIST_START, payload: data }),
    getTagListSuccess: (data) => ({ type: TagMap.GET_TAG_LIST_SUCCESS, payload: data }),
    getTagListError: () => ({ type: TagMap.GET_TAG_LIST_ERROR }),

    searchTextChange: (data) => ({ type: TagMap.SEARCH_TEXT_CHANGE, payload: data }),
    refreshTagList: () => ({ type: TagMap.REFRESH_TAG_LIST }),
    setTagBatchNumber: (data) => ({ type: TagMap.SET_TAG_BATCH_NUMBER, payload: data }),
}