export const ContactUsMap = {
  GET_ALL_CONTACT_START: "GET_ALL_CONTACT_START",
  GET_ALL_CONTACT_SUCCESS: "GET_ALL_CONTACT_SUCCESS",
  GET_ALL_CONTACT_ERROR: "GET_ALL_CONTACT_ERROR",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  SET_CONTACT_BATCH_NUMBER: "SET_CONTACT_BATCH_NUMBER",
  GET_CONTACT_START: "GET_CONTACT_START",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_ERROR: "GET_CONTACT_ERROR",
  SELECT_CONTACT_DETAILS: "SELECT_CONTACT_DETAILS",
  PAGE_TYPE_CHANGE: "PAGE_TYPE_CHANGE",
  GET_PAGE_NAME_LIST_SUCCESS: "GET_PAGE_NAME_LIST_SUCCESS",
};

export const contactUsAction = {
  getAllContactStart: (data) => ({
    type: ContactUsMap.GET_ALL_CONTACT_START,
    payload: data,
  }),
  getAllContactSuccess: (data) => ({
    type: ContactUsMap.GET_ALL_CONTACT_SUCCESS,
    payload: data,
  }),
  getAllContactError: (errors) => ({
    type: ContactUsMap.GET_ALL_CONTACT_ERROR,
    payload: { errors },
  }),
  refreshContactList: () => ({ type: ContactUsMap.REFRESH_CONTACT_LIST }),
  searchTextChange: (data) => ({
    type: ContactUsMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setContactBatchNumber: (data) => ({
    type: ContactUsMap.SET_CONTACT_BATCH_NUMBER,
    payload: data,
  }),
  getContactStart: (data) => ({
    type: ContactUsMap.GET_CONTACT_START,
    payload: data,
  }),
  getContactSuccess: (data) => ({
    type: ContactUsMap.GET_CONTACT_SUCCESS,
    payload: data,
  }),
  getContactError: (errors) => ({
    type: ContactUsMap.GET_CONTACT_ERROR,
    payload: { errors },
  }),
  getContactPageNameListSuccess: (data) => ({
    type: ContactUsMap.GET_PAGE_NAME_LIST_SUCCESS,
    payload:data,
  })
};
