export const dashboardMap = {
  GET_TOTAL_LEADS_DASHBORD_COUNT_START: "GET_TOTAL_LEADS_DASHBORD_COUNT_START",
  GET_TOTAL_LEADS_DASHBORD_COUNT_SUCCESS:
    "GET_TOTAL_LEADS_DASHBORD_COUNT_SUCCESS",
  GET_TOTAL_LEADS_DASHBORD_COUNT_ERROR: "GET_TOTAL_LEADS_DASHBORD_COUNT_ERROR",
  GET_MONTHLY_LEADS_DASHBORD_LIST_START:
    "GET_MONTHLY_LEADS_DASHBORD_LIST_START",
  GET_MONTHLY_LEADS_DASHBORD_LIST_SUCCESS:
    "GET_MONTHLY_LEADS_DASHBORD_LIST_SUCCESS",
  GET_MONTHLY_LEADS_DASHBORD_LIST_ERROR:
    "GET_MONTHLY_LEADS_DASHBORD_LIST_ERROR",
  GET_COUNTRY_LEADS_DASHBORD_LIST_START:
    "GET_COUNTRY_LEADS_DASHBORD_LIST_START",
  GET_COUNTRY_LEADS_DASHBORD_LIST_SUCCESS:
    "GET_COUNTRY_LEADS_DASHBORD_LIST_SUCCESS",
  GET_COUNTRY_LEADS_DASHBORD_LIST_ERROR:
    "GET_COUNTRY_LEADS_DASHBORD_LIST_ERROR",
  REFRESH_DASHBORD_LIST: "REFRESH_DASHBORD_LIST",
  REFRESH_COUNTRY_DASHBORD_LIST: "REFRESH_COUNTRY_DASHBORD_LIST",
  SET_DASHBORD_BATCH_NUMBER: "SET_DASHBORD_BATCH_NUMBER",
  SET_DASHBORD_COUNTRY_BATCH_NUMBER: "SET_DASHBORD_COUNTRY_BATCH_NUMBER",

  // GoogleAnalytics
  GET_GOOGLE_ANALYRICS_START: "GET_GOOGLE_ANALYRICS_START",
  GET_GOOGLE_ANALYRICS_SUCCESS: "GET_GOOGLE_ANALYRICS_SUCCESS",
  GET_GOOGLE_ANALYRICS_ERROR: "GET_GOOGLE_ANALYRICS_ERROR",
  FILTER_DATE_GOOGLE_ANALYRICS: "FILTER_DATE_GOOGLE_ANALYRICS",
  REFRESH_GOOGLE_ANALYRICS_DATA: "REFRESH_GOOGLE_ANALYRICS_DATA",

  // GoogleAnalytics Graph
  GET_GOOGLE_ANALYRICS_GRAPH_START: "GET_GOOGLE_ANALYRICS_GRAPH_START",
  GET_GOOGLE_ANALYRICS_GRAPH_SUCCESS: "GET_GOOGLE_ANALYRICS_GRAPH_SUCCESS",
  GET_GOOGLE_ANALYRICS_GRAPH_ERROR: "GET_GOOGLE_ANALYRICS_GRAPH_ERROR",
  FILTER_DATE_GOOGLE_ANALYRICS_GRAPH: "FILTER_DATE_GOOGLE_ANALYRICS_GRAPH",
  REFRESH_GOOGLE_ANALYRICS_GRAPH_DATA: "REFRESH_GOOGLE_ANALYRICS_GRAPH_DATA",

  // QuotePage
  GET_QUOTEPAGE_COUNT_START: "GET_QUOTEPAGE_COUNT_START",
  GET_QUOTEPAGE_COUNT_SUCCESS: "GET_QUOTEPAGE_COUNT_SUCCESS",
  GET_QUOTEPAGE_COUNT_ERROR: "GET_QUOTEPAGE_COUNT_ERROR",
  REFRESH_QUOTEPAGE_COUNT_DATA: "REFRESH_QUOTEPAGE_COUNT_DATA",
  SET_DASHBORD_QUOTE_BATCH_NUMBER: "SET_DASHBORD_QUOTE_BATCH_NUMBER",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  FILTER_DATE: "FILTER_DATE",

  // ContactUs
  GET_CONTACTPAGE_COUNT_START: "GET_CONTACTPAGE_COUNT_START",
  GET_CONTACTPAGE_COUNT_SUCCESS: "GET_CONTACTPAGE_COUNT_SUCCESS",
  GET_CONTACTPAGE_COUNT_ERROR: "GET_CONTACTPAGE_COUNT_ERROR",
  REFRESH_CONTACTPAGE_COUNT_DATA: "REFRESH_CONTACTPAGE_COUNT_DATA",
  SET_DASHBORD_CONTACT_BATCH_NUMBER: "SET_DASHBORD_CONTACT_BATCH_NUMBER",
  SEARCH_TEXT_CONTACTPAGE_CHANGE: "SEARCH_TEXT_CONTACTPAGE_CHANGE",
  FILTER_DATE_CONTACTPAGE: "FILTER_DATE_CONTACTPAGE",

  // OneMonth
  GET_ONEMONTH_COUNT_START: "GET_ONEMONTH_COUNT_START",
  GET_ONEMONTH_COUNT_SUCCESS: "GET_ONEMONTH_COUNT_SUCCESS",
  GET_ONEMONTH_COUNT_ERROR: "GET_ONEMONTH_COUNT_ERROR",
  REFRESH_ONEMONTH_COUNT_DATA: "REFRESH_ONEMONTH_COUNT_DATA",

  // KeyworkdRanker
  GET_KEYWORD_RANKER_START: "GET_KEYWORD_RANKER_START",
  GET_KEYWORD_RANKER_SUCCESS: "GET_KEYWORD_RANKER_SUCCESS",
  GET_KEYWORD_RANKER_ERROR: "GET_KEYWORD_RANKER_ERROR",
  REFRESH_KEYWORD_RANKER_DATA: "REFRESH_KEYWORD_RANKER_DATA",
};

export const dashboardActions = {
  // KeyworkdRanker
  getKeywordRankStart: (data) => ({
    type: dashboardMap.GET_KEYWORD_RANKER_START,
    payload: data,
  }),
  getKeywordRankSuccess: (data) => ({
    type: dashboardMap.GET_KEYWORD_RANKER_SUCCESS,
    payload: data,
  }),
  getKeywordRankError: (data) => ({
    type: dashboardMap.GET_KEYWORD_RANKER_ERROR,
    payload: data,
  }),
  refKeywordRankList: () => ({
    type: dashboardMap.REFRESH_KEYWORD_RANKER_DATA,
  }),

  // OneMonth

  getOneMonthCountStart: (data) => ({
    type: dashboardMap.GET_ONEMONTH_COUNT_START,
    payload: data,
  }),
  getOneMonthCountSuccess: (data) => ({
    type: dashboardMap.GET_ONEMONTH_COUNT_SUCCESS,
    payload: data,
  }),
  getOneMonthCountError: (data) => ({
    type: dashboardMap.GET_ONEMONTH_COUNT_ERROR,
    payload: data,
  }),
  refreshOneMonthList: () => ({
    type: dashboardMap.REFRESH_ONEMONTH_COUNT_DATA,
  }),

  // counts
  getTotalLeadsDashboardCountStart: (data) => ({
    type: dashboardMap.GET_TOTAL_LEADS_DASHBORD_COUNT_START,
    payload: data,
  }),
  getTotalLeadsDashboardCountSuccess: (data) => ({
    type: dashboardMap.GET_TOTAL_LEADS_DASHBORD_COUNT_SUCCESS,
    payload: data,
  }),
  getTotalLeadsDashboardCountError: (data) => ({
    type: dashboardMap.GET_TOTAL_LEADS_DASHBORD_COUNT_ERROR,
    payload: data,
  }),
  refreshMonthDashBoardList: () => ({
    type: dashboardMap.REFRESH_DASHBORD_LIST,
  }),
  refreshCountryDashboard: () => ({
    type: dashboardMap.REFRESH_COUNTRY_DASHBORD_LIST,
  }),
  setDashBoardMonthBatchNumber: (data) => ({
    type: dashboardMap.SET_DASHBORD_BATCH_NUMBER,
    payload: data,
  }),

  setDashBoardCountryBatchNumber: (data) => ({
    type: dashboardMap.SET_DASHBORD_COUNTRY_BATCH_NUMBER,
    payload: data,
  }),

  // monthly leads
  getMonthlyLeadsDashboardListStart: (data) => ({
    type: dashboardMap.GET_MONTHLY_LEADS_DASHBORD_LIST_START,
    payload: data,
  }),
  getMonthlyLeadsDashboardListSuccess: (data) => ({
    type: dashboardMap.GET_MONTHLY_LEADS_DASHBORD_LIST_SUCCESS,
    payload: data,
  }),
  getMonthlyLeadsDashboardListError: (data) => ({
    type: dashboardMap.GET_MONTHLY_LEADS_DASHBORD_LIST_ERROR,
    payload: data,
  }),

  // yearly leads
  getCountryLeadsDashboardListStart: (data) => ({
    type: dashboardMap.GET_COUNTRY_LEADS_DASHBORD_LIST_START,
    payload: data,
  }),
  getCountryLeadsDashboardListSuccess: (data) => ({
    type: dashboardMap.GET_COUNTRY_LEADS_DASHBORD_LIST_SUCCESS,
    payload: data,
  }),
  getCountryLeadsDashboardListError: (data) => ({
    type: dashboardMap.GET_COUNTRY_LEADS_DASHBORD_LIST_ERROR,
    payload: data,
  }),

  //googleAnalytics data

  getGoogleAnalyticsDataStart: (data) => ({
    type: dashboardMap.GET_GOOGLE_ANALYRICS_START,
    payload: data,
  }),
  getGoogleAnalyticsDataSuccess: (data) => ({
    type: dashboardMap.GET_GOOGLE_ANALYRICS_SUCCESS,
    payload: data,
  }),
  getGoogleAnalyticsDataError: (data) => ({
    type: dashboardMap.GET_GOOGLE_ANALYRICS_ERROR,
    payload: data,
  }),
  filterDateAnalytics: (data) => ({
    type: dashboardMap.FILTER_DATE_GOOGLE_ANALYRICS,
    payload: data,
  }),
  refreshDateAnalytics: () => ({
    type: dashboardMap.REFRESH_GOOGLE_ANALYRICS_DATA,
  }),

  //googleAnalytics data Graph

  getGoogleAnalyticsDataGraphStart: (data) => ({
    type: dashboardMap.GET_GOOGLE_ANALYRICS_GRAPH_START,
    payload: data,
  }),
  getGoogleAnalyticsDataGraphSuccess: (data) => ({
    type: dashboardMap.GET_GOOGLE_ANALYRICS_GRAPH_SUCCESS,
    payload: data,
  }),
  getGoogleAnalyticsDataGraphError: (data) => ({
    type: dashboardMap.GET_GOOGLE_ANALYRICS_GRAPH_ERROR,
    payload: data,
  }),
  filterDateAnalyticsGraph: (data) => ({
    type: dashboardMap.FILTER_DATE_GOOGLE_ANALYRICS_GRAPH,
    payload: data,
  }),
  refreshDateAnalyticsGraph: () => ({
    type: dashboardMap.REFRESH_GOOGLE_ANALYRICS_GRAPH_DATA,
  }),

  //Quote Page Data

  getQuotePageStart: (data) => ({
    type: dashboardMap.GET_QUOTEPAGE_COUNT_START,
    payload: data,
  }),
  getQuotePageSuccess: (data) => ({
    type: dashboardMap.GET_QUOTEPAGE_COUNT_SUCCESS,
    payload: data,
  }),
  getQuotePageError: (data) => ({
    type: dashboardMap.GET_QUOTEPAGE_COUNT_ERROR,
    payload: data,
  }),
  setDashBoardQuoteBatchNumber: (data) => ({
    type: dashboardMap.SET_DASHBORD_QUOTE_BATCH_NUMBER,
    payload: data,
  }),
  refreshQuoteDashboard: () => ({
    type: dashboardMap.REFRESH_QUOTEPAGE_COUNT_DATA,
  }),
  searchTextChange: (data) => ({
    type: dashboardMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  filterDate: (data) => ({
    type: dashboardMap.FILTER_DATE,
    payload: data,
  }),

  //Contact Us Data
  getContactPageStart: (data) => ({
    type: dashboardMap.GET_CONTACTPAGE_COUNT_START,
    payload: data,
  }),
  getContactPageSuccess: (data) => ({
    type: dashboardMap.GET_CONTACTPAGE_COUNT_SUCCESS,
    payload: data,
  }),
  getContactPageError: (data) => ({
    type: dashboardMap.GET_CONTACTPAGE_COUNT_ERROR,
    payload: data,
  }),
  setDashBoardContactBatchNumber: (data) => ({
    type: dashboardMap.SET_DASHBORD_CONTACT_BATCH_NUMBER,
    payload: data,
  }),
  refreshContactDashboard: () => ({
    type: dashboardMap.REFRESH_CONTACTPAGE_COUNT_DATA,
  }),
  searchTextChangeContact: (data) => ({
    type: dashboardMap.SEARCH_TEXT_CONTACTPAGE_CHANGE,
    payload: data,
  }),
  filterDateContact: (data) => ({
    type: dashboardMap.FILTER_DATE_CONTACTPAGE,
    payload: data,
  }),
};
