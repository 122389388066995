import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { userActions } from "../redux";
import UserManagementTable from "./userDetail/addUserTable";
import { getUserAsync, useIsAccessAllow } from "../redux/userApi";

export function Users() {
  const { refreshSubUserList, searchText, isLoding } = useSelector(
    (state) => state.user,
    shallowEqual
  );
  const searchSubUserRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubUserModalShow = () => {
    history.push("/user/add-user");
  };

  const onSearchTextChange = (e) => {
    dispatch(userActions.searchTextChange(e.target.value));
  };
  useEffect(() => {
    if (refreshSubUserList) {
      dispatch(getUserAsync(searchText));
    }
  }, [refreshSubUserList, isLoding]);

  const isAddUserAllow = useIsAccessAllow("user.add_user");
  return (
    <>
      <Card>
        <CardHeader title="Sub User">
          <CardHeaderToolbar>
            {isAddUserAllow && (
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={() => handleSubUserModalShow()}
                >
                  Create User
                </button>
              </div>
            )}
            <form
              className="subheader_filter"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="form-row">
                <div className="form-group mr-3 mb-1">
                  <input
                    name="Search"
                    placeholder="Search. . ."
                    type="text"
                    className="form-control"
                    value={searchText}
                    autoComplete="off"
                    onChange={onSearchTextChange}
                    ref={searchSubUserRef}
                  />
                </div>
              </div>
            </form>
          </CardHeaderToolbar>
        </CardHeader>
        <UserManagementTable />
      </Card>
    </>
  );
}
