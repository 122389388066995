import React, { memo, useEffect, useMemo } from "react";
import { StatisticsWidget } from "./statisticWidget";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getMonthlyLeadsList,
  getCountryLeadsList,
  getGoogleAnalytics,
  getQuotePageCount,
  getContactUsPageCount,
  getGoogleAnalyticsForGraph,
} from "./redux/dashboardApi";
import LeadsCount from "./components/LeadsCount";
import GoogleAnalytics from "./components/GoogleAnalytics";
import PageCount from "./components/PageCount";
import DashboardChart from "./components/DashboardChart";
import KeyWordTracker from "./components/KeyWordTracker";

const MemoizedDonutChart = memo(DashboardChart);

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuotePageCount());
    dispatch(getContactUsPageCount());
    dispatch(getMonthlyLeadsList());
    dispatch(getCountryLeadsList());
    dispatch(getGoogleAnalytics());
  }, []);

  const {
    refreshMonthDashboard,
    refreshCountryDashboard,
    refreshQuotePageDataDashboard,
    refreshContactPageDataDashboard,
    refreshGoogleAnalyticsDashboard,
    refreshGoogleAnalyticsGraphDashboard,
    monthlyLeadsLists,
    countryLeadsList,
    QuotepageList,
    contactUsList,
    analyticsData,
  } = useSelector((leads) => leads.dashboard, shallowEqual);

  const leads = useMemo(() => {
    let leads = [];
    let leadsMonth = [];

    if (monthlyLeadsLists.total) {
      monthlyLeadsLists.data.forEach((monthlyLead) => {
        leads.push(monthlyLead.leadsCount);
        leadsMonth.push(monthlyLead.monthYear);
      });
    }
    const leadsOptions = ["all Months", ...leadsMonth.reverse()];
    return {
      leads: leads.reverse(),
      leadsMonth: leadsMonth.reverse(),
      leadsOptions,
    };
  }, [monthlyLeadsLists]);

  const anlyticsData = useMemo(() => {
    let anlyticsLabels = [];
    let anlyticsSeries = [];
    if (analyticsData?.length) {
      for (const data of analyticsData) {
        anlyticsLabels.push(data.dimensionValues[0].value);
        anlyticsSeries.push(data.metricValues[0].value);
      }
    }
    return { anlyticsLabels, anlyticsSeries };
  }, [analyticsData]);

  // const leadc = useMemo(() => {
  //   let leadc = [];
  //   let leadsCountry = [];
  //   if (countryLeadsList.length) {
  //     countryLeadsList.forEach((monthlyLead) => {
  //       leadc.push(monthlyLead.leadsCount);
  //       leadsCountry.push(
  //         monthlyLead.countryName ? monthlyLead.countryName : "No country"
  //       );
  //     });
  //   }
  //   return { leadc, leadsCountry };
  // }, [countryLeadsList]);

  useEffect(
    () => {
      if (refreshCountryDashboard) {
        dispatch(getCountryLeadsList());
      }
    },
    useMemo(() => [refreshCountryDashboard])
  );

  useEffect(
    () => {
      if (refreshMonthDashboard) {
        dispatch(getMonthlyLeadsList());
      }
    },
    useMemo(() => [refreshMonthDashboard])
  );

  useEffect(() => {
    if (refreshQuotePageDataDashboard) {
      dispatch(getQuotePageCount());
    }
  }, [refreshQuotePageDataDashboard]);

  useEffect(() => {
    if (refreshContactPageDataDashboard) {
      dispatch(getContactUsPageCount());
    }
  }, [refreshContactPageDataDashboard]);

  useEffect(() => {
    if (refreshGoogleAnalyticsDashboard) {
      dispatch(getGoogleAnalytics());
    }
  }, [refreshGoogleAnalyticsDashboard]);

  useEffect(() => {
    if (refreshGoogleAnalyticsGraphDashboard) {
      dispatch(getGoogleAnalyticsForGraph());
    }
  }, [refreshGoogleAnalyticsGraphDashboard]);

  return (
    <>
      <div>
        <StatisticsWidget />
        <div className="row  dashboardListDiv">
          <LeadsCount monthlyLeadsLists={monthlyLeadsLists} />
          <div className="dashboardDiv dashboardRightDiv">
            <div className="dashboardTableDiv">
              <MemoizedDonutChart
                selectOptions={leads.leadsOptions}
                series={[{ name: "lead", data: leads.leads }]}
                xAxis={leads.leadsMonth}
                height="380px"
                customOptions={{ legend: { show: false } }}
                graph={0}
                data="leadsMonth"
                // type={"bar"}
                text={"Leads"}
              />
            </div>
          </div>
        </div>

        <div className="row dashboardListDiv">
          <LeadsCount countryLeadsList={countryLeadsList} />
          <div className="dashboardDiv dashboardRightDiv">
            <div className="dashboardTableDiv">
              <KeyWordTracker />
            </div>
          </div>
        </div>
        <div className="row dashboardListDiv">
          <PageCount QuotepageList={QuotepageList} />
          <div className="dashboardDiv dashboardRightDiv">
            <div className="dashboardTableDiv">
              <PageCount contactUsList={contactUsList} />
            </div>
          </div>
        </div>
        <div className="row dashboardListDiv">
          <GoogleAnalytics analyticsData={analyticsData} />
          <div className="dashboardDiv dashboardRightDiv">
            <div className="dashboardTableDiv">
              <MemoizedDonutChart
                selectOptions={anlyticsData.anlyticsLabels}
                series={[
                  { name: "session", data: anlyticsData.anlyticsSeries },
                ]}
                xAxis={anlyticsData.anlyticsLabels}
                height="380px"
                customOptions={{ legend: { show: false } }}
                graph={0}
                data="google"
                // type={"bar"}
                // text={"Google Analytics Graph"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Dashboard);
