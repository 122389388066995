import React, { useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllCategoryAsync, CategoryActions } from "../redux";
import DubaiCategoryTable from "./categoryDetails/categoryTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useIsAccessAllow } from "../../users/redux";

export function DubaiCategory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchRef = useRef();
  const { refreshCategory, searchBy, searchText } = useSelector(
    (state) => state.category,
    shallowEqual
  );

  useEffect(() => {
    if (searchRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(CategoryActions.refreshCategoryList());
        }
      };
      let input = searchRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(CategoryActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshCategory) {
      dispatch(getAllCategoryAsync(searchBy, searchText));
    }
    // eslint-disable-next-line
  }, [refreshCategory]);

  const onSearchTextChange = (e) => {
    dispatch(CategoryActions.searchTextChange(e.target.value));
  };

  const onSelectCategory = (category) => {
    history.push(`/dubai_category/add-category`);
  };
  const isAddCategoryAllow = useIsAccessAllow("dubai_category.add_category");
  const isSearchAllow = useIsAccessAllow("dubai_category.list");
  return (
    <>
      <Card>
        <CardHeader title="Dubai Category">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  {isAddCategoryAllow && (
                    <div className="form-group mr-5 mb-1 mt-2">
                      <button
                        className="btn_new btn-sm"
                        type="button"
                        onClick={() => onSelectCategory()}
                      >
                        Add
                      </button>
                    </div>
                  )}
                  {isSearchAllow && (
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        autoComplete="off"
                        onChange={onSearchTextChange}
                        ref={searchRef}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <DubaiCategoryTable />
      </Card>
    </>
  );
}
