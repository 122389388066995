import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  StatisticsWidget1,
  DonutChart,
} from "../../../_metronic/_partials/widgets";
import { getTotalLeadsCount } from "./redux/dashboardApi";

export function StatisticsWidget() {
  const dispatch = useDispatch();

  const { totalLeadsCount } = useSelector(
    (leads) => leads.dashboard,
    shallowEqual
  );
  useEffect(() => {
    dispatch(getTotalLeadsCount());
  }, []);
  return (
    <div className="row">
      <div className="col-xl-3 pb-5">
        <StatisticsWidget1
          className="card-xl-stretch"
          svgIcon="/media/svg/icons/General/User.svg"
          color="white"
          iconColor="text-dark"
          title="Total Leads"
          description={
            totalLeadsCount.totalLeadsCount
              ? totalLeadsCount.totalLeadsCount
              : 0
          }
          redirectUrl={"/contact-us"}
        />
      </div>
      <div className="col-xl-3 pb-5">
        <StatisticsWidget1
          className="card-xl-stretch"
          svgIcon="/media/svg/icons/General/User.svg"
          color="white"
          iconColor="text-dark"
          title="Today Leads"
          description={
            totalLeadsCount.todayLeadCount ? totalLeadsCount.todayLeadCount : 0
          }
          redirectUrl={"/contact-us"}
        />
      </div>
    </div>
  );
}
