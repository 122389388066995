import axios from "axios";
import { WhatsAppAccountActions } from "./whatsAppAccountAction";
import { showSuccessSnackbar } from "../../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllAccountsAsync = (searchBy, searchText, type) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { skip, limit } = getState().whatsAppAccount;
      dispatch(WhatsAppAccountActions.getAllWaAccountsStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/whatsapp/accounts?skip=${skip}&limit=${limit}&column=${searchBy}&dir=&search=${searchText}`,
        headers: { "Content-Type": "application/json" },
      });
      if (data.code === 200) {
        return dispatch(
          WhatsAppAccountActions.getAllWaAccountsSuccess(data.data)
        );
      }
      dispatch(WhatsAppAccountActions.getAllWaAccountsError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      console.log("--------------------");
      console.log(error);
      dispatch(WhatsAppAccountActions.getAllWaAccountsError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching data....", 3000)
      );
    }
  };
};
