import { ContactUsMap } from "./contactUsAction";

const initialState = {
  isLoading: false,
  ContactList: {},
  refreshContactList: true,
  searchBy: "",
  countries: [],
  searchText: "",
  pageNameList: [],
  contactNameList: [], //created new variable for dashboard use
  skip: 0,
  limit: 10,
  selectedContact: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ContactUsMap.GET_ALL_CONTACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.GET_ALL_CONTACT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ContactList: action.payload,
        refreshContactList: false,
      };
    }
    case ContactUsMap.GET_ALL_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }

    case ContactUsMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
        refreshContactList: true,
      };
    }
    case ContactUsMap.SET_CONTACT_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshContactList: true,
      };
    }
    case ContactUsMap.GET_CONTACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.GET_CONTACT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ContactList: action.payload,
        refreshContactList: false,
      };
    }
    case ContactUsMap.GET_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }
    case ContactUsMap.GET_PAGE_NAME_LIST_SUCCESS: {
      return {
        ...state,
        pageNameList: action.payload,
        contactNameList: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
