import axios from "axios";
import { AuthorManagementActions } from "./authorManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllAuthorAsync = (searchBy, searchText) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      //let { _id } = getState().auth.user;
      let { skip, limit } = getState().author;
      dispatch(AuthorManagementActions.getAllAuthorStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaiauthor/getAllAuthor?skip=${skip}&limit=${limit}&column=${searchBy}&dir=&search=${searchText}`,
        headers: { "Content-Type": "application/json" },
      });
      if (data.code === 200) {
        return dispatch(AuthorManagementActions.getAllAuthorSuccess(data.data));
      }
      dispatch(AuthorManagementActions.getAllAuthorError());
      return dispatch(showSuccessSnackbar("success", data.message, 3000));
    } catch (error) {
      dispatch(AuthorManagementActions.getAllAuthorError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addAuthorAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      //let { _id } = getState().auth.user;
      dispatch(AuthorManagementActions.addAuthorStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaiauthor/createAuthor`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200 && data.status) {
        dispatch(AuthorManagementActions.addAuthorSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(AuthorManagementActions.addAuthorError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
      setSubmitting(false);
    } catch (error) {
      dispatch(AuthorManagementActions.addAuthorError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getAuthorDetailAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(AuthorManagementActions.getAuthorDetail());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaiauthor/getAuthorDetail/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          AuthorManagementActions.getAuthorDetailSuccess(data.data)
        );
      }
      dispatch(AuthorManagementActions.getAuthorDetailError());
      dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(AuthorManagementActions.getAuthorDetailError());
      dispatch(
        showSuccessSnackbar("error", "Error while fetching Data.", 3000)
      );
    }
  };
};

export const updateAuthorAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      //let { _id } = getState().auth.user;
      let { selectedAuthor } = getState().author;
      dispatch(AuthorManagementActions.updateAuthorStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaiauthor/updateAuthor/${selectedAuthor._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200 && data.status) {
        dispatch(AuthorManagementActions.updateAuthorSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(AuthorManagementActions.updateAuthorError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(AuthorManagementActions.updateAuthorError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteAuthorAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      // let { _id } = getState().auth.user;
      dispatch(AuthorManagementActions.deleteAuthorStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaiauthor/remove/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(AuthorManagementActions.deleteAuthorSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(AuthorManagementActions.deleteAuthorError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(AuthorManagementActions.deleteAuthorError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const authorListAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(AuthorManagementActions.getAuthorListStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/hireaiauthor/authorList`,
        method: "Get",
      });
      if (data.code === 200) {
        dispatch(AuthorManagementActions.getAuthorListSuccess(data.data));
        // return dispatch(showSuccessSnackbar("success", data.message, 3000));
      } else {
        dispatch(AuthorManagementActions.getAuthorListError());
        return dispatch(showSuccessSnackbar("error", data.message, 3000));
      }
    } catch (error) {
      console.log("Error: ", error);
      dispatch(AuthorManagementActions.getAuthorListError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating.Please try again after sometime..",
          3000
        )
      );
    }
  };
};
