import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getKeyWordRank } from "../redux";

const KeyWordTracker = () => {
  const { keyWordRankerList, refreshKeywordranker } = useSelector(
    (leads) => leads.dashboard,
    shallowEqual
  );

  const dispatch = useDispatch();

  const [country, setCountry] = useState();

  const [key, setKey] = useState("All");

  const options = [
    { label: "All", value: "All" },
    { label: "US", value: "US" },
    { label: "UK", value: "GB" },
    { label: "UAE", value: "AE" },
  ];
  const handleSelectChangeTable = (e) => {
    setCountry(e.value);
  };
  const handleInputChange = (e) => {
    setKey(e.target.value);
  };

  const onSearch = () => {
    let selectedCountry;
    if (!country || country == "All") {
      selectedCountry = ["US", "GB", "AE"];
    } else {
      selectedCountry = [country];
    }

    if (key !== "" && key.length) {
      dispatch(getKeyWordRank(key, selectedCountry));
    }
  };

  return (
    <div className="dashboardTableDiv">
      <h2 className="p-3">KeyWord Tracker</h2>
      <div className="page_type">
        <input
          className="form-control re_inputRouded ml-4"
          type="text"
          placeholder="Enter key word"
          onChange={handleInputChange}
        />
        <Select
          className="select-wrap select-dashboard-google-chart "
          defaultValue={options[0]}
          classNamePrefix="react-select"
          placeholder="Select Country"
          // className={`form-control border-0 p-0 `}
          options={options}
          onChange={handleSelectChangeTable}
        />
        <div className="form-group mr-5 mb-1 mt-2">
          <button
            className="btn_new btn-sm"
            type="button"
            onClick={() => onSearch()}
          >
            Search
          </button>
        </div>
      </div>
      <Table hover responsive className="">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Country</th>
            <th>Origin</th>
            <th>Rank</th>
          </tr>
        </thead>
        <tbody>
          {keyWordRankerList?.length > 0 ? (
            keyWordRankerList?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {item.country === "GB"
                    ? "UK"
                    : item.country === "AE"
                    ? "UAE"
                    : item.country}
                </td>
                <td>{item.origin}</td>
                <td>
                  <span className="f-w-500">{item.rank}</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center">
                Please select a country
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default KeyWordTracker;
