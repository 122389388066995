import { TagMap } from './tagAction';

const initialState = {
    isLoading: false,
    tagList: [],
    tags: [],
    refreshTag: true,
    selectedTag: {},
    reDirect: false,
    searchBy: "",
    searchText: "",
    skip: 0,
    limit: 10,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TagMap.CREATE_TAG_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case TagMap.CREATE_TAG_SUCCESS: {
            return {
                ...state,
                refreshTag: true,
                reDirect: true
            }
        }
        case TagMap.CREATE_TAG_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case TagMap.GET_ALL_TAG_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case TagMap.GET_ALL_TAG_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshTag: false,
                tagList: action.payload
            }
        }
        case TagMap.GET_ALL_TAG_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshTag: false
            }
        }
        case TagMap.UPDATE_TAG_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case TagMap.UPDATE_TAG_SUCCESS: {

            return {
                ...state,
                refreshTag: true,
                selectedTag: {
                    ...state.selectedTag,
                    ...action.payload
                },
                reDirect: true
            }
        }
        case TagMap.UPDATE_TAG_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case TagMap.DELETE_TAG_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case TagMap.DELETE_TAG_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshTag: true
            }
        }
        case TagMap.DELETE_TAG_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case TagMap.SEARCH_TEXT_CHANGE: {
            return {
                ...state,
                searchText: action.payload,
            };
        }
        case TagMap.REFRESH_TAG_LIST: {
            return {
                ...state,
                skip: 0,
                refreshTag: true,
            };
        }
        case TagMap.GET_TAG_DETAIL_START: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TagMap.GET_TAG_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                selectedTag: action.payload,
                refreshTag: false,
            };
        }
        case TagMap.GET_TAG_DETAIL_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshTag: false,
            };
        }
        case TagMap.GET_TAG_LIST_START: {
            return {
                ...state,
            };
        }
        case TagMap.GET_TAG_LIST_SUCCESS: {
            return {
                ...state,
                tags: action.payload,
            };
        }
        case TagMap.GET_TAG_LIST_ERROR: {
            return {
                ...state,
            };
        }
        case TagMap.SET_TAG_BATCH_NUMBER: {
            return {
                ...state,
                skip: action.payload,
                refreshTag: true,
            };
        }
        default: return { ...state }
    }
}