export const MailSubscriberMap = {
  GET_ALL_EMAIL_START: "GET_ALL_EMAIL_START",
  GET_ALL_EMAIL_SUCCESS: "GET_ALL_EMAIL_SUCCESS",
  GET_ALL_EMAIL_ERROR: "GET_ALL_EMAIL_ERROR",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_EMAIL_LIST: "REFRESH_EMAIL_LIST",
  SET_EMAIL_BATCH_NUMBER: "SET_EMAIL_BATCH_NUMBER",
  SELECT_EMAIL_DETAILS: "SELECT_EMAIL_DETAILS",
};

export const MailSubscriberActions = {
  getAllEmailStart: (data) => ({
    type: MailSubscriberMap.GET_ALL_EMAIL_START,
    payload: data,
  }),
  getAllEmailSuccess: (data) => ({
    type: MailSubscriberMap.GET_ALL_EMAIL_SUCCESS,
    payload: data,
  }),
  getAllEmailError: (errors) => ({
    type: MailSubscriberMap.GET_ALL_EMAIL_ERROR,
    payload: { errors },
  }),
  refreshEmailList: () => ({
    type: MailSubscriberMap.REFRESH_EMAIL_LIST,
  }),
  searchTextChange: (data) => ({
    type: MailSubscriberMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedEmail: (data) => ({
    type: MailSubscriberMap.SELECT_EMAIL_DETAILS,
    payload: data,
  }),
  setEmailBatchNumber: (data) => ({
    type: MailSubscriberMap.SET_EMAIL_BATCH_NUMBER,
    payload: data,
  }),
};
