import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BasicPagination from "../../pagination/BasicPagination";
import { dashboardActions } from "../redux";

const LeadsCount = ({ monthlyLeadsLists, countryLeadsList }) => {
  const { skip, limit, skipCountries, limitCountries } = useSelector(
    (state) => state.dashboard,
    shallowEqual
  );

  const [showMonthly, setShowMonthly] = useState(false);
  const [monthlyDataLength, setMonthlyDataLength] = useState();
  const [countryDataLength, setCountryDataLength] = useState();
  const [showCountry, setShowCountry] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (monthlyLeadsLists?.total > 5) setShowMonthly(true);
    else setShowMonthly(false);
    setMonthlyDataLength(limit * skip + monthlyLeadsLists?.data?.length);
  }, [monthlyLeadsLists]);

  useEffect(() => {
    if (countryLeadsList?.total > 5) {
      setShowCountry(true);
    } else setShowCountry(false);
    // setCountryDataLength(5);
    setCountryDataLength(
      limitCountries * skipCountries + countryLeadsList?.data?.length
    );
  }, [countryLeadsList]);

  const onPageChangeMonth = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;

    dispatch(dashboardActions.setDashBoardMonthBatchNumber(count));
  };

  const onPageChangeCountry = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skipCountries;

    dispatch(dashboardActions.setDashBoardCountryBatchNumber(count));
  };

  return (
    <div className=" dashboardDiv dashboardTableDiv">
      {monthlyLeadsLists ? (
        <>
          <h2 className="p-3">Monthly Leads</h2>
          <div className="form-group ml-3 mb-2">
            <Table hover responsive className="">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Month</th>
                  <th>Leads</th>
                </tr>
              </thead>
              <tbody>
                {monthlyLeadsLists?.data ? (
                  monthlyLeadsLists.data?.map((monthlyLead, index) => (
                    <tr key={index}>
                      <td>{skip * limit + (index + 1)}</td>
                      <td>
                        {monthlyLead.monthYear
                          ? monthlyLead.monthYear
                          : monthlyLead.countryName != "" &&
                            monthlyLead.countryName !== null
                          ? monthlyLead.countryName
                          : "No country"}
                      </td>
                      <td>
                        <span className="f-w-500">
                          {monthlyLead.leadsCount}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {showMonthly && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={monthlyLeadsLists?.total} //
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChangeMonth}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {monthlyDataLength} of{" "}
                {monthlyLeadsLists?.total} Records
              </div>
            </div>
          )}
        </>
      ) : countryLeadsList ? (
        <>
          <h2 className="p-3">Country Leads</h2>
          <div className="form-group ml-3 mb-2">
            <Table hover responsive className="">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Country (IP)</th>
                  <th>Leads</th>
                </tr>
              </thead>
              <tbody>
                {countryLeadsList.data ? (
                  countryLeadsList.data?.map((monthlyLead, index) => (
                    <tr key={index}>
                      <td>{skipCountries * limitCountries + (index + 1)}</td>
                      <td>
                        {monthlyLead.monthYear
                          ? monthlyLead.monthYear
                          : monthlyLead.countryName != "" &&
                            monthlyLead.countryName !== null
                          ? monthlyLead.countryName
                          : "No country"}
                      </td>
                      <td>
                        <span className="f-w-500">
                          {monthlyLead.leadsCount}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {showCountry && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={countryLeadsList?.total} //
                  limit={limitCountries}
                  batch={skipCountries + 1}
                  onBatchChange={onPageChangeCountry}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skipCountries * limitCountries + 1} -{" "}
                {countryDataLength} of {countryLeadsList?.total} Records
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default LeadsCount;
