import { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dashboardActions, getGoogleAnalytics } from "../redux";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import { FiFilter } from "react-icons/fi";
import { LuFilterX } from "react-icons/lu";

const GoogleAnalytics = ({ analyticsData }) => {
  const [toErrAnalytics, setToErrAnalytics] = useState(false);
  const [fromErrAnalytics, setFromErrAnalytics] = useState(false);
  const [toDateAnalytics, setToDateAnalytics] = useState();
  const [fromDateAnalytics, setFromDateAnalytics] = useState();
  const [removeFilter, setRemoveFilter] = useState(true);
  const [filter, setFilter] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (analyticsData?.length > 100) {
      setFromErrAnalytics(false);
      setFromErrAnalytics(true);
    } else {
      setFromErrAnalytics(false);
    }
  }, [analyticsData]);

  const filterByDate = () =>
    dispatch(
      dashboardActions.filterDateAnalytics({
        toDateAnalytics: toDateAnalytics ? new Date(toDateAnalytics) : "",
        fromDateAnalytics: fromDateAnalytics ? new Date(fromDateAnalytics) : "",
      })
    );

  const handleToDateChangeAnalytics = (date) => {
    if (!date) {
      setToDateAnalytics(null);
      filterByDate();
    } else {
      setToDateAnalytics(date);
    }
    setToErrAnalytics(false);
    setFromErrAnalytics(false);
  };

  const handleFromDateChangeAnalytics = (date) => {
    if (!date) {
      setFromDateAnalytics(null);
      filterByDate();
    } else {
      setFromDateAnalytics(date);
    }

    setToErrAnalytics(false);

    setFromErrAnalytics(false);

    if (fromDateAnalytics && date <= toDateAnalytics) {
      setFromErrAnalytics(true);
    }
  };
  const handleFilterDateAnalytics = () => {
    if (toDateAnalytics || fromDateAnalytics) {
      filterByDate();
    } else {
      setToErrAnalytics(true);
      setFromErrAnalytics(true);
    }
  };

  const handleFilter = () => {
    setRemoveFilter(false);
    setFilter(true);
  };

  const handleClearFilter = () => {
    setRemoveFilter(true);
    if (filter) setFilter(false);
    else setFilter(true);
    dispatch(
      dashboardActions.filterDateAnalytics({
        toDateAnalytics: "",
        fromDateAnalytics: "",
      })
    );
  };

  return (
    <div className=" dashboardDiv dashboardTableDiv">
      <h2 className="p-3">Google Analytics</h2>
      {removeFilter ? (
        <FiFilter
          title="Advance Filter"
          className="ml-2"
          size="2em"
          type="button"
          onClick={handleFilter}
        />
      ) : (
        <LuFilterX
          title="Clear Filter"
          className="ml-2"
          size="2em"
          type="button"
          onClick={handleClearFilter}
        />
      )}
      <>
        {filter && (
          <div className="date-picker-container pt-5 ">
            <DatePicker
              placeholderText="Select to date"
              autoComplete="off"
              dateFormat="dd/MM/yyyy"
              selected={toDateAnalytics}
              onChange={handleToDateChangeAnalytics}
              name="createdDate"
              className={
                toErrAnalytics
                  ? "form-control re_inputRouded is-invalid date-picker ml-10"
                  : "form-control re_inputRouded date-picker ml-2"
              }
            />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Select from date"
              autoComplete="off"
              selected={fromDateAnalytics}
              onChange={handleFromDateChangeAnalytics}
              name="createdDate"
              className={
                fromErrAnalytics
                  ? "form-control re_inputRouded is-invalid date-picker ml-10"
                  : "form-control re_inputRouded date-picker"
              }
            />
            <button
              type="submit"
              onClick={handleFilterDateAnalytics}
              className="search-button"
            >
              Search
            </button>
          </div>
        )}
      </>

      <Table hover responsive className="">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Session default channel group</th>
            <th>Sessions</th>
            <th>Users</th>
            <th>Engaged sessions</th>
            <th>Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {analyticsData?.length ? (
            analyticsData?.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.dimensionValues[0].value}</td>
                <td>
                  <span className="f-w-500">{data.metricValues[0]?.value}</span>
                </td>
                <td>
                  <span className="f-w-500">{data.metricValues[1]?.value}</span>
                </td>
                <td>
                  <span className="f-w-500">{data.metricValues[2]?.value}</span>
                </td>
                <td>
                  <span className="f-w-500">{data.metricValues[3]?.value}</span>
                </td>
                <td>
                  <span className="f-w-500">{data.metricValues[4]?.value}</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center">
                No Record Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default GoogleAnalytics;
