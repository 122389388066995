import React, { useEffect, useRef, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllBlogAsync, HireAIBlogActions } from "../redux";
import BlogTable from "./blogDetail/blogTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { HireAiBlogStatus } from "../constants/constants";
import { useIsAccessAllow } from "../../users/redux";

const statusOptionList = [
  { status: "Draft", value: HireAiBlogStatus.DRAFT },
  { status: "Published", value: HireAiBlogStatus.PUBLISHED },
  { status: "Pending Review", value: HireAiBlogStatus.PENDING_REVIEW },
  { status: "Future", value: HireAiBlogStatus.FUTURE },
  { status: "Auto Draft", value: HireAiBlogStatus.AUTO_DRAFT },
];

export function HireAIBlog() {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchRef = useRef();
  const { refreshBlog, searchBy, searchText, status } = useSelector(
    (state) => state.blog,
    shallowEqual
  );

  useEffect(() => {
    if (searchRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(HireAIBlogActions.refreshBlogList());
        }
      };
      let input = searchRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(HireAIBlogActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshBlog) {
      dispatch(getAllBlogAsync(searchBy, searchText, status));
    }
    // eslint-disable-next-line
  }, [refreshBlog]);

  const onSearchTextChange = (e) => {
    dispatch(HireAIBlogActions.searchTextChange(e.target.value));
  };

  const onChangeStatus = (e) => {
    dispatch(HireAIBlogActions.searchChangeStatus(e.target.value));
    dispatch(HireAIBlogActions.refreshBlogList());
  };

  const onSelectBlog = (blog) => {
    history.push(`/hire_ai_blog/add-blog`);
  };
  const isAddBlogAllow = useIsAccessAllow("hire_ai_blog.add_blog");
  const isListAllow = useIsAccessAllow("hire_ai_blog.list");

  return (
    <>
      <Card>
        <CardHeader title="Hire AI Blog">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  {isAddBlogAllow && (
                    <div className="form-group mr-5 mb-1 mt-2">
                      <button
                        className="btn_new btn-sm"
                        type="button"
                        onClick={() => onSelectBlog()}
                      >
                        Add
                      </button>
                    </div>
                  )}
                  {isListAllow && (
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        autoComplete="off"
                        onChange={onSearchTextChange}
                        ref={searchRef}
                      />
                    </div>
                  )}
                  {isListAllow && (
                    <div className="form-group mr-3 mb-1">
                      <select
                        className="form-control"
                        onChange={onChangeStatus}
                      >
                        <option value="" selected disabled hidden>
                          Select status
                        </option>
                        {statusOptionList?.map((statusdata) => (
                          <option value={statusdata.value}>
                            {statusdata.status}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <BlogTable />
      </Card>
    </>
  );
}
