import React, { useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  getAllGenAIAsync,
  generativeAIAction,
} from "../redux";
import GenerativeAITable from "./generativaAI_detail/generativaAITable";
import Select from "react-select";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIsAccessAllow } from "../../users/redux";

export function GenerativeAIData() {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchContactRef = useRef();
  const { pageType: currentPageType } = useParams();
  let type = currentPageType ? currentPageType : "all";
  const {
    refreshGenAIList,
    searchBy,
    searchText,
  } = useSelector((state) => state.genAIData, shallowEqual);


  useEffect(() => {
    if (searchContactRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(generativeAIAction.refreshGenerativeAIList());
        }
      };
      let input = searchContactRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(generativeAIAction.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshGenAIList) {
      dispatch(getAllGenAIAsync(searchBy, searchText, type));
    }
    // eslint-disable-next-line
  }, [refreshGenAIList]);

  const onSearchTextChange = (e) => {
    dispatch(generativeAIAction.searchTextChange(e.target.value));
  };
  // const changePageType = (e) => {
  //   setPageType(e.value);
  //   if (pageType == "all") {
  //     history.push("/quote");
  //   } else {
  //     history.push("/quote/" + e.value);
  //   }
  //   dispatch(generativeAIAction.searchTextChange(""));
  // };
  const isSearchAllow = useIsAccessAllow("get_a_quote.list");

  return (
    <>
      <Card>
        <CardHeader title="Quote">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  {/* <div
                    className="form-group mr-3 mb-1 p-0"
                    style={{ width: "300px", marginRight: "50px" }}
                  >
                    <Select
                      classNamePrefix="react-select"
                      placeholder="Select page type"
                      className={`form-control border-0 p-0 `}
                      options={pageNameList}
                      onChange={changePageType}
                      defaultValue={defaultPageType}
                      theme={(theme) => ({
                        ...theme,
                        spacing: {
                          ...theme.spacing,
                          controlHeight: 50,
                        },
                      })}
                    />
                  </div> */}
                  {isSearchAllow && (
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        autoComplete="off"
                        onChange={onSearchTextChange}
                        ref={searchContactRef}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <GenerativeAITable />
      </Card>
    </>
  );
}
