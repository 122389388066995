export const AuthorManagementMap = {
  GET_ALL_AUTHOR_START: "GET_ALL_AUTHOR_START",
  GET_ALL_AUTHOR_SUCCESS: "GET_ALL_AUTHOR_SUCCESS",
  GET_ALL_AUTHOR_ERROR: "GET_ALL_AUTHOR_ERROR",
  ADD_AUTHOR_START: "ADD_AUTHOR_START",
  ADD_AUTHOR_SUCCESS: "ADD_AUTHOR_SUCCESS",
  ADD_AUTHOR_ERROR: "ADD_AUTHOR_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_AUTHOR_LIST: "REFRESH_AUTHOR_LIST",
  SET_AUTHOR_BATCH_NUMBER: "SET_AUTHOR_BATCH_NUMBER",
  SELECT_AUTHOR_DETAILS: "SELECT_AUTHOR_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_AUTHOR_START: "UPDATE_AUTHOR_START",
  UPDATE_AUTHOR_SUCCESS: "UPDATE_AUTHOR_SUCCESS",
  UPDATE_AUTHOR_ERROR: "UPDATE_AUTHOR_ERROR",
  DELETE_AUTHOR_START: "DELETE_AUTHOR_START",
  DELETE_AUTHOR_SUCCESS: "DELETE_AUTHOR_SUCCESS",
  DELETE_AUTHOR_ERROR: "DELETE_AUTHOR_ERROR",
  GET_AUTHOR_DETAIL_START: "GET_AUTHOR_DETAIL_START",
  GET_AUTHOR_DETAIL_SUCCESS: "GET_AUTHOR_DETAIL_SUCCESS",
  GET_AUTHOR_DETAIL_ERROR: "GET_AUTHOR_DETAIL_ERROR",
  GET_AUTHOR_LIST_START: "GET_AUTHOR_LIST_START",
  GET_AUTHOR_LIST_SUCCESS: "GET_AUTHOR_LIST_SUCCESS",
  GET_AUTHOR_LIST_ERROR: "GET_AUTHOR_LIST_ERROR",
};

export const AuthorManagementActions = {
  getAllAuthorStart: (data) => ({
    type: AuthorManagementMap.GET_ALL_AUTHOR_START,
    payload: data,
  }),
  getAllAuthorSuccess: (data) => ({
    type: AuthorManagementMap.GET_ALL_AUTHOR_SUCCESS,
    payload: data,
  }),
  getAllAuthorError: (errors) => ({
    type: AuthorManagementMap.GET_ALL_AUTHOR_ERROR,
    payload: { errors },
  }),
  deleteAuthorStart: (data) => ({
    type: AuthorManagementMap.DELETE_AUTHOR_START,
    payload: data,
  }),
  deleteAuthorSuccess: (data) => ({
    type: AuthorManagementMap.DELETE_AUTHOR_SUCCESS,
    payload: data,
  }),
  deleteAuthorError: (errors) => ({
    type: AuthorManagementMap.DELETE_AUTHOR_ERROR,
    payload: { errors },
  }),
  addAuthorStart: (data) => ({
    type: AuthorManagementMap.ADD_AUTHOR_START,
    payload: data,
  }),
  addAuthorSuccess: (data) => ({
    type: AuthorManagementMap.ADD_AUTHOR_SUCCESS,
    payload: data,
  }),
  addAuthorError: (errors) => ({
    type: AuthorManagementMap.ADD_AUTHOR_ERROR,
    payload: { errors },
  }),
  updateAuthorStart: (data) => ({
    type: AuthorManagementMap.UPDATE_AUTHOR_START,
    payload: data,
  }),
  updateAuthorSuccess: (data) => ({
    type: AuthorManagementMap.UPDATE_AUTHOR_SUCCESS,
    payload: data,
  }),
  updateAuthorError: (errors) => ({
    type: AuthorManagementMap.UPDATE_AUTHOR_ERROR,
    payload: { errors },
  }),
  getAuthorDetail: (data) => ({
    type: AuthorManagementMap.GET_AUTHOR_DETAIL_START,
    payload: data,
  }),
  getAuthorDetailSuccess: (data) => ({
    type: AuthorManagementMap.GET_AUTHOR_DETAIL_SUCCESS,
    payload: data,
  }),
  getAuthorDetailError: () => ({
    type: AuthorManagementMap.GET_AUTHOR_DETAIL_ERROR,
  }),

  getAuthorListStart: (data) => ({
    type: AuthorManagementMap.GET_AUTHOR_LIST_START,
    payload: data,
  }),
  getAuthorListSuccess: (data) => ({
    type: AuthorManagementMap.GET_AUTHOR_LIST_SUCCESS,
    payload: data,
  }),
  getAuthorListError: () => ({
    type: AuthorManagementMap.GET_AUTHOR_LIST_ERROR,
  }),
  applyFilters: (data) => ({
    type: AuthorManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshAuthorList: () => ({ type: AuthorManagementMap.REFRESH_AUTHOR_LIST }),
  searchTextChange: (data) => ({
    type: AuthorManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedAuthor: (data) => ({
    type: AuthorManagementMap.SELECT_AUTHOR_DETAILS,
    payload: data,
  }),
  setAuthorBatchNumber: (data) => ({
    type: AuthorManagementMap.SET_AUTHOR_BATCH_NUMBER,
    payload: data,
  }),
};
