import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { WhatsAppAccountActions } from "../../redux";
import BasicPagination from "../../../../pagination/BasicPagination";

const WhatsAppAccountTable = () => {
  const { isLoading, accountList, skip, limit } = useSelector(
    (state) => state.whatsAppAccount,
    shallowEqual
  );

  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(WhatsAppAccountActions.setAccountBatchNumber(count));
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Created On</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {accountList?.records && accountList?.records?.length ? (
                  accountList.records.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <span className="f-w-500">
                          {skip * limit + (index + 1)}
                        </span>
                      </td>

                      <td>
                        <span className="f-w-500">{row.verifiedName}</span>
                      </td>
                      <td>
                        <span className="f-w-500">
                          {row.displayPhoneNumber}
                        </span>
                      </td>

                      <td>
                        <span className="f-w-500">
                          {new Date(row.createdOn).toLocaleString()}
                        </span>
                      </td>

                      <td>
                        <span className="f-w-500">{row.status}</span>
                      </td>
                      <td>
                        <button>
                          <i className="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={accountList?.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {accountList?.recordsTotal} Records
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppAccountTable;
