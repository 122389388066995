import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import BasicPagination from "../../../pagination/BasicPagination";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CircularProgress } from "@material-ui/core";
import { userActions } from "../../redux";
import {
  deleteUserAsync,
  getUserAsync,
  useIsAccessAllow,
} from "../../redux/userApi";

const UserManagementTable = () => {
  const {
    subUserList,
    subUserSkip,
    subUserLimit,
    isSubUserLoading,
    isLoading,
    searchText,
  } = useSelector((state) => state.user, shallowEqual);
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const [flag, setFlag] = useState(false);
  // const deleteShow = () => setDeleteModal(true);
  const [selectedSubUser, setselectedSubUser] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : subUserSkip;
    dispatch(userActions.setSubUserBatchNumber(count));
  };
  useEffect(() => {
    setFlag(false);
    dispatch(getUserAsync(searchText));
  }, [flag]);
  const onEditClick = (subUser) => {
    dispatch(userActions.setSelectedSubUser(subUser));
    history.push(`/user/edit-user/${subUser?._id}`);
  };
  // const onDeleteClick = (subUser) => {
  //   setselectedSubUser(subUser);
  //   deleteShow();
  // };
  const onDeleteClick = (data) => {
    if (window.confirm("Are you sure you want to delete user?")) {
      dispatch(deleteUserAsync(data?._id));
      //dispatch(getUserAsync(searchText));
      setFlag(true);
    }
  };
  // const onDeactiveClick = () => {
  //   dispatch(deleteUserAsync(selectedSubUser._id));
  //   setselectedSubUser({});
  //   deleteClose();
  // };

  const isDeteleAllow = useIsAccessAllow("user.delete");
  const isEditUserAllow = useIsAccessAllow("user.edit_user");
  return (
    <>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <div>
              <Table
                hover
                responsive
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th width="60px">Sr No.</th>
                    {/* <th>Profile</th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Status</th>
                    <th width="120px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subUserList.records && subUserList.records.length ? (
                    subUserList.records.map((subUser, index) => (
                      <tr key={index}>
                        <td>
                          <span className="f-w-500">
                            {subUserSkip * subUserLimit + (index + 1)}
                          </span>
                        </td>
                        {/* <td>
                          <span>
                            <img
                              className="clubImage"
                              src={subUser.profilePicture}
                              alt="profilePicture"
                            ></img>
                          </span>
                        </td> */}
                        <td>
                          <span className="f-w-500">{subUser.name}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{subUser.email}</span>
                        </td>
                        <td>
                          <span className="f-w-500">
                            {subUser.dailCode ? subUser.dailCode : ""}
                            {subUser.contactNumber ? subUser.contactNumber : ""}
                          </span>
                        </td>
                        <td>
                          <span className="f-w-500">{subUser.status}</span>
                        </td>

                        <td>
                          {isEditUserAllow && (
                            <button
                              title="Edit"
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              onClick={() => onEditClick(subUser)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Write.svg"
                                  )}
                                />
                              </span>
                            </button>
                          )}
                          {isDeteleAllow && (
                            <button
                              title="Delete"
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => onDeleteClick(subUser)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </button>
                          )}
                          {/* <a
                          title="Delete"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => onDeleteClick(subUser)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </a> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                  {isSubUserLoading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        <div className="basic-verification-loader text-center">
                          {<CircularProgress />}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {!isLoading && (
              <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
                <div className="col-md-8 text-right mb-5 aspgntn">
                  <BasicPagination
                    totalRecords={subUserList && subUserList.recordsTotal}
                    limit={subUserLimit}
                    batch={subUserSkip + 1}
                    onBatchChange={onPageChange}
                  />
                </div>
                <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                  Displaying {subUserSkip * subUserLimit + 1} -{" "}
                  {subUserSkip * subUserLimit + subUserLimit} of{" "}
                  {subUserList && subUserList.recordsTotal} Records
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <Modal
          show={DeleteModal}
          onHide={deleteClose}
          centered
          className="def_modal delete_modal"
        >
          <Modal.Header>
            <Modal.Title className="fn_s_18 color_blk f-w-700">
              Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form def_form frmwtpddng">
              <p className="fn_s_18 f-w-400 text-center mb-10">
                Are you sure want you to delete?
              </p>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <button
                  type="button"
                  className="btn btn-def btn_pdng mr-3"
                  onClick={deleteClose}
                >
                  Keep
                </button>
                <button
                  type="button"
                  className="btn btn-delete btn_pdng"
                  onClick={onDeactiveClick}
                >
                  Yes, Delete
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};

export default UserManagementTable;
