export const EnvironmentMap = {
    GET_ENVIRONMENTS_START: 'GET_ENVIRONMENTS_START',
    GET_ENVIRONMENTS_SUCCESS: 'GET_ENVIRONMENTS_SUCCESS',
    GET_ENVIRONMENTS_ERROR: 'GET_ENVIRONMENTS_ERROR',
    GET_ENVIRONMENTS_WEBSITE_LIST_SUCCESS: 'GET_ENVIRONMENTS_WEBSITE_LIST_SUCCESS',
    GET_ENVIRONMENTS_WEB_ID:'GET_ENVIRONMENTS_WEB_ID'
}

export const EnvironmentActions = {
    getEnvironments: () => ({ type: EnvironmentMap.GET_ENVIRONMENTS_START }),
    getEnvironmentsSuccess: (data) => ({ type: EnvironmentMap.GET_ENVIRONMENTS_SUCCESS, payload: data }),
    getEnvironmentsError: () => ({ type: EnvironmentMap.GET_ENVIRONMENTS_ERROR }),
    getEnvironmentsWebsiteListSuccess: (data) => ({ type: EnvironmentMap.GET_ENVIRONMENTS_WEBSITE_LIST_SUCCESS, payload: data }),
    updateEnvironmentsWebId: (data) => ({ type: EnvironmentMap.GET_ENVIRONMENTS_WEB_ID, payload: data }),
}