import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/redux/authReducer";
import * as environnment from "../app/modules/GetEnvironment/getEnvironmentReducer";
import * as profile from "../app/modules/profile_settings/redux";
import snackBarReducer from "../app/modules/snackBar/snackbarReducer";
import * as contactUs from "../app/modules/contact_us/redux";
import * as quoteData from "../app/modules/quote/redux";
import * as genAIData from "../app/modules/generativeAI/redux";
import * as tag from "../app/modules/tag/redux";
import * as author from "../app/modules/AuthorManagement/redux";
import * as blog from "../app/modules/blog/redux";
import * as dubai_blog from "../app/modules/dubai_blog/redux";
import * as category from "../app/modules/Category/redux";
import * as emailManagement from "../app/modules/emailManagement/redux";
import * as mailSubscriber from "../app/modules/mailSubscriber/redux";
import * as whatsAppAccount from "../app/modules/whatsApp/accounts/redux";
import * as dashboard from "../app/modules/dashboards/redux";
import * as user from "../app/modules/users/redux";
import * as pageSpeedScore from "../app/modules/pageSpeedScore/redux";

export const rootReducer = combineReducers({
  snackBar: snackBarReducer,
  environnment: environnment.reducer,
  auth: auth.reducer,
  profile: profile.reducer,
  contactUs: contactUs.reducer,
  quoteData: quoteData.reducer,
  genAIData: genAIData.reducer,
  tag: tag.reducer,
  blog: blog.reducer,
  dubai_blog: dubai_blog.reducer,
  author: author.reducer,
  category: category.reducer,
  emailManagement: emailManagement.reducer,
  mailSubscriber: mailSubscriber.reducer,
  whatsAppAccount: whatsAppAccount.reducer,
  dashboard: dashboard.reducer,
  user: user.reducer,
  pageSpeedScore: pageSpeedScore.reducer,
});
