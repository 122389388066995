import React, { useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllContactUsAsync, contactUsAction } from "../redux";
import ContactUsTable from "./careers_detail/careersTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIsAccessAllow } from "../../users/redux";

export function CareersData() {
  const dispatch = useDispatch();
  const searchContactRef = useRef();
  const type = "CONTACT";
  const { refreshContactList, searchBy, searchText } = useSelector(
    (state) => state.contactUs,
    shallowEqual
  );

  useEffect(() => {
    if (refreshContactList) {
      dispatch(getAllContactUsAsync(searchBy, searchText, type));
    }
    // eslint-disable-next-line
  }, [refreshContactList]);

  const onSearchTextChange = (e) => {
    dispatch(contactUsAction.searchTextChange(e.target.value));
  };
  const isSearchAllow = useIsAccessAllow("careers.list");

  return (
    <>
      <Card>
        <CardHeader title="Careers">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  {isSearchAllow && (
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        autoComplete="off"
                        onChange={onSearchTextChange}
                        ref={searchContactRef}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <ContactUsTable />
      </Card>
    </>
  );
}
