import { pageSpeedScoreMap } from './pageSpeedScoreAction'

const initialState = {
    isLoading : false,
    pageSpeedScore : {}
}

export const reducer = (state = initialState, action) => {
    switch (action.type){
        case pageSpeedScoreMap.GET_PAGE_SPEED_SCORE_START:{
            return {
                ...state,
                isLoading:true
            }
        };
        case pageSpeedScoreMap.GET_PAGE_SPEED_SCORE_ERROR:{
            return {
                ...state,
                isLoading:false
            }
        };
        case pageSpeedScoreMap.GET_PAGE_SPEED_SCORE_SUCCESS:{
            return {
                ...state,
                isLoading:false,
                pageSpeedScore:action.payload
            }
        };
        default:{
            return {...state}
        }
    }
}