export const pageSpeedScoreMap = {
    GET_PAGE_SPEED_SCORE_START : "GET_PAGE_SPEED_SCORE_START",
    GET_PAGE_SPEED_SCORE_SUCCESS : "GET_PAGE_SPEED_SCORE_SUCCESS",
    GET_PAGE_SPEED_SCORE_ERROR : "GET_PAGE_SPEED_SCORE_ERROR"
}

export const pageSpeedScoreActions = {
    getPageSpeedScoreActionsStart : (data) => ({
        type : pageSpeedScoreMap.GET_PAGE_SPEED_SCORE_START,
        payload : data
    }),
    getPageSpeedScoreActionsSuccess : (data) => ({
        type : pageSpeedScoreMap.GET_PAGE_SPEED_SCORE_SUCCESS,
        payload : data
    }),
    getPageSpeedScoreActionsError : (data) => ({
        type : pageSpeedScoreMap.GET_PAGE_SPEED_SCORE_ERROR,
        payload : data
    })
}